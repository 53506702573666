import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { SelectOption } from 'types/util'

const useStyles = makeStyles(() => ({
    fullWidth: {
        marginBottom: 16,
        minWidth: 128,
        width: '100%',
    },
    notFullWidth: {
        marginBottom: 16,
        minWidth: 128,
        width: 128,
    },
}))

interface SelectBoxProps {
    isMinWidth?: boolean
    label: string
    options: SelectOption[]
    required: boolean
    select: (value: string) => void
    value: string
}

const SelectBox = (props: SelectBoxProps) => {
    const classes = useStyles()
    const formControlClass = props.isMinWidth ? classes.notFullWidth : classes.fullWidth

    return (
        <FormControl className={formControlClass}>
            <InputLabel>{props.label}</InputLabel>
            <Select
                value={props.value}
                required={props.required}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => props.select(e.target.value as string)}
            >
                {props.options.map((value: { id: string; name: string }) => {
                    return (
                        <MenuItem key={value.id} value={value.id}>
                            {value.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default SelectBox
