import * as Actions from 'reducks/courses/action'
import { initialState } from 'reducks/store/initialState'

export const CoursesReducer = (state = initialState.courses, action: any) => {
    switch (action.type) {
        case Actions.FETCH_COURSES:
            return [...action.payload]
        default:
            return state
    }
}
