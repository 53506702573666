import React, { useEffect } from 'react'
import { TextDetail } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const Faq = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)

    useEffect(() => {
        const title = 'FAQ'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <h3 className='u-text-primary u-text-medium u-text-bolder'>決済について</h3>
                <TextDetail
                    label={'Q.対応している支払い方法を教えてください。'}
                    value={
                        'A. 現在は、VISA / MasterCard / AMEX / Discover / Diners Club / JCB のクレジットカードおよびデビットカードに対応しております。'
                    }
                />
                <TextDetail
                    label={'Q. 退会（解約）時に金額は戻ってきますか？'}
                    value={
                        'A. 7日間の返金保証期間に退会すると料金が発生することはありません。期間外の退会（解約）の場合、返金することはできません。'
                    }
                />
                <TextDetail
                    label={'Q. 解約時 解約後の１ヶ月分の残り日数分は返金されますか？'}
                    value={'A. 月額費用を日割り計算で返金することはできません。'}
                />
                <div className='module-spacer--small' />
                <h3 className='u-text-primary u-text-medium u-text-bolder'>サービス内容について</h3>
                <TextDetail
                    label={'Q. このサービスを使って何のメリットがありますか？'}
                    value={
                        'A. 実務で使えるプログラミングスキルを教えています。知識の習得ではなく、開発経験を重視しています。 Swiftコースは、iPhoneやiPadのアプリの開発方法を学ぶことができます。 HTML/CSSコースは、Webサイトやランディングページの作り方を学ぶことができます。'
                    }
                />
                <TextDetail
                    label={'Q. 今後どのような言語をサポートする予定ですか？'}
                    value={
                        'A. JavaScript(React)コースを公開予定です。また、現在のコースにカリキュラムを随時追加していきます。'
                    }
                />
                <TextDetail
                    label={'Q. 受講に必要な物を教えてください。'}
                    value={
                        'A. SwiftコースはMac OSのパソコンが必要です。また、教材内で使用しているコマンドや操作も、Mac OSのみをサポートしています。'
                    }
                />
                <TextDetail
                    label={'Q. 推奨ブラウザを教えてください。'}
                    value={'A. 推奨ブラウザはChromeまたはSafariです。Internet Explorerはサポートしておりません。'}
                />
                <div className='module-spacer--small' />
                <h3 className='u-text-primary u-text-medium u-text-bolder'>紹介制度について</h3>
                <TextDetail
                    label={'Q. 紹介制度は何人でもOKですか？'}
                    value={
                        'A. はい、何人でも紹介可能です。紹介していただいた方1名につき¥5,000ずつのキャッシュバックをお支払いします。'
                    }
                />
                <TextDetail
                    label={'Q. キャッシュバックはいつ振り込まれますか？'}
                    value={
                        'A. キャッシュバック条件成立の翌月末にお振込いいたします。 例えば、紹介していただいたご友人が2020/06/15に有料会員登録を行なった場合、2020/07/31にあなたとご友人に¥5,000ずつお振込します。'
                    }
                />
            </div>
        </section>
    )
}

export default Faq
