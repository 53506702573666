import React, { Component, ErrorInfo, ReactElement } from 'react'
import { functions } from 'firebase/index'

interface ErrorState {
    hasError: boolean
}

class ErrorBoundary extends Component<any, ErrorState> {
    constructor(props: ReactElement) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        const reportError = functions.httpsCallable('reportRenderError')
        this.setState({ hasError: true })
        reportError({ error: error, errorInfo: errorInfo })
    }

    render() {
        if (this.state.hasError && process.env.NODE_ENV === 'production') {
            window.location.reload()
            return <></>
        } else {
            return this.props.children
        }
    }
}

export default ErrorBoundary
