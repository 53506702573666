import * as Actions from 'reducks/page/action'
import { initialState } from 'reducks/store/initialState'

export const PageReducer = (state = initialState.page, action: any) => {
    switch (action.type) {
        case Actions.SET_TITLE:
            return {
                ...state,
                title: action.payload,
            }
        default:
            return state
    }
}
