import React, { useCallback, useEffect, useState } from 'react'
import { GoBackButton, InputText, PrimaryButton, SelectBox, TextInput } from 'components/UIkit'
import { saveBankData } from 'reducks/users/operations'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '../firebase'
import { State } from '../index'
import { getSignedIn, getUserId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const BankEdit = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const isSignedIn = getSignedIn(selector)
    const uid: string = getUserId(selector)
    const pageTitle = getPageTitle(selector)

    const [firstName, setFirstName] = useState(''),
        [lastName, setLastName] = useState(''),
        [firstKanaName, setFirstKanaName] = useState(''),
        [lastKanaName, setLastKanaName] = useState(''),
        [zipCode, setZipCode] = useState(''),
        [address, setAddress] = useState(''),
        [building, setBuilding] = useState(''),
        [bankCode, setBankCode] = useState(''),
        [bankName, setBankName] = useState(''),
        [branchCode, setBranchCode] = useState(''),
        [branchName, setBranchName] = useState(''),
        [accountNumber, setAccountNumber] = useState(''),
        [accountType, setAccountType] = useState('')

    // -------------------------------------------------------------------------------------------
    //
    // @ Create Callbacks()
    const inputFirstName = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFirstName(event.target.value)
        },
        [setFirstName]
    )

    const inputLastName = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setLastName(event.target.value)
        },
        [setLastName]
    )

    const inputFirstKanaName = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setFirstKanaName(event.target.value)
        },
        [setFirstKanaName]
    )

    const inputLastKanaName = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setLastKanaName(event.target.value)
        },
        [setLastKanaName]
    )

    // const inputZipCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    //     setZipCode(event.target.value)
    // }, [setZipCode]);
    //
    // const inputAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    //     setAddress(event.target.value)
    // }, [setAddress]);
    // -------------------------------------------------------------------------------------------

    useEffect(() => {
        if (isSignedIn) {
            db.collection('users')
                .doc(uid)
                .collection('banks')
                .doc(uid)
                .get()
                .then((snapshot) => {
                    const bankData = snapshot.data()
                    if (bankData) {
                        setFirstName(bankData.first_name)
                        setLastName(bankData.last_name)
                        setFirstKanaName(bankData.first_kana_name)
                        setLastKanaName(bankData.last_kana_name)
                        setZipCode(bankData.zip_code)
                        setAddress(bankData.address)
                        setBuilding(bankData.building)
                        setBankCode(bankData.bank_code)
                        setBankName(bankData.bank_name)
                        setBranchCode(bankData.branch_code)
                        setBranchName(bankData.branch_name)
                        setAccountNumber(bankData.account_number)
                        setAccountType(bankData.account_type)
                    }
                })
        }
    }, [isSignedIn])

    useEffect(() => {
        const title = 'お振込先情報の編集'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <div className='module-spacer--small' />
                {/* 姓名（フリガナ）*/}
                <TextInput
                    fullWidth={false}
                    label={'姓'}
                    multiline={false}
                    onChange={inputLastName}
                    required={true}
                    rows={1}
                    type={'text'}
                    value={lastName}
                />
                <TextInput
                    fullWidth={false}
                    label={'名'}
                    multiline={false}
                    onChange={inputFirstName}
                    required={true}
                    rows={1}
                    type={'text'}
                    value={firstName}
                />
                <TextInput
                    fullWidth={false}
                    label={'セイ'}
                    multiline={false}
                    onChange={inputLastKanaName}
                    required={true}
                    rows={1}
                    type={'text'}
                    value={lastKanaName}
                />
                <TextInput
                    fullWidth={false}
                    label={'メイ'}
                    multiline={false}
                    onChange={inputFirstKanaName}
                    required={true}
                    rows={1}
                    type={'text'}
                    value={firstKanaName}
                />
                <div className='module-spacer--extra-small' />
                {/* 住所 */}
                <InputText
                    id={'zip'}
                    name={'zip'}
                    label={'郵便番号'}
                    onChange={setZipCode}
                    placeholder={'xxx-xxxx'}
                    required={true}
                    type={'tel'}
                    value={zipCode}
                />
                <InputText
                    id={'addr'}
                    label={'住所・番地'}
                    name={'addr'}
                    onChange={setAddress}
                    placeholder={'東京都千代田区岩本町x-xx-x'}
                    required={true}
                    type={'text'}
                    value={address}
                />
                <InputText
                    id={'building-name'}
                    label={'建物名・部屋番号など'}
                    onChange={setBuilding}
                    required={false}
                    type={'text'}
                    value={building}
                />
                <div className='module-spacer--extra-small' />
                {/* 口座情報 */}
                <InputText
                    id={'bank_name'}
                    name={'bank_name'}
                    label={'銀行名'}
                    onChange={setBankName}
                    placeholder={'例：みずほ銀行'}
                    required={true}
                    type={'text'}
                    value={bankName}
                />
                <InputText
                    id={'bank_code'}
                    maxLength={4}
                    name={'bank_code'}
                    label={'銀行コード'}
                    onChange={setBankCode}
                    placeholder={'例：0001'}
                    required={true}
                    type={'text'}
                    value={bankCode}
                />
                <InputText
                    id={'branch_name'}
                    name={'branch_name'}
                    label={'支店名'}
                    onChange={setBranchName}
                    placeholder={'例：〇〇支店'}
                    required={true}
                    type={'text'}
                    value={branchName}
                />
                <InputText
                    id={'branch_code'}
                    maxLength={3}
                    name={'branch_code'}
                    label={'支店コード'}
                    onChange={setBranchCode}
                    placeholder={'例：001'}
                    required={true}
                    type={'text'}
                    value={branchCode}
                />
                <SelectBox
                    label={'口座種類'}
                    required={true}
                    options={[
                        { id: '普通', name: '普通' },
                        { id: '当座', name: '当座' },
                    ]}
                    select={setAccountType}
                    value={accountType}
                />
                <InputText
                    id={'account_number'}
                    maxLength={7}
                    name={'account_number'}
                    label={'口座番号(半角数字)'}
                    onChange={setAccountNumber}
                    placeholder={'下7桁をご入力ください'}
                    required={true}
                    type={'tel'}
                    value={accountNumber}
                />
                <div className='module-spacer--extra-small' />
                <section className='center'>
                    <PrimaryButton
                        label={'変更を保存する'}
                        onClick={() => {
                            dispatch(
                                saveBankData({
                                    first_name: firstName,
                                    first_kana_name: firstKanaName,
                                    last_name: lastName,
                                    last_kana_name: lastKanaName,
                                    zip_code: zipCode,
                                    address: address,
                                    building: building,
                                    bank_code: bankCode,
                                    bank_name: bankName,
                                    branch_code: branchCode,
                                    branch_name: branchName,
                                    account_number: accountNumber,
                                    account_type: accountType,
                                })
                            )
                        }}
                    />
                    <GoBackButton label={'キャンセル'} />
                </section>
            </div>
        </section>
    )
}

export default BankEdit
