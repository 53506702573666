import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    formControl: {
        margin: 8,
    },
})

interface CheckboxProps {
    checked: boolean
    label: string
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CheckboxWithLabel = (props: CheckboxProps) => {
    const classes = useStyles()

    return (
        <FormControl component='fieldset' className={classes.formControl}>
            <FormControlLabel
                control={<Checkbox checked={props.checked} onChange={props.handleChange} name='gilad' />}
                label={props.label}
            />
        </FormControl>
    )
}

export default CheckboxWithLabel
