import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Badge, Divider } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import { pushTransition } from 'reducks/router/operation'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/styles'
import { db } from 'firebase/index'
import { Notifications } from 'reducks/notifications/types'
import { getReadNotifications, getUserId } from 'reducks/users/selectors'
import { State } from 'index'
import { theme } from 'assets/css/theme'
import { readNotifications } from '../../reducks/users/operations'

const useStyles = makeStyles({
    notification: {
        boxOrient: 'vertical',
        color: theme.palette.grey['700'],
        display: '-webkit-box',
        fontSize: 12,
        height: 48,
        lineHeight: '18px',
        lineClamp: 2,
        width: 256,
        whiteSpace: 'normal',
        wordBreak: 'break-word',
    },
})

const HeaderNotifications = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const notificationsRef = db.collection('notifications')

    const selector = useSelector((state: State) => state)
    const uid = getUserId(selector)

    let readNotificationIds = getReadNotifications(selector)
    // If the read_notifications property does not exist in the Users model
    readNotificationIds = readNotificationIds ? readNotificationIds : []

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [notificationIds, setNotificationIds] = React.useState<string[]>([])
    const [notifications, setNotifications] = React.useState<Notifications>([])
    const [unreadCounter, setUnreadCounter] = React.useState<number>(0)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setUnreadCounter(0)
        dispatch(readNotifications(notificationIds))
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const selectMenu = (path: string) => {
        if (path !== selector.router.location.pathname) {
            dispatch(pushTransition(path))
        }
        handleClose()
    }

    useEffect(() => {
        const ids: string[] = Object.keys(readNotificationIds)

        notificationsRef
            .where('receiver_ids', 'array-contains-any', [uid, 'all'])
            .orderBy('created_at', 'asc')
            .limitToLast(10)
            .onSnapshot((snapshots) => {
                snapshots.docChanges().forEach((change) => {
                    const data = change.doc.data()

                    if (change.type === 'added') {
                        const notification = {
                            content: data.content,
                            created_at: data.created_at,
                            link_id: data.link_id,
                            link_path: data.link_path,
                            notification_id: data.notification_id,
                            receiver_ids: data.receiver_ids,
                            sender_id: data.sender_id,
                            title: data.title ? data.title : '',
                            type: data.type,
                            updated_at: data.updated_at,
                        }
                        setNotifications((prevState) => [notification, ...prevState])
                        setNotificationIds((prevState) => [...prevState, data.notification_id])

                        // Count up unread counter
                        if (!ids.includes(data.notification_id)) {
                            setUnreadCounter((prevState) => prevState + 1)
                        }
                    }
                })
            })
    }, [])

    return (
        <>
            <IconButton aria-label='Show notifications' color='inherit' onClick={handleClick}>
                {unreadCounter > 0 ? (
                    <Badge badgeContent={unreadCounter} color='primary'>
                        <NotificationsIcon />
                    </Badge>
                ) : (
                    <NotificationsIcon />
                )}
            </IconButton>
            {notifications.length > 0 && (
                <Menu
                    id='menu-notifications'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {notifications.map((notification) => {
                        // For notifications before adding course in the url
                        const linkPath =
                            notification.link_path === 'curriculum' ? 'swift/curriculum' : notification.link_path

                        const url = `/${linkPath}/${notification.link_id}`
                        return (
                            <div key={notification.notification_id}>
                                <MenuItem
                                    className={classes.notification}
                                    onClick={() => selectMenu(url)}
                                    key={notification.notification_id}
                                >
                                    {notification.title !== ''
                                        ? '[運営からのお知らせ] ' + notification.title
                                        : notification.content}
                                </MenuItem>
                                <Divider />
                            </div>
                        )
                    })}
                </Menu>
            )}
        </>
    )
}

export default HeaderNotifications
