import { createSelector } from 'reselect'
import State from 'reducks/store/types'

const modalSelector = (state: State) => state.modal

export const getModalBody = createSelector([modalSelector], (state) => state.body)

export const getModalFunc = createSelector([modalSelector], (state) => state.func)

export const getModalOpen = createSelector([modalSelector], (state) => state.open)

export const getModalTitle = createSelector([modalSelector], (state) => state.title)

export const getModalType = createSelector([modalSelector], (state) => state.type)
