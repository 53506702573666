// import {handleActions} from 'redux-actions';
import * as Actions from 'reducks/users/action'
import { initialState } from 'reducks/store/initialState'

export const UsersReducer = (state = initialState.users, action: any) => {
    switch (action.type) {
        case Actions.CONFIRMED_FIRST_LOGIN:
            return {
                ...state,
                isFirstLogin: action.payload,
            }
        case Actions.EDIT_USER_PROFILE:
            return {
                ...state,
                icon_path: action.payload.icon_path,
                introduction: action.payload.introduction,
                username: action.payload.username,
            }
        case Actions.SIGN_IN:
            return {
                ...state,
                ...action.payload,
            }
        case Actions.INIT_USERS_LIST:
            return {
                ...state,
                users: [],
            }
        case Actions.LOG_OUT:
            return {
                ...initialState.users,
            }
        case Actions.READ_NOTIFICATIONS:
            return {
                ...state,
                read_notifications: action.payload,
            }
        case Actions.SELECT_COURSE:
            return {
                ...state,
                course: action.payload,
            }
        default:
            return state
    }
}
