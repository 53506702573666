import { push, goBack, replace } from 'connected-react-router'
import { initChaptersAction } from 'reducks/chapters/action'
import { initCurriculumAction } from 'reducks/curriculum/action'
import { initUsersList } from 'reducks/users/action'
import { initTestAction } from 'reducks/tests/action'
import { initSupportsAction } from 'reducks/supports/action'

// Push to the specified page initializing state
export const pushTransition = (path: string) => {
    return async (dispatch: any) => {
        dispatch(initChaptersAction())
        dispatch(initCurriculumAction())
        dispatch(initSupportsAction())
        dispatch(initUsersList())
        dispatch(initTestAction())
        dispatch(push(path))
    }
}

// Go back to the specified page initializing state
export const goBackTransition = () => {
    return async (dispatch: any) => {
        dispatch(initChaptersAction())
        dispatch(initCurriculumAction())
        dispatch(initSupportsAction())
        dispatch(initUsersList())
        dispatch(initTestAction())
        dispatch(goBack())
    }
}

// Go back to the specified page initializing state
export const replaceTransition = (path: string) => {
    return async (dispatch: any) => {
        dispatch(initChaptersAction())
        dispatch(initCurriculumAction())
        dispatch(initSupportsAction())
        dispatch(initUsersList())
        dispatch(initTestAction())
        dispatch(replace(path))
    }
}
