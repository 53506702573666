import React, { useEffect } from 'react'
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import { CanMakePaymentResult, Stripe } from '@stripe/stripe-js'

interface ApplePayButtonProps {
    initialFeeAmount: number
    stripe: Stripe | null
}

const ApplePayButton = (props: ApplePayButtonProps) => {
    let paymentRequest

    useEffect(() => {
        ;(async () => {
            if (props.stripe) {
                const pr = await props.stripe.paymentRequest({
                    country: 'JP',
                    currency: 'jpy',
                    total: {
                        label: 'Playground入会金',
                        amount: props.initialFeeAmount,
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                })

                // Check the availability of the Payment Request API.
                await pr.canMakePayment().then((result: CanMakePaymentResult | null) => {
                    if (result) {
                        paymentRequest = pr
                    }
                })
            }
        })()
    }, [props.stripe])

    return paymentRequest ? <PaymentRequestButtonElement options={{ paymentRequest }} /> : <></>
}
export default ApplePayButton
