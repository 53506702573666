import React, { useState } from 'react'
import { CurriculumEditor } from 'components/Curriculum'
import { useDispatch, useSelector } from 'react-redux'
import { saveContent } from 'reducks/tests/operations'
import { State } from 'index'
import { getAnswer } from 'reducks/tests/selectors'

const AnswerEditor = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const [answer, setAnswer] = useState<string>(getAnswer(selector))

    return (
        <div className='p-grid__curriculum__content'>
            <button className='p-btn-small p-btn-secondary' onClick={() => dispatch(saveContent(answer, 'answer'))}>
                解答文を保存する
            </button>
            <div className='module-spacer--extra-extra-small' />
            <CurriculumEditor value={answer} onChange={setAnswer} />
        </div>
    )
}

export default AnswerEditor
