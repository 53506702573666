import { CommentItems, CurriculumItem, CurriculumItems } from './types'

export const FETCH_COMMENTS = 'FETCH_COMMENTS'
export const fetchCommentsAction = (comments: CommentItems, commenterIds: Set<string>) => {
    return {
        type: 'FETCH_COMMENTS',
        payload: {
            comments: comments,
            commenterIds: commenterIds,
        },
    }
}

export const FETCH_CURRICULUM_ITEM = 'FETCH_CURRICULUM_ITEM'
export const fetchCurriculumItemAction = (curriculumItem: CurriculumItem) => {
    return {
        type: 'FETCH_CURRICULUM_ITEM',
        payload: curriculumItem,
    }
}

export const FETCH_CURRICULUM_ITEMS = 'FETCH_CURRICULUM_ITEMS'
export const fetchCurriculumItemsAction = (curriculumItems: CurriculumItems) => {
    return {
        type: 'FETCH_CURRICULUM_ITEMS',
        payload: curriculumItems,
    }
}

export const INIT_CURRICULUM = 'INIT_CURRICULUM'
export const initCurriculumAction = () => {
    return {
        type: 'INIT_CURRICULUM',
        payload: null,
    }
}

export const UPDATE_COMMENT = 'UPDATE_COMMENT'
export const updateCommentAction = (comments: CommentItems) => {
    return {
        type: 'UPDATE_COMMENT',
        payload: comments,
    }
}
