import React from 'react'
import { Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getSignedIn } from 'reducks/users/selectors'
import logo from 'assets/img/icons/loneFeed_logo.png'
import { pushTransition } from 'reducks/router/operation'
import { HeaderMenu, HeaderNotifications } from './index'
import { getCourseId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuBar: {
            backgroundColor: '#fff',
            color: '#444',
        },
        iconButtons: {
            margin: '0 0 0 auto',
            position: 'relative',
            zIndex: 1200,
        },
        logo: {
            position: 'relative',
            zIndex: 1200,
            width: '100px',
        },
        version: {
            color: theme.palette.grey['700'],
            fontSize: 14,
            fontWeight: 600,
            marginLeft: 8,
            marginTop: 'auto',
            marginBottom: 16,
            [theme.breakpoints.down('sm')]: {
                fontSize: 8,
            },
        },
        toolbar: {
            position: 'relative',
            zIndex: 1000,
        },
        title: {
            color: '#1400FF',
            fontSize: '18px',
            margin: 'auto',
            position: 'absolute',
            left: '0',
            right: '0',
            textAlign: 'center',
            zIndex: 1100,
        },
    })
)

const Header = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)

    const course = getCourseId(selector)
    const isSignedIn = getSignedIn(selector)
    const pageTitle = getPageTitle(selector)

    return (
        <div className={classes.root}>
            <AppBar position='fixed' className={classes.menuBar}>
                <Toolbar className={classes.toolbar}>
                    <img
                        alt='LineFeed Logo'
                        className={classes.logo}
                        src={logo}
                        onClick={() => dispatch(pushTransition('/' + course))}
                    />
                    <p className={classes.version}>v1.0.0</p>
                    <Typography className={`u-display-none-under-sp ${classes.title}`}>{pageTitle}</Typography>
                    {isSignedIn && (
                        <div className={classes.iconButtons}>
                            <HeaderNotifications />
                            <HeaderMenu />
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default Header
