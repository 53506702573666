import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CurriculumNav } from 'components/Curriculum'
import { State } from 'index'
import 'assets/css/prism.css'
import { Content } from 'components/Curriculum'
import { GoBackButton, GreyButton, DoneButton, SecondaryButton } from 'components/UIkit'
import { getIsSubscriber } from '../reducks/users/selectors'
import { IconButton } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Maze from 'assets/img/src/maze.jpg'
import { theme } from 'assets/css/theme'
import { pushTransition } from 'reducks/router/operation'
import { fetchTestItems } from 'reducks/tests/operations'
import { db } from 'firebase/index'
import {
    getCourseId,
    getDoneTestsAmount,
    getDoneTestsRate,
    getReadCurriculumAmount,
    getReadCurriculumRate,
} from 'reducks/users/selectors'
import { setTitleAction } from 'reducks/page/action'
import { ModalShare } from 'components/UIkit'

const useStyles = makeStyles(() =>
    createStyles({
        locked: {
            backgroundColor: '#fff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 3,
            '&:hover': {
                backgroundColor: theme.palette.grey['300'],
            },
        },
    })
)

const TestDetail = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const courseId = getCourseId(selector)

    const [answer, setAnswer] = useState<string>(''),
        [eyecatchPath, setEyecatchPath] = useState<string>(''),
        [hint, setHint] = useState<string>(''),
        [hint2, setHint2] = useState<string>(''),
        [isAnswerOpen, setAnswerOpen] = useState<boolean>(false),
        [isHintOpen, setHintOpen] = useState<boolean>(false),
        [isHint2Open, setHint2Open] = useState<boolean>(false),
        [question, setQuestion] = useState<string>(''),
        // [references, setReferences] = useState<Object>({}),
        [title, setTitle] = useState<string>('')

    const id: string = selector.router.location.pathname.split('/test/')[1]
    const isSubscriber = getIsSubscriber(selector)

    const confirmSubscribe = () => {
        const ret = window.confirm('こちらは有料コンテンツです。決済ページへ移動しますか？')
        if (ret) {
            return dispatch(pushTransition('/checkout'))
        } else {
            return false
        }
    }

    const handleAnswerOpen = useCallback(() => {
        setAnswerOpen(!isAnswerOpen)
    }, [isAnswerOpen])

    const handleHintOpen = useCallback(() => {
        setHintOpen(!isHintOpen)
    }, [isHintOpen])

    const handleHint2Open = useCallback(() => {
        setHint2Open(!isHint2Open)
    }, [isHint2Open])

    useEffect(() => {
        // Prevent from dispatching actions when going back to the top page
        if (id) {
            db.collection('tests')
                .doc(id)
                .get()
                .then((doc) => {
                    const data = doc.data()
                    if (data) {
                        setAnswer(data.answer ? data.answer : '')
                        setEyecatchPath(data.eyecatch_path)
                        setHint(data.hint ? data.hint : '')
                        setHint2(data.hint2 ? data.hint2 : '')
                        setQuestion(data.question ? data.question : '')
                        setTitle(data.title)
                    }
                })
            dispatch(fetchTestItems(courseId))
        }
    }, [id])

    useEffect(() => {
        const title = '【テスト】カリキュラム詳細'
        dispatch(setTitleAction(title))
    }, [])

    const doneTestsAmount = getDoneTestsAmount(selector)
    const doneTestsRate = getDoneTestsRate(selector)
    const readCurriculumAmount = getReadCurriculumAmount(selector)
    const readCurriculumRate = getReadCurriculumRate(selector)

    const text =
        courseId === 'swift'
            ? `Swiftカリキュラム達成数 ${readCurriculumAmount.swift}(${readCurriculumRate.swift}%)
Swift課題達成数 ${doneTestsAmount.swift}(${doneTestsRate.swift}%)`
            : `LP制作カリキュラム達成数 ${readCurriculumAmount.website}(${readCurriculumRate.website}%)
LP制作課題達成数 ${doneTestsAmount.website}(${doneTestsRate.website}%)`

    return (
        <section>
            <div className='c-section-wrapin'>
                <div className='module-spacer--medium' />
                <CurriculumNav id={id} />
                <div className='module-spacer--medium' />
                <GoBackButton label={'1つ前に戻る'} />
                <div className='module-spacer--medium' />
                {answer === '' && hint === '' && question === '' ? (
                    // Until fetching curriculum data
                    <></>
                ) : isSubscriber ? (
                    // If the user subscribes or the curriculum is published for free
                    <>
                        <Content content={question} eyecatch_path={eyecatchPath} title={title} key={'question'} />
                        <div className='module-spacer--medium' />
                        <div>
                            {isHintOpen ? (
                                <>
                                    <GreyButton label={'ヒント1を隠す'} onClick={handleHintOpen} />
                                    <Content content={hint} eyecatch_path={''} title={'💡 Hint1'} key={'hint1'} />
                                </>
                            ) : (
                                <SecondaryButton label={'ヒント1を見る'} onClick={handleHintOpen} />
                            )}
                        </div>
                        <div className='module-spacer--medium' />
                        <div>
                            {isHint2Open ? (
                                <>
                                    <GreyButton label={'ヒント2を隠す'} onClick={handleHint2Open} />
                                    <Content content={hint2} eyecatch_path={''} title={'💡 Hint2'} key={'hint2'} />
                                </>
                            ) : (
                                <SecondaryButton label={'ヒント2を見る'} onClick={handleHint2Open} />
                            )}
                        </div>
                        <div className='module-spacer--medium' />
                        <div>
                            {isAnswerOpen ? (
                                <>
                                    <GreyButton label={'解答を隠す'} onClick={handleAnswerOpen} />
                                    <Content content={answer} eyecatch_path={''} title={'✅ Answer'} key={'answer'} />
                                </>
                            ) : (
                                <SecondaryButton label={'解答を見る'} onClick={handleAnswerOpen} />
                            )}
                        </div>
                        <div className='module-spacer--medium' />
                        <DoneButton id={id} type={'test'} />
                        <ModalShare text={text} />
                    </>
                ) : (
                    // If the user does not subscribe and the curriculum is not published for free
                    <>
                        <p>こちらは有料コンテンツです。迷路でも解いていてください。</p>
                        <div className='module-spacer--extra-extra-small' />
                        <div className='p-content__locked' onClick={() => confirmSubscribe()}>
                            <img src={Maze} alt={'有料コンテンツ'} />
                            <IconButton className={classes.locked}>
                                <LockIcon fontSize={'large'} />
                            </IconButton>
                        </div>
                    </>
                )}
                <div className='module-spacer--medium' />
                <GoBackButton label={'1つ前に戻る'} />
                <div className='module-spacer--medium' />
                <CurriculumNav id={id} />
                <div className='module-spacer--medium' />
            </div>
        </section>
    )
}

export default TestDetail
