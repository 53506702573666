import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { ImagePreview } from '../UIkit'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CommentInput } from './index'

const useStyles = makeStyles({
    icon: {
        marginRight: 8,
        height: 48,
        width: 48,
    },
})

interface InputProps {
    close: (isEditable: boolean) => void
    commentId: string
    curriculumId: string
    deleteImage: (id: string) => void
    label: string
    images: { id: string; path: string }[]
    onChange: (value: string) => void
    onClick: (
        uid: string,
        curriculumId: string,
        value: string,
        commentId: string,
        attachedImages: { id: string; path: string }[]
    ) => void
    rows: number
    uploadImage: (event: React.ChangeEvent<HTMLInputElement>) => void
    value: string
}

const CommentEdit = (props: InputProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const userSelector = useSelector((state: State) => state.users)
    const uid = userSelector.uid

    return (
        <div className='p-input__text'>
            <label className='u-text-left'>{props.label}</label>
            <CommentInput comment={props.value} inputComment={props.onChange} />
            <div className='p-grid__list-images'>
                {props.images.length > 0 &&
                    props.images.map((image) => (
                        <ImagePreview delete={props.deleteImage} id={image.id} path={image.path} />
                    ))}
            </div>
            <div className='u-text-right'>
                <IconButton className={classes.icon}>
                    <label>
                        <AddPhotoAlternateIcon />
                        <input
                            className='u-display-none'
                            type='file'
                            id='image'
                            onChange={(e) => props.uploadImage(e)}
                        />
                    </label>
                </IconButton>
                <button
                    className='p-btn-small p-btn-secondary'
                    onClick={() => {
                        console.log(props.images)
                        dispatch(props.onClick(uid, props.curriculumId, props.value, props.commentId, props.images))
                        props.close(false)
                    }}
                >
                    保存する
                </button>
            </div>
        </div>
    )
}

export default CommentEdit
