import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { State } from 'index'
import { fetchCurriculumItems } from 'reducks/curriculum/operations'
import { fetchChapters } from 'reducks/chapters/operations'
import { fetchCourses } from 'reducks/courses/operations'
import { getChapterItems } from 'reducks/chapters/selectors'
import { getUserRole } from 'reducks/users/selectors'
import { ChapterItem } from 'reducks/chapters/types'
import { ExpansionList, CurriculumDone } from 'components/Curriculum'
import { getCurriculumItems } from 'reducks/curriculum/selectors'
import { CurriculumItem } from 'reducks/curriculum/types'
import { pushTransition } from 'reducks/router/operation'
import { getCourseId } from 'reducks/users/selectors'
// import FooterBanner from '../assets/img/src/support-footer-banner.jpeg'
import { setTitleAction } from 'reducks/page/action'
import { getCourses } from 'reducks/courses/selectors'
import { makeStyles } from '@material-ui/core/styles'
import { AdArea } from '../components/Footer'

const useStyles = makeStyles({
    align: {
        textAlign: 'right',
    },
})

const Curriculum = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const selector = useSelector((state: State) => state)

    const userRole = getUserRole(selector)
    const isManager = userRole === 'teacher' || userRole === 'administrator'

    const chaptersList = getChapterItems(selector)
    const courseId = getCourseId(selector)
    const courses = getCourses(selector)

    const curriculumItems = getCurriculumItems(selector)
    const uncategorizedCurriculum = curriculumItems.filter((item: CurriculumItem) => item.chapter_id === '')

    useEffect(() => {
        if (chaptersList.length === 0) {
            dispatch(fetchChapters(courseId))
        }
    }, [chaptersList.length])

    useEffect(() => {
        if (curriculumItems.length === 0) {
            dispatch(fetchCurriculumItems(courseId))
        }
    }, [curriculumItems.length])

    useEffect(() => {
        if (courses.length === 0) {
            dispatch(fetchCourses())
        }
    }, [])

    // Get uncategorized curriculum
    const uncategorizedChapter: ChapterItem = {
        chapter_name: '自動化ツール',
        chapter_id: '',
        course_id: courseId,
        order: '9999',
        curriculum: [],
    }

    useEffect(() => {
        const title = 'カリキュラム一覧'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            {/* Hide a banner to link support page
            <div className='p-media__banner' onClick={() => dispatch(pushTransition('/supports'))}>
                <img src={FooterBanner} alt={'banner'} />
            </div>
            */}
            <div className='module-spacer--medium' />
            <div className='c-section-wrapin'>
                {isManager && (
                    <div className='u-text-right' id='create-button'>
                        <div className='module-spacer--small' />
                        <a
                            className='p-btn-small p-btn-primary'
                            role='button'
                            onClick={() => dispatch(pushTransition('/pg-admin/curriculum/create'))}
                        >
                            新規作成
                        </a>
                    </div>
                )}
                <div className='module-spacer--small' />
                <div className={classes.align}>
                    <CurriculumDone type={'curriculum'} />
                </div>
                <div className='module-spacer--small' />
                <div>
                    {chaptersList.length > 0 &&
                        chaptersList.map((chapter: ChapterItem) => {
                            if (chapter.order.length === 4) {
                                return <ExpansionList chapter={chapter} key={chapter.chapter_id} />
                            } else {
                                return <></>
                            }
                        })}

                    {uncategorizedCurriculum.length > 0 && (
                        <ExpansionList chapter={uncategorizedChapter} key={'uncategorized'} />
                    )}
                </div>
                <div className='module-spacer--medium' />
                <div className='module-spacer--medium' />
                <AdArea />
            </div>
        </section>
    )
}

export default Curriculum
