import React from 'react'
import { CommentListItem } from '../Curriculum'
import { CommentItem, CommentItems } from 'reducks/curriculum/types'

interface CommentProps {
    comments: CommentItems
    curriculumId: string
}

const CommentList = (props: CommentProps) => {
    return (
        <>
            {props.comments.map((comment: CommentItem) => {
                return <CommentListItem curriculumId={props.curriculumId} comment={comment} key={comment.comment_id} />
            })}
        </>
    )
}

export default CommentList
