import React, { useState, useEffect } from 'react'
import { InputRadio, InputText } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { createNewUser } from 'reducks/users/operations'
import { goBackTransition } from 'reducks/router/operation'
import { State } from 'index'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const UserCreate = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)

    const [username, inputUsername] = useState('')
    const [email, inputEmail] = useState('')
    const [password, inputPassword] = useState('')
    const [role, toggleRole] = useState('student')

    useEffect(() => {
        const title = 'ユーザーの新規作成'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <div className='module-spacer--small' />
                <InputText
                    id={'username'}
                    label={'ユーザー名'}
                    onChange={inputUsername}
                    required={true}
                    type={'text'}
                    value={username}
                />
                <InputText
                    id={'email'}
                    label={'Email'}
                    onChange={inputEmail}
                    required={true}
                    type={'email'}
                    value={email}
                />
                <InputText
                    id={'password'}
                    label={'Password（6文字以上の英数字）'}
                    onChange={inputPassword}
                    required={true}
                    type={'password'}
                    value={password}
                />
                <div className='p-input__radio'>
                    <InputRadio
                        id={'student'}
                        label={'生徒'}
                        name={'role'}
                        currentState={role}
                        onChange={toggleRole}
                        value={'student'}
                    />
                    <InputRadio
                        id={'teacher'}
                        label={'講師'}
                        name={'role'}
                        currentState={role}
                        onChange={toggleRole}
                        value={'teacher'}
                    />
                    <InputRadio
                        id={'sales'}
                        label={'販売代理店'}
                        name={'role'}
                        currentState={role}
                        onChange={toggleRole}
                        value={'sales'}
                    />
                </div>
                <div className='module-spacer--small' />
                <button
                    className='p-btn-rounded'
                    onClick={() => dispatch(createNewUser(username, email, password, role))}
                >
                    作成する
                </button>
                <button className='p-btn-rounded p-btn-gray' onClick={() => dispatch(goBackTransition())}>
                    前のページに戻る
                </button>
            </div>
        </section>
    )
}

export default UserCreate
