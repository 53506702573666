import { UserState } from './types'

export const CONFIRMED_FIRST_LOGIN = 'CONFIRMED_FIRST_LOGIN'
export const confirmedFirstLoginAction = (isFirstLogin: boolean) => {
    return {
        type: 'CONFIRMED_FIRST_LOGIN',
        payload: isFirstLogin,
    }
}

export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE'
export const editProfileStateAction = (userProfile: object) => {
    return {
        type: 'EDIT_USER_PROFILE',
        payload: userProfile,
    }
}

export const SIGN_IN = 'SIGN_IN'
export const signInAction = (userState: UserState) => {
    return {
        type: 'SIGN_IN',
        payload: userState,
    }
}

export const INIT_USERS_LIST = 'INIT_USERS_LIST'
export const initUsersList = () => {
    return {
        type: 'INIT_USERS_LIST',
        payload: null,
    }
}

export const LOG_OUT = 'LOG_OUT'
export const signOutAction = () => {
    return {
        type: 'LOG_OUT',
        payload: null,
    }
}

export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS'
export const readNotificationsAction = (notificationIds: string[]) => {
    return {
        type: 'READ_NOTIFICATIONS',
        payload: notificationIds,
    }
}

export const SELECT_COURSE = 'SELECT_COURSE'
export const selectCourseAction = (courseId: string) => {
    return {
        type: 'SELECT_COURSE',
        payload: courseId,
    }
}
