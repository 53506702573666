import React from 'react'
import { deleteChapter } from 'reducks/chapters/operations'
import { useDispatch } from 'react-redux'
import { pushTransition } from 'reducks/router/operation'

interface ActionButtonsProps {
    chapterId: string
}

const ActionButtons = (props: ActionButtonsProps) => {
    const dispatch = useDispatch()
    const chapterId = props.chapterId
    const chapterEditUrl = '/pg-admin/chapter/edit/' + chapterId

    return (
        <div className='p-grid__buttons__area'>
            <button
                className='p-btn-small p-btn-secondary'
                role='button'
                onClick={() => dispatch(pushTransition(chapterEditUrl))}
            >
                編集
            </button>
            <button
                className='p-btn-small p-btn-gray'
                role='button'
                onClick={() => {
                    const ret = window.confirm('このチャプターを削除しますか？')
                    if (!ret) {
                        return false
                    } else {
                        return dispatch(deleteChapter(chapterId))
                    }
                }}
            >
                削除
            </button>
        </div>
    )
}

export default ActionButtons
