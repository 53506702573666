import React from 'react'

interface InputProps {
    currentState: string
    id: string
    label: string
    name: string
    onChange: (value: string) => void
    value: string
}

const InputRadio = (props: InputProps) => {
    return (
        <p>
            <input
                type='radio'
                id={props.id}
                name={props.name}
                checked={props.id === props.currentState}
                value={props.value}
                onChange={() => props.onChange(props.value)}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </p>
    )
}

export default InputRadio
