import React from 'react'

interface InputProps {
    id: string
    label: string
    maxLength?: number
    name?: string
    onChange: (value: string) => void
    placeholder?: string
    required: boolean
    type: string
    value: string
}

const InputText = (props: InputProps) => {
    return (
        <div className='p-input__text'>
            <label className='u-text-left'>
                {props.label}
                {props.required ? (
                    <span className='p-label__required'>必須</span>
                ) : (
                    <span className='p-label__optional'>任意</span>
                )}
            </label>
            <input
                type={props.type}
                id={props.id}
                maxLength={props.maxLength}
                name={props.name}
                required={props.required}
                placeholder={props.placeholder}
                onChange={(e) => props.onChange(e.target.value)}
                value={props.value}
            />
        </div>
    )
}

export default InputText
