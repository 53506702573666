import React from 'react'
import { PrimaryButton, SimpleTable } from 'components/UIkit'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { subscribeSupport } from '../reducks/payments/operations'
import { State } from '../index'

const useStyles = makeStyles((theme: Theme) => ({
    selectBox: {
        display: 'flex',
        margin: '0 auto',
        width: 320,
    },
    description: {
        margin: '0 auto',
        maxWidth: 400,
        textAlign: 'left',
        width: '100%',
        '& p': {
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: 16,
            },
        },
    },
    button: {
        textAlign: 'center',
    },
    movie: {
        margin: '0 auto',
        maxWidth: 768,
        width: '100%',
    },
}))

const Supports = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const user = useSelector((state: State) => state.users)

    const createData = (label: string, value: string) => {
        return { label, value }
    }

    const tableRows = [createData('プラン名', '料金'), createData('学習サポート', '¥5,000+税')]

    return (
        <section className='c-section-wrapin'>
            <div className='module-spacer--medium' />
            <div className={classes.description}>
                <h3 className='u-text-larger u-text-bolder'>学習サポートって？</h3>
                <div className='module-spacer--extra-small' />
                <p>
                    チャットと週に一度のオンライン授業で
                    <br />
                    あなたの学び、サポートします。
                </p>
                <p>
                    初回限定！月5,000円で現役エンジニアに聞き放題。
                    <br />
                    ※定員達し次第終了
                </p>
                <div className='module-spacer--small' />
                <h3 className='u-text-larger u-text-bolder'>学習サポートの料金表</h3>
                <div className='module-spacer--extra-small' />
                <SimpleTable rows={tableRows} />
                <div className='module-spacer--small' />
                <div className={classes.button}>
                    <PrimaryButton
                        label={'申し込む'}
                        onClick={() => {
                            dispatch(
                                subscribeSupport({
                                    customerId: user.customer_id,
                                    email: user.email,
                                    role: user.role,
                                    paymentMethodId: user.payment_method_id,
                                    supportFeeAmount: 5000,
                                    uid: user.uid,
                                    username: user.username,
                                })
                            )
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

export default Supports
