import React, { FC, useEffect } from 'react'
import { setTitleAction } from 'reducks/page/action'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getPageTitle } from 'reducks/page/selectors'
import Swacrab from 'assets/img/swacrab/17_sorry.png'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import { PrimaryButton } from '../components/UIkit'

const useStyles = makeStyles(() =>
    createStyles({
        description: {
            height: 'auto',
            margin: '0 auto',
            padding: 8,
            maxWidth: 450,
        },
    })
)

const SupportSorry: FC = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)

    useEffect(() => {
        const title = 'サポート休止中'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section-wrapin'>
            <h2 className='u-text__headline u-display-none-over-md'>{pageTitle}</h2>
            <div className={classes.description}>
                <img src={Swacrab} alt='Swacrab' />
                <div className='module-spacer--small' />
                <p>
                    現在、メンターサポートのサービスは停止中です。
                    <br />
                    メンターの手が空き次第、再開いたします。
                </p>
                <p>
                    オンライン教材と課題を用いて学習できる通常サービスは提供しています。
                    <br />
                    7日間無料でお試しできます。
                </p>
                <div className='module-spacer--small' />
                <PrimaryButton label={'登録はコチラ'} onClick={() => dispatch(push('/registration'))} />
            </div>
        </section>
    )
}

export default SupportSorry
