import React from 'react'
import { useSelector } from 'react-redux'
import { State } from '../index'
import { getModalType } from 'reducks/modal/selectors'
import { ModalDialog, ModalImage } from 'components/UIkit'

const Modal: React.StatelessComponent<React.Props<{}>> = ({ children }: { children?: any }) => {
    const selector = useSelector((state: State) => state)
    const type = getModalType(selector)

    let modal = <></>

    switch (type) {
        case 'text':
            modal = <ModalDialog />
            break
        case 'image':
            modal = <ModalImage />
            break
        default:
            break
    }

    return (
        <>
            {modal}
            {children}
        </>
    )
}

export default Modal
