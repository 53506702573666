import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import * as History from 'history'
import App from './App'
import 'assets/css/style.css'
import createStore from 'reducks/store/store'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from 'assets/css/theme'
import * as serviceWorker from './serviceWorker'
import { ErrorBoundary } from './components/UIkit'

// connected-react-router - action経由でルーティングが可能、push,replace..
// historyを強化
const history = History.createBrowserHistory()
export const store = createStore(history)
export type State = ReturnType<typeof store.getState>

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </MuiThemeProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
)

serviceWorker.unregister()
