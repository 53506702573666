import React, { useMemo } from 'react'
import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import { dateToString } from 'functions/commonFunc'
import { db } from 'firebase/index'
import { getUserRole } from 'reducks/users/selectors'
import { State } from 'index'
import { useSelector } from 'react-redux'

interface UserTableItem {
    created_at: firebase.firestore.Timestamp
    email: string
    inviterId: string
    is_subscriber: boolean
    role: string
    uid: string
    username: string
}

const useStyles = makeStyles({
    nowrap: {
        whiteSpace: 'nowrap',
    },
})

const UsersTableRow = (props: UserTableItem) => {
    const classes = useStyles()
    const selector = useSelector((state: State) => state)
    const registeredDate: string = dateToString(props.created_at.toDate())
    const isAdministrator = getUserRole(selector) === 'administrator'

    const deleteUser = (uid: string) => {
        const ret = window.confirm('このユーザーを削除しますか？')

        if (!ret) {
            return false
        } else {
            return db.collection('users').doc(uid).delete()
        }
    }

    const role = useMemo(() => {
        switch (props.role) {
            case 'student':
                return '生徒'
            case 'supported':
                return 'サポート生徒'
            case 'teacher':
                return '講師'
            case 'administrator':
                return '管理者'
            default:
                return ''
        }
    }, [props.role])

    return (
        <TableRow>
            <TableCell className={classes.nowrap}>{registeredDate}</TableCell>
            <TableCell className={classes.nowrap}>{props.uid}</TableCell>
            <TableCell className={classes.nowrap}>{props.username}</TableCell>
            <TableCell className={classes.nowrap}>{props.email}</TableCell>
            <TableCell className={classes.nowrap}>{props.is_subscriber ? '有料会員' : '退会済み'}</TableCell>
            <TableCell className={classes.nowrap}>{role}</TableCell>
            <TableCell className={classes.nowrap}>{props.inviterId}</TableCell>
            {isAdministrator && (
                <TableCell className={classes.nowrap}>
                    <button className='p-btn-small p-btn-primary' onClick={() => deleteUser(props.uid)}>
                        削除
                    </button>
                </TableCell>
            )}
        </TableRow>
    )
}

export default UsersTableRow
