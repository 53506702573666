import { db, auth, functions } from 'firebase/index'
import {
    signOutAction,
    signInAction,
    editProfileStateAction,
    confirmedFirstLoginAction,
    readNotificationsAction,
} from './action'
import { push } from 'connected-react-router'
import { BankData } from './types'
import { isValidEmailFormat, isValidRequiredInput } from 'functions/commonFunc'
import { hideLoadingAction, showLoadingAction } from 'reducks/loading/action'
import { initCurriculumAction } from 'reducks/curriculum/action'
import { goBackTransition, pushTransition } from 'reducks/router/operation'
import { initChaptersAction } from 'reducks/chapters/action'
import { openTextModalAction } from 'reducks/modal/action'
import { selectCourseAction } from 'reducks/users/action'
import { initTestAction } from '../tests/action'

const usersRef = db.collection('users')

export const suggestRegisterBankData = (uid: string) => {
    // Verify that an invited user has registered their bank data or not
    return async (dispatch: any) => {
        return db
            .collection('campaigns')
            .doc('invitation')
            .collection('users')
            .doc(uid)
            .get()
            .then((doc) => {
                const data = doc.data()
                if (data) {
                    const inviterId = data.inviter_id
                    if (inviterId !== '') {
                        dispatch(
                            openTextModalAction(
                                `<p>紹介キャンペーンのキャッシュバック受け取りのため、振込先の口座情報をご登録ください。</p>`,
                                () => pushTransition('/bank/edit'),
                                '口座情報登録のお願い'
                            )
                        )
                    }
                }
            })
    }
}

export const confirmedFirstLogin = () => {
    return async (dispatch: any, getState: any) => {
        const state = getState()
        const uid = state.users.uid

        usersRef
            .doc(uid)
            .update({ is_first_login: false })
            .then(() => {
                dispatch(confirmedFirstLoginAction(false))
                dispatch(suggestRegisterBankData(uid))
            })
    }
}

export const createNewUser = (username: string, email: string, password: string, role: string) => {
    return async (dispatch: any) => {
        dispatch(showLoadingAction())
        const createFunction = functions.httpsCallable('createNewUser')
        createFunction({
            email: email,
            password: password,
            role: role,
            username: username,
        })
            .then(() => {
                dispatch(hideLoadingAction())
                alert('新規ユーザーを作成しました。')
                dispatch(push('/pg-admin/users'))
            })
            .catch((error) => {
                console.error(error)
                dispatch(hideLoadingAction())
                alert('新規ユーザーの作成に失敗しました。')
            })
    }
}

export const editUserProfile = (iconPath: string, introduction: string, uid: string, username: string) => {
    return async (dispatch: any) => {
        const updateValue = {
            icon_path: iconPath,
            introduction: introduction,
            username: username,
        }
        return usersRef
            .doc(uid)
            .update(updateValue)
            .then(() => {
                alert('ユーザー情報を更新しました。')
                dispatch(editProfileStateAction(updateValue))
                dispatch(goBackTransition())
            })
            .catch((error) => {
                console.error(error)
                alert('ユーザー情報の更新に失敗しました。')
            })
    }
}

export const listenAuthState = () => {
    return async (dispatch: any) => {
        return auth.onAuthStateChanged((user) => {
            if (user) {
                usersRef.doc(user.uid).onSnapshot(async (snapshot) => {
                    const data = snapshot.data()
                    if (!data) {
                        throw new Error('ユーザーデータが存在しません。')
                    }

                    if (data.is_first_login) {
                        dispatch(
                            openTextModalAction(
                                `<p>いつでもカリキュラムを閲覧できるように、ブラウザのブックマークに登録しよう！</p>
                                   <p>iPhoneのSafari、またはAndroidのChromeなら「ホーム画面に追加」することでよりアクセスしやすくなります。</p>`,
                                confirmedFirstLogin,
                                'ブックマーク or ホーム画面に追加'
                            )
                        )
                    }

                    const doneTests = data.done_tests ? data.done_tests : {}
                    const readCurriculum = data.read_curriculum ? data.read_curriculum : {}

                    const swiftTestsAmount = await fetchDoneAndTotalAmount('swift', 'tests', doneTests)
                    const websiteTestsAmount = await fetchDoneAndTotalAmount('website', 'tests', doneTests)
                    const swiftCurriculumAmount = await fetchDoneAndTotalAmount('swift', 'curriculum', readCurriculum)
                    const websiteCurriculumAmount = await fetchDoneAndTotalAmount(
                        'website',
                        'curriculum',
                        readCurriculum
                    )

                    const userState = {
                        course: data.course ? data.course : '',
                        customer_id: data.customer_id ?? '',
                        done_tests: doneTests,
                        doneTestsAmount: {
                            swift: swiftTestsAmount.done + '/' + swiftTestsAmount.total,
                            website: websiteTestsAmount.done + '/' + websiteTestsAmount.total,
                        },
                        doneTestsRate: {
                            swift: Math.round((swiftTestsAmount.done / swiftTestsAmount.total) * 100 * 10) / 10,
                            website: Math.round((websiteTestsAmount.done / websiteTestsAmount.total) * 100 * 10) / 10,
                        },
                        email: data.email,
                        icon_path: data.icon_path,
                        introduction: data.introduction,
                        isSignedIn: true,
                        isFirstLogin: data.is_first_login,
                        isSubscriber: data.is_subscriber,
                        loyaltyPoints: data.loyalty_points ? data.loyalty_points : 0,
                        read_curriculum: readCurriculum,
                        readCurriculumAmount: {
                            swift: swiftCurriculumAmount.done + '/' + swiftCurriculumAmount.total,
                            website: websiteCurriculumAmount.done + '/' + websiteCurriculumAmount.total,
                        },
                        readCurriculumRate: {
                            swift:
                                Math.round((swiftCurriculumAmount.done / swiftCurriculumAmount.total) * 100 * 10) / 10,
                            website:
                                Math.round((websiteCurriculumAmount.done / websiteCurriculumAmount.total) * 100 * 10) /
                                10,
                        },
                        read_notifications: data.read_notifications ? data.read_notifications : {},
                        role: data.role,
                        payment_method_id: data.payment_method_id ?? '',
                        sharedIds: data.shared_ids ? data.shared_ids : [],
                        uid: user.uid,
                        username: data.username,
                    }

                    // Update logged in user state
                    dispatch(signInAction(userState))

                    if (!data.course) {
                        dispatch(push('/courses'))
                    }
                })
            } else {
                dispatch(push('/login'))
            }
        })
    }
}

export const readNotifications = (notificationIds: string[]) => {
    return async (dispatch: any, getState: any) => {
        let updateData: { [id: string]: boolean } = {}
        for (const id of notificationIds) {
            updateData[id] = true
        }

        const uid = getState().users.uid
        db.collection('users').doc(uid).set({ read_notifications: updateData }, { merge: true })
        dispatch(readNotificationsAction(notificationIds))
    }
}

export const resetPassword = (email: string) => {
    return async (dispatch: any) => {
        if (!isValidEmailFormat(email)) {
            alert('メールアドレスの形式が不正です。')
            return false
        } else {
            return auth
                .sendPasswordResetEmail(email)
                .then(() => {
                    alert('入力されたアドレス宛にパスワードリセットのメールをお送りしましたのでご確認ください。')
                    dispatch(push('/login'))
                })
                .catch(() => {
                    alert('登録されていないメールアドレスです。もう一度ご確認ください。')
                })
        }
    }
}

export const saveBankData = (bankData: BankData) => {
    return async (dispatch: any, getState: any) => {
        if (
            !isValidRequiredInput(
                bankData.last_name,
                bankData.first_name,
                bankData.last_kana_name,
                bankData.last_name,
                bankData.address,
                bankData.zip_code,
                bankData.bank_name,
                bankData.bank_code,
                bankData.branch_name,
                bankData.branch_code,
                bankData.account_type,
                bankData.account_number
            )
        ) {
            alert('必須項目が未入力です。')
            return false
        }

        if (!/[0-9]{4}/.test(bankData.bank_code)) {
            alert('銀行コードは4桁の整数で入力してください。')
            return false
        }

        if (!/[0-9]{3}/.test(bankData.branch_code)) {
            alert('支店コードは3桁の整数で入力してください。')
            return false
        }

        const state = getState()
        const userId = state.users.uid

        dispatch(showLoadingAction())
        return usersRef
            .doc(userId)
            .collection('banks')
            .doc(userId)
            .set(bankData)
            .then(() => {
                dispatch(hideLoadingAction())
                alert('お振込先情報を保存しました。')
                dispatch(push('/bank'))
            })
            .catch((error) => {
                alert('お振込先情報の更新に失敗しました。通信環境を確認してもう1度お試しください。')
                throw new Error(error)
            })
    }
}

export const selectCourse = (courseId: string) => {
    return async (dispatch: any, getState: any) => {
        const uid = getState().users.uid
        usersRef
            .doc(uid)
            .set({ course: courseId }, { merge: true })
            .then(() => {
                dispatch(initCurriculumAction())
                dispatch(initTestAction)
                dispatch(selectCourseAction(courseId))
                dispatch(push('/' + courseId))
            })
            .catch(() => {
                throw new Error('Failed to update course')
            })
    }
}

export const signIn = (email: string, password: string) => {
    return async (dispatch: any) => {
        dispatch(showLoadingAction('Login...'))
        if (!isValidRequiredInput(email, password)) {
            dispatch(hideLoadingAction())
            alert('メールアドレスかパスワードが未入力です。')
            return false
        }
        if (!isValidEmailFormat(email)) {
            dispatch(hideLoadingAction())
            alert('メールアドレスの形式が不正です。')
            return false
        }
        return auth
            .signInWithEmailAndPassword(email, password)
            .then((result: firebase.auth.UserCredential) => {
                const userState = result.user
                if (!userState) {
                    dispatch(hideLoadingAction())
                    throw new Error('ユーザーIDを取得できません')
                }
                const userId = userState.uid

                return usersRef.doc(userId).onSnapshot(async (snapshot) => {
                    const data = snapshot.data()
                    if (!data) {
                        dispatch(hideLoadingAction())
                        throw new Error('ユーザーデータが存在しません')
                    }

                    const doneTests = data.done_tests ? data.done_tests : {}
                    const readCurriculum = data.read_curriculum ? data.read_curriculum : {}

                    const swiftTestsAmount = await fetchDoneAndTotalAmount('swift', 'tests', doneTests)
                    const websiteTestsAmount = await fetchDoneAndTotalAmount('website', 'tests', doneTests)
                    const swiftCurriculumAmount = await fetchDoneAndTotalAmount('swift', 'curriculum', readCurriculum)
                    const websiteCurriculumAmount = await fetchDoneAndTotalAmount(
                        'website',
                        'curriculum',
                        readCurriculum
                    )

                    const userState = {
                        course: data.course ? data.course : '',
                        customer_id: data.customer_id ?? '',
                        done_tests: doneTests,
                        doneTestsAmount: {
                            swift: swiftTestsAmount.done + '/' + swiftTestsAmount.total,
                            website: websiteTestsAmount.done + '/' + websiteTestsAmount.total,
                        },
                        doneTestsRate: {
                            swift: Math.round((swiftTestsAmount.done / swiftTestsAmount.total) * 100 * 10) / 10,
                            website: Math.round((websiteTestsAmount.done / websiteTestsAmount.total) * 100 * 10) / 10,
                        },
                        email: data.email,
                        icon_path: data.icon_path,
                        introduction: data.introduction,
                        isSignedIn: true,
                        isFirstLogin: data.is_first_login,
                        isSubscriber: data.is_subscriber,
                        loyaltyPoints: data.loyalty_points ? data.loyalty_points : 0,
                        read_curriculum: readCurriculum,
                        readCurriculumAmount: {
                            swift: swiftCurriculumAmount.done + '/' + swiftCurriculumAmount.total,
                            website: websiteCurriculumAmount.done + '/' + websiteCurriculumAmount.total,
                        },
                        readCurriculumRate: {
                            swift:
                                Math.round((swiftCurriculumAmount.done / swiftCurriculumAmount.total) * 100 * 10) / 10,
                            website:
                                Math.round((websiteCurriculumAmount.done / websiteCurriculumAmount.total) * 100 * 10) /
                                10,
                        },
                        read_notifications: data.read_notifications ? data.read_notifications : {},
                        role: data.role,
                        payment_method_id: data.payment_method_id ?? '',
                        sharedIds: data.shared_ids ? data.shared_ids : [],
                        uid: userId,
                        username: data.username,
                    }

                    // Update logged in user state
                    dispatch(signInAction(userState))

                    if (!data.course) {
                        dispatch(hideLoadingAction())
                        dispatch(push('/courses'))
                    }
                    if (data.role === 'teacher' || data.role === 'administrator') {
                        dispatch(hideLoadingAction())
                        dispatch(push('/pg-admin'))
                    } else {
                        dispatch(hideLoadingAction())
                        dispatch(push(''))
                    }
                })
            })
            .catch(() => {
                const log = document.getElementById('log')
                if (!log) {
                    dispatch(hideLoadingAction())
                    throw new Error('ログの入力欄がありません。')
                }
                dispatch(hideLoadingAction())
                log.innerText = 'メールアドレスかパスワードが間違っています'
            })
    }
}

export const signOut = () => {
    return async (dispatch: any) => {
        dispatch(showLoadingAction('Logout...'))
        auth.signOut()
            .then(() => {
                dispatch(initChaptersAction())
                dispatch(initCurriculumAction())
                dispatch(signOutAction())
                dispatch(hideLoadingAction())
                dispatch(push('/login'))
            })
            .catch(() => {
                dispatch(hideLoadingAction())
                throw new Error('ログアウトに失敗しました。')
            })
    }
}

export const fetchDoneAndTotalAmount = (
    course: string,
    type: 'curriculum' | 'tests',
    doneItems: { [s: string]: boolean }[]
) => {
    let doneAmount = 0
    let totalAmount = 0

    return db
        .collection(type)
        .where('isPublished', '==', true)
        .get()
        .then((snapshots) => {
            const keys = Object.keys(doneItems)
            const values = Object.values(doneItems)

            snapshots.forEach((snapshot) => {
                const course_id = snapshot.data().course_id
                const index = keys.findIndex((key) => key === snapshot.id)

                // Verify the curriculum/test belongs to the course or not
                if (course === course_id) {
                    totalAmount++

                    // Verify the user has done the curriculum/test or not
                    if (index >= 0 && values[index]) {
                        doneAmount++
                    }
                }
            })

            return {
                done: doneAmount,
                total: totalAmount,
            }
        })
}
