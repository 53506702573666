import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CurriculumNav, CommentArea } from 'components/Curriculum'
import { State } from 'index'
import { fetchCurriculum, fetchCurriculumItems } from 'reducks/curriculum/operations'
import 'assets/css/prism.css'
import { Content } from 'components/Curriculum'
import { GoBackButton, DoneButton } from 'components/UIkit'
import { IconButton } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Maze from 'assets/img/src/maze.jpg'
import { theme } from 'assets/css/theme'
import { pushTransition } from 'reducks/router/operation'
import { setTitleAction } from 'reducks/page/action'
import { ModalShare } from 'components/UIkit'
import {
    getIsSubscriber,
    getCourseId,
    getDoneTestsAmount,
    getDoneTestsRate,
    getReadCurriculumAmount,
    getReadCurriculumRate,
} from 'reducks/users/selectors'

const useStyles = makeStyles(() =>
    createStyles({
        locked: {
            backgroundColor: '#fff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 3,
            '&:hover': {
                backgroundColor: theme.palette.grey['300'],
            },
        },
    })
)

const CurriculumDetail = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)

    const courseId = getCourseId(selector)
    const curriculum = selector.curriculum
    const id: string = selector.router.location.pathname.split('/curriculum/')[1]
    const isSubscriber = getIsSubscriber(selector)

    const confirmSubscribe = () => {
        const ret = window.confirm('こちらは有料コンテンツです。決済ページへ移動しますか？')
        if (ret) {
            return dispatch(pushTransition('/checkout'))
        } else {
            return false
        }
    }

    useEffect(() => {
        // Prevent from dispatching actions when going back to the top page
        if (id) {
            dispatch(fetchCurriculum(id))
            dispatch(fetchCurriculumItems(courseId))
        }
    }, [id])

    useEffect(() => {
        const title = 'カリキュラム詳細'
        dispatch(setTitleAction(title))
    }, [])

    const doneTestsAmount = getDoneTestsAmount(selector)
    const doneTestsRate = getDoneTestsRate(selector)
    const readCurriculumAmount = getReadCurriculumAmount(selector)
    const readCurriculumRate = getReadCurriculumRate(selector)

    const text =
        courseId === 'swift'
            ? `Swiftカリキュラム達成数 ${readCurriculumAmount.swift}(${readCurriculumRate.swift}%)
Swift課題達成数 ${doneTestsAmount.swift}(${doneTestsRate.swift}%)`
            : `LP制作カリキュラム達成数 ${readCurriculumAmount.website}(${readCurriculumRate.website}%)
LP制作課題達成数 ${doneTestsAmount.website}(${doneTestsRate.website}%)`

    const content =
        curriculum.content === '' ? (
            // Until fetching curriculum data
            <></>
        ) : isSubscriber || curriculum.is_free ? (
            // If the user subscribes or the curriculum is published for free
            <>
                <ModalShare text={text} />
                <Content
                    content={curriculum.content}
                    eyecatch_path={curriculum.eyecatch_path}
                    title={curriculum.title}
                />
                <div className='module-spacer--medium' />
                <DoneButton id={id} type={'curriculum'} />
            </>
        ) : (
            // If the user does not subscribe and the curriculum is not published for free
            <>
                <p>こちらは有料コンテンツです。迷路でも解いていてください。</p>
                <div className='module-spacer--extra-extra-small' />
                <div className='p-content__locked' onClick={() => confirmSubscribe()}>
                    <img src={Maze} alt={'有料コンテンツ'} />
                    <IconButton className={classes.locked}>
                        <LockIcon fontSize={'large'} />
                    </IconButton>
                </div>
            </>
        )

    return (
        <section>
            <div className='c-section-wrapin-wide'>
                <div className='module-spacer--medium' />
                <div className='module-spacer--medium' />
                <CurriculumNav id={id} />
                <div className='module-spacer--medium' />
                <GoBackButton label={'1つ前に戻る'} />
                <div className='module-spacer--medium' />
                {content}
                <div className='module-spacer--medium' />
                <GoBackButton label={'1つ前に戻る'} />
                <div className='module-spacer--medium' />
                <CurriculumNav id={id} />
                <div className='module-spacer--medium' />
                {(isSubscriber || curriculum.is_free) && <CommentArea id={id} />}
            </div>
        </section>
    )
}

export default CurriculumDetail
