import * as Actions from 'reducks/tests/action'
import { initialState } from 'reducks/store/initialState'

export const TestsReducer = (state = initialState.tests, action: any) => {
    switch (action.type) {
        case Actions.FETCH_TEST_ITEM:
            return {
                ...state,
                ...action.payload,
            }
        case Actions.FETCH_TEST_ITEMS:
            return {
                ...state,
                list: action.payload,
            }
        case Actions.INIT_TEST:
            return {
                ...initialState.tests,
            }
        case Actions.SAVE_ANSWER:
            return {
                ...state,
                answer: action.payload,
            }
        case Actions.SAVE_HINT:
            return {
                ...state,
                hint: action.payload,
            }
        case Actions.SAVE_HINT2:
            return {
                ...state,
                hint2: action.payload,
            }
        case Actions.SAVE_QUESTION:
            return {
                ...state,
                question: action.payload,
            }
        default:
            return state
    }
}
