import { db, FirebaseFieldValue, FirebaseTimestamp } from 'firebase/index'
import {
    updateCommentAction,
    fetchCurriculumItemAction,
    fetchCurriculumItemsAction,
    fetchCommentsAction,
} from './action'
import { CommentItem, CommentItems, CurriculumItem, CurriculumItems, readCurriculumType } from './types'
import { UsersInfo } from 'reducks/users/types'
import { fetchChapters } from '../chapters/operations'
import { pushTransition } from '../router/operation'

const chaptersRef = db.collection('chapters')
const curriculumRef = db.collection('curriculum')

const hasDone = (id: string, readCurriculum: readCurriculumType) => {
    let flag: boolean = false

    if (!readCurriculum) {
        return flag
    } else {
        for (let curriculum of Object.keys(readCurriculum)) {
            if (curriculum === id && readCurriculum[curriculum]) {
                flag = true
            }
        }
        return flag
    }
}

const fetchUsers = async () => {
    let users: { [uid: string]: UsersInfo } = {}
    return db
        .collection('users')
        .get()
        .then((snapshot) => {
            snapshot.forEach((doc) => {
                const data = doc.data()
                users[doc.id] = {
                    created_at: data.created_at,
                    email: data.email,
                    icon_path: data.icon_path,
                    introduction: data.introduction,
                    role: data.role,
                    uid: doc.id,
                    username: data.username,
                    updated_at: data.updated_at,
                }
            })
            return users
        })
        .catch((error) => {
            throw new Error(error)
        })
}

export const deleteCurriculum = (id: string) => {
    return async (dispatch: any, getState: any) => {
        const batch = db.batch()

        await chaptersRef
            .where('curriculum', 'array-contains', id)
            .get()
            .then((snapshots) => {
                // Add queries removing Curriculum ID from curriculum in chapter
                snapshots.forEach((doc) => {
                    batch.set(
                        chaptersRef.doc(doc.id),
                        { curriculum: FirebaseFieldValue.arrayRemove(id) },
                        { merge: true }
                    )
                })
            })
            .then(() => {
                // Add a query to remove Curriculum ID from curriculum data
                batch.delete(curriculumRef.doc(id))
            })
            .catch((error) => {
                throw new Error(error)
            })

        await db
            .collection('users')
            .where(`read_curriculum.${id}`, '==', true)
            .get()
            .then((snapshots) => {
                snapshots.forEach((snapshot) => {
                    const userData = snapshot.data()
                    const readCurriculum = userData.read_curriculum
                    readCurriculum[id] = false

                    batch.update(db.collection('users').doc(snapshot.id), { read_curriculum: readCurriculum })
                })
            })

        // Delete comments
        await curriculumRef
            .doc(id)
            .collection('comments')
            .get()
            .then((snapshots) => {
                snapshots.forEach((snapshot) => [
                    batch.delete(curriculumRef.doc(id).collection('comments').doc(snapshot.id)),
                ])
            })

        batch.commit().then(() => {
            const courseId = getState().users.course
            dispatch(fetchChapters(courseId))
            dispatch(fetchCurriculumItems(courseId))
        })
    }
}

export const editComment = (
    commenterId: string,
    curriculumId: string,
    value: string,
    commentId: string,
    attachedImages: { id: string; path: string }[]
) => {
    return async (dispatch: any, getState: any) => {
        const state = getState()
        curriculumRef
            .doc(curriculumId)
            .collection('comments')
            .doc(commentId)
            .set(
                {
                    comment: value,
                    images: attachedImages,
                    updated_at: FirebaseTimestamp.now(),
                },
                { merge: true }
            )
            .then(() => {
                const comments = state.curriculum.comments
                const index = comments.findIndex((comment: CommentItem) => comment.comment_id === commentId)
                comments[index] = {
                    created_at: FirebaseTimestamp.now(),
                    comment: value,
                    comment_id: commentId,
                    commenter_icon: state.users.icon_path,
                    commenter_id: commenterId,
                    commenter_name: state.users.username,
                    images: attachedImages,
                    updated_at: FirebaseTimestamp.now(),
                }
                dispatch(updateCommentAction(comments))
            })
    }
}

export const fetchComments = (curriculumId: string) => {
    return async (dispatch: any, getState: any) => {
        const users = await fetchUsers()
        const commenterIds: Set<string> = getState().curriculum.commenterIds

        return curriculumRef
            .doc(curriculumId)
            .collection('comments')
            .orderBy('created_at', 'asc')
            .onSnapshot((snapshots) => {
                let comments: CommentItems = getState().curriculum.comments

                snapshots.docChanges().forEach((change) => {
                    const data = change.doc.data()
                    const uid = data.commenter_id

                    if (change.type === 'added') {
                        // Add commenter data like icon and username
                        comments.push({
                            created_at: data.created_at,
                            comment: data.comment,
                            comment_id: change.doc.id,
                            commenter_icon: users[uid] ? users[uid].icon_path : '',
                            commenter_id: uid,
                            commenter_name: users[uid] ? users[uid].username : '退会済みユーザー',
                            images: data.images,
                            updated_at: data.updated_at,
                        })
                    } else if (change.type === 'modified') {
                        const index = comments.findIndex((value: CommentItem) => {
                            return value.comment_id === change.doc.id
                        })
                        comments[index] = {
                            created_at: data.created_at,
                            comment: data.comment,
                            comment_id: change.doc.id,
                            commenter_icon: users[uid] ? users[uid].icon_path : '',
                            commenter_id: uid,
                            commenter_name: users[uid] ? users[uid].username : '退会済みユーザー',
                            images: data.images,
                            updated_at: data.updated_at,
                        }
                    } else if (change.type === 'removed') {
                        const index = comments.findIndex((value: CommentItem) => {
                            return value.comment_id === change.doc.id
                        })
                        comments.splice(index, 1)
                    }
                })

                // Get IDs of which users commented in this curriculum
                for (const comment of comments) {
                    commenterIds.add(comment.commenter_id)
                }
                // Delete from the Set
                const uid = getState().users.uid
                commenterIds.delete(uid)

                dispatch(fetchCommentsAction(comments, commenterIds))
            })
    }
}

export const fetchCurriculum = (id: string) => {
    return async (dispatch: any, getState: any) => {
        curriculumRef
            .doc(id)
            .get()
            .then((snapshot) => {
                const data = snapshot.data()
                if (!data) {
                    throw new Error('カリキュラムデータが存在しません。')
                }
                const updateValue: CurriculumItem = {
                    chapter_id: data.chapter_id,
                    comments: [],
                    content: data.content,
                    course_id: data.course_id,
                    eyecatch_path: data.eyecatch_path,
                    hasDone: hasDone(id, getState().users.read_curriculum),
                    id: id,
                    is_free: data.is_free,
                    isPublished: data.isPublished,
                    order: data.order,
                    title: data.title,
                    updated_at: data.updated_at,
                }
                dispatch(fetchCurriculumItemAction(updateValue))
            })
    }
}

export const fetchCurriculumItems = (courseId: string) => {
    return async (dispatch: any, getState: any) => {
        return curriculumRef
            .where('course_id', '==', courseId)
            .orderBy('order', 'asc')
            .get()
            .then((snapshots) => {
                let list: CurriculumItems = []
                snapshots.forEach((doc) => {
                    const data = doc.data()
                    list.push({
                        chapter_id: data.chapter_id ? data.chapter_id : '',
                        comments: [],
                        content: data.content,
                        course_id: data.course_id,
                        eyecatch_path: data.eyecatch_path,
                        hasDone: hasDone(doc.id, getState().users.read_curriculum),
                        id: doc.id,
                        is_free: data.is_free,
                        isPublished: data.isPublished,
                        order: data.order,
                        title: data.title,
                        updated_at: data.updated_at,
                    })
                })
                dispatch(fetchCurriculumItemsAction(list))
            })
    }
}

export const saveCurriculum = (curriculumId: string, data: any) => {
    return async (dispatch: any, getState: any) => {
        const batch = db.batch()
        const curriculumList = getState().curriculum.list

        if (curriculumId === '') {
            const ref = curriculumRef.doc()
            data.created_at = FirebaseTimestamp.now()
            curriculumId = ref.id
        }

        const index = curriculumList.findIndex((curriculum: CurriculumItem) => curriculum.id === curriculumId)
        const currentChapterId = curriculumList[index] ? curriculumList[index].chapter_id : ''

        // Remove curriculum id from the list in chapter
        if (currentChapterId !== '') {
            batch.set(
                chaptersRef.doc(currentChapterId),
                { curriculum: FirebaseFieldValue.arrayRemove(curriculumId) },
                { merge: true }
            )
        }

        // Add curriculum id to the list in chapter
        if (data.chapter_id !== '') {
            batch.update(chaptersRef.doc(data.chapter_id), {
                curriculum: FirebaseFieldValue.arrayUnion(curriculumId),
            })
        }

        // Update curriculum
        batch.set(curriculumRef.doc(curriculumId), data, { merge: true })

        return batch
            .commit()
            .then(() => {
                alert('カリキュラムの変更を保存しました。')
                dispatch(pushTransition('/pg-admin/curriculum/edit/' + curriculumId))
            })
            .catch((error) => {
                console.error(error)
                alert('カリキュラムの操作に失敗しました。通信環境を確認してもう1度試してください。')
            })
    }
}
