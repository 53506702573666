import React, { useCallback, useEffect, useState } from 'react'
import { GoBackButton, PrimaryButton, TextInput } from '../components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import NoProfile from 'assets/img/src/no-profile.png'
import { editUserProfile } from 'reducks/users/operations'
import { attachFiles } from 'functions/commonFunc'
import NoImage from 'assets/img/src/no_image.png'
import { db } from 'firebase/index'
import { State } from '../index'
import { getSignedIn, getUserId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const UserEdit = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const isSignedIn = getSignedIn(selector)
    const uid: string = getUserId(selector)
    const pageTitle = getPageTitle(selector)

    const [iconPath, setIconPath] = useState(''),
        [introduction, setIntroduction] = useState(''),
        [username, setUsername] = useState('')

    // Save edited profile
    const saveProfile = () => {
        const iconDom = document.getElementById('image-thumb')
        let icon = iconDom ? iconDom.getAttribute('src') : ''
        if (icon === NoImage || icon === null) {
            icon = ''
        }
        dispatch(editUserProfile(icon, introduction, uid, username))
    }

    const inputUsername = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setUsername(event.target.value)
        },
        [setUsername]
    )

    const inputIntroduction = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIntroduction(event.target.value)
        },
        [setIntroduction]
    )

    useEffect(() => {
        if (isSignedIn) {
            db.collection('users')
                .doc(uid)
                .get()
                .then((snapshot) => {
                    const profile = snapshot.data()
                    if (profile) {
                        setIconPath(profile.icon_path === '' ? NoProfile : profile.icon_path)
                        setIntroduction(profile.introduction)
                        setUsername(profile.username)
                    }
                })
        }
    }, [isSignedIn])

    // Attach event listener to upload and preview Icon
    useEffect(() => {
        attachFiles('image', 'add')
        return () => {
            attachFiles('image', 'remove')
        }
    }, [])

    useEffect(() => {
        const title = 'ユーザーの編集'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <div className='module-spacer--small' />
                <div className='p-media__profile'>
                    <label>
                        <span>
                            ＋
                            <input className='u-display-none' id='image' type='file' />
                        </span>
                    </label>
                    <img alt='profile icon' id='image-thumb' src={iconPath} />
                </div>
                <div className='module-spacer--medium' />
                <TextInput
                    fullWidth={true}
                    label={'ユーザー名'}
                    multiline={true}
                    onChange={inputUsername}
                    required={true}
                    rows={1}
                    value={username}
                    type={'text'}
                />
                <TextInput
                    fullWidth={true}
                    label={'自己紹介'}
                    multiline={true}
                    onChange={inputIntroduction}
                    required={true}
                    rows={5}
                    value={introduction}
                    type={'text'}
                />
                <div className='module-spacer--small' />

                <section className='center'>
                    <PrimaryButton label={'変更を保存する'} onClick={saveProfile} />
                    <GoBackButton label={'キャンセル'} />
                </section>
            </div>
        </section>
    )
}

export default UserEdit
