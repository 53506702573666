import { createStore as reduxCreateStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'

// Import reducers
import { ChaptersReducer } from 'reducks/chapters/reducer'
import { CoursesReducer } from 'reducks/courses/reducer'
import { CurriculumReducer } from 'reducks/curriculum/reducer'
import { LoadingReducer } from 'reducks/loading/reducer'
import { ModalReducer } from 'reducks/modal/reducer'
import { PageReducer } from 'reducks/page/reducer'
import { SupportsReducer } from 'reducks/supports/reducer'
import { TestsReducer } from 'reducks/tests/reducer'
import { UsersReducer } from 'reducks/users/reducer'

// createStoreの再定義 - historyを引数で受け、connected-react-routerの利用を抽象化
export default function createStore(history: any) {
    // Define individual settings of redux-logger
    let middleWares = [routerMiddleware(history), thunk]
    if (process.env.NODE_ENV === 'development') {
        const logger: any = createLogger({
            collapsed: true,
            diff: true,
        })
        middleWares.push(logger)
    }

    return reduxCreateStore(
        // オリジナル createStore の別名
        combineReducers({
            chapters: ChaptersReducer,
            courses: CoursesReducer,
            curriculum: CurriculumReducer,
            loading: LoadingReducer,
            modal: ModalReducer,
            page: PageReducer,
            router: connectRouter(history),
            supports: SupportsReducer,
            tests: TestsReducer,
            users: UsersReducer,
        }),
        applyMiddleware(...middleWares)
    )
}
