import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Theme, createStyles, makeStyles, Typography } from '@material-ui/core'
import { State } from 'index'
import { fetchCurriculumItems } from 'reducks/curriculum/operations'
import { fetchCourses } from 'reducks/courses/operations'
import { getCurriculumItems } from 'reducks/curriculum/selectors'
import { getCourseId } from 'reducks/users/selectors'
import { getCourses } from 'reducks/courses/selectors'
import { getTestItems } from '../../reducks/tests/selectors'
import { TestItem } from '../../reducks/tests/types'
import { CurriculumItem } from 'reducks/curriculum/types'
import {
    getDoneTestsAmount,
    getDoneTestsRate,
    getReadCurriculumAmount,
    getReadCurriculumRate,
} from 'reducks/users/selectors'

interface DoneProps {
    type: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontColor: '#444',
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 'bold',
        },
    })
)

const CurriculumDone = (props: DoneProps) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const selector = useSelector((state: State) => state)
    const type = props.type === 'curriculum' ? 'curriculum' : 'test'
    const items: (CurriculumItem | TestItem)[] =
        type === 'curriculum' ? getCurriculumItems(selector) : getTestItems(selector)

    const amount = type === 'curriculum' ? getReadCurriculumAmount(selector) : getDoneTestsAmount(selector)
    const rate = type === 'curriculum' ? getReadCurriculumRate(selector) : getDoneTestsRate(selector)

    const courseId = getCourseId(selector)
    const courses = getCourses(selector)

    useEffect(() => {
        if (items.length === 0) {
            dispatch(fetchCurriculumItems(courseId))
        }
    }, [items.length])

    useEffect(() => {
        if (courses.length === 0) {
            dispatch(fetchCourses())
        }
    }, [])

    return (
        <div>
            <Typography className={classes.heading}>
                {courses.map((course: any) => {
                    if (course.id === courseId) {
                        return course.name
                    }
                })}
                コース達成数&nbsp;
                {courseId === 'swift' ? amount.swift : amount.website}&nbsp; (
                {courseId === 'swift' ? rate.swift : rate.website}%)
            </Typography>
        </div>
    )
}

export default CurriculumDone
