import React from 'react'
import { datetimeToString } from 'functions/commonFunc'
import { Notification } from 'reducks/notifications/types'
import { pushTransition } from 'reducks/router/operation'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getUserRole } from 'reducks/users/selectors'
import { db } from 'firebase/index'
import Noimage from 'assets/img/src/no_image.png'

const NotificationListItem = (props: { notification: Notification }) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)

    const userRole = getUserRole(selector)
    const isManager = userRole === 'teacher' || userRole === 'administrator'

    const id = props.notification.notification_id

    const deleteNotification = (notificationId: string) => {
        return db.collection('notifications').doc(notificationId).delete()
    }

    const eyecatch = props.notification.eyecatch ? props.notification.eyecatch : Noimage

    return (
        <article className='p-grid__list-items'>
            <div className='p-media__thumb eyecatch'>
                <img id='eyecatch' alt='アイキャッチ画像' src={eyecatch} />
            </div>
            <div className='body'>
                <h3 className='u-text__ellipsis-two'>{props.notification.title}</h3>
                <p>{datetimeToString(props.notification.updated_at.toDate())}</p>
            </div>
            {isManager && (
                <div className='buttons'>
                    <button
                        className='p-btn-small p-btn-secondary'
                        onClick={() => dispatch(pushTransition('/pg-admin/notification/edit/' + id))}
                    >
                        編集
                    </button>
                    <button
                        className='p-btn-small p-btn-gray'
                        onClick={() => {
                            const ret = window.confirm('このお知らせを削除しますか？この操作はやり直しできません。')
                            if (!ret) {
                                return false
                            } else {
                                return dispatch(deleteNotification(id))
                            }
                        }}
                    >
                        削除
                    </button>
                </div>
            )}
            <a className='link' onClick={() => dispatch(pushTransition('/notification/' + id))} role='button' />
        </article>
    )
}

export default NotificationListItem
