import { createSelector } from 'reselect'
import State from 'reducks/store/types'

const testsSelector = (state: State) => state.tests

export const getAnswer = createSelector([testsSelector], (state) => state.answer)

export const getHint = createSelector([testsSelector], (state) => state.hint)

export const getHint2 = createSelector([testsSelector], (state) => state.hint2)

export const getQuestion = createSelector([testsSelector], (state) => state.question)

export const getTestItems = createSelector([testsSelector], (state) => state.list)
