import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Button, createStyles } from '@material-ui/core'
import { getLoyaltyPoints, getUserId, getSharedIds } from 'reducks/users/selectors'
import { State } from 'index'
import { db, FirebaseTimestamp } from 'firebase/index'

interface ShareButtonProps {
    text: string
    type: string
    disabled?: boolean
}

const useStyles = makeStyles(() =>
    createStyles({
        twitter: {
            backgroundColor: '#1DA1F2',
            color: '#fff',
            fontSize: 16,
            height: 48,
            marginBottom: 16,
            width: 256,
            '&:hover': {
                backgroundColor: '#fff',
                border: '1px solid #1DA1F2',
                color: '#1DA1F2',
            },
            whiteSpace: 'nowrap',
        },
        line: {
            backgroundColor: '#1dcd00',
            color: '#fff',
            fontSize: 16,
            height: 48,
            marginBottom: 16,
            width: 256,
            '&:hover': {
                backgroundColor: '#fff',
                border: '1px solid #1dcd00',
                color: '#1dcd00',
            },
            whiteSpace: 'nowrap',
        },
        facebook: {
            backgroundColor: '#3b5998',
            color: '#fff',
            fontSize: 16,
            height: 48,
            marginBottom: 16,
            width: 256,
            '&:hover': {
                backgroundColor: '#fff',
                border: '1px solid #3b5998',
                color: '#3b5998',
            },
            whiteSpace: 'nowrap',
        },
    })
)

const ShareButton = (props: ShareButtonProps) => {
    const classes = useStyles()
    const selector = useSelector((state: State) => state)
    const userId = getUserId(selector)
    const sharedIds = getSharedIds(selector)

    const encodedUrl = encodeURI('https://playground.style/official/learning/')
    const encodedText = encodeURI(props.text + '\n')
    const encodedTags = encodeURI('playground')

    const [loyaltyPointCounter, setLoyaltyPointCounter] = useState<number>(getLoyaltyPoints(selector))
    const [url, setUrl] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [buttonClass, setButtonClass] = useState<string>('')

    const setLoyaltyPoints = () => {
        const pathArray: string[] = selector.router.location.pathname.split('/')
        const id: string = pathArray[pathArray.length - 1] // カリキュラム or 課題 ID
        const type: string = pathArray[pathArray.length - 2] // ex. profile curriculum test

        // シェアポイント加算（store & database)
        const counter = loyaltyPointCounter + 1
        setLoyaltyPointCounter(counter)

        if (type !== 'profile') {
            // 配列に値が合ったら処理を中止
            const isShared = sharedIds.includes(id)
            if (isShared) {
                return
            }

            sharedIds.push(id)
            db.collection('users').doc(userId).set(
                {
                    loyalty_points: counter,
                    shared_ids: sharedIds,
                },
                { merge: true }
            )
        } else {
            const now = FirebaseTimestamp.now()
            db.collection('users').doc(userId).set(
                {
                    last_daily_shared_at: now,
                    loyalty_points: counter,
                    shared_ids: sharedIds,
                },
                { merge: true }
            )
        }
    }

    useEffect(() => {
        switch (props.type) {
            case 'twitter':
                setName('Twitter')
                setButtonClass(classes.twitter)
                setUrl(
                    'https://twitter.com/intent/tweet?url=' +
                        encodedUrl +
                        '&text=' +
                        encodedText +
                        '&hashtags=' +
                        encodedTags
                )
                break
            case 'line':
                setName('LINE')
                setButtonClass(classes.line)
                setUrl('http://line.me/R/msg/text/?' + encodedText + '%20' + encodedUrl)
                break
            case 'facebook':
                setName('Facebook')
                setButtonClass(classes.facebook)
                setUrl('https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl + '&t=' + encodedText)
                break
            default:
                break
        }
    }, [props.type])

    return (
        <Button
            className={buttonClass}
            href={url}
            rel='nofollow noopener noreferrer'
            role={'button'}
            disabled={props.disabled}
            target='_blank'
            onClick={() => setLoyaltyPoints()}
        >
            {name}でシェア
        </Button>
    )
}
export default ShareButton
