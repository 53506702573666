import State from './types'

export const initialState: State = {
    chapters: [],
    courses: [],
    curriculum: {
        chapter_id: '',
        comments: [],
        commenterIds: new Set(),
        content: '',
        eyecatch_path: '',
        isPublished: false,
        list: [],
        order: '',
        title: '',
        updated_at: '',
    },
    loading: {
        state: false,
        text: '',
    },
    modal: {
        body: '',
        func: () => null,
        open: false,
        title: '',
        type: '',
    },
    page: {
        title: '',
    },
    supports: {
        calendars: [
            { dayOfWeek: '月', classes: [] },
            { dayOfWeek: '火', classes: [] },
            { dayOfWeek: '水', classes: [] },
            { dayOfWeek: '木', classes: [] },
            { dayOfWeek: '金', classes: [] },
            { dayOfWeek: '土', classes: [] },
            { dayOfWeek: '日', classes: [] },
        ],
        dayOfWeek: '',
        id: '',
        mentor: '',
        mentors: [],
    },
    users: {
        course: '',
        done_tests: [],
        doneTestsAmount: {
            swift: '0/0',
            website: '0/0',
        },
        doneTestsRate: {
            swift: 0,
            website: 0,
        },
        email: '',
        icon_path: '',
        introduction: '',
        isFirstLogin: false,
        isSignedIn: false,
        isSubscriber: false,
        loyaltyPoints: 0,
        read_curriculum: [],
        readCurriculumAmount: {
            swift: '0/0',
            website: '0/0',
        },
        readCurriculumRate: {
            swift: 0,
            website: 0,
        },
        read_notifications: [],
        role: '',
        sharedIds: [],
        uid: '',
        username: '',
    },
    tests: {
        answer: '',
        hint: '',
        hint2: '',
        list: [],
        question: '',
    },
}
