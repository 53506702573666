import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { InputText, PrimaryButton, SelectBox, SimpleTable } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { ApplePayButton, CheckoutForm, PrivacyAgreement } from 'components/Checkout/index'
import Swacrab from 'assets/img/src/pg-swacrab.png'
import { registerMembership } from 'reducks/payments/operations'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const Registration = () => {
    const elements = useElements()
    const dispatch = useDispatch()
    const stripe = useStripe()

    const selector = useSelector((state: State) => state)
    const pathname = selector.router.location.pathname
    const inviterId = pathname.split('/registration/')[1] ? pathname.split('/registration/')[1] : ''
    const pageTitle = getPageTitle(selector)

    const [email, inputEmail] = useState('')
    const [password, inputPassword] = useState('')
    const [confirmPassword, inputConfirmPassword] = useState('')
    const [invitationCode, inputInvitationCode] = useState(inviterId)
    const [username, inputUsername] = useState('')
    const [isPrivacyAgreed, setIsPrivacyAgreed] = useState<boolean>(false)
    const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false)

    const handleIsPrivacyAgreed = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPrivacyAgreed(event.target.checked)
    }, [])

    const handleIsTermsAgreed = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTermsAgreed(event.target.checked)
    }, [])

    const createData = (label: string, value: string) => {
        return { label, value }
    }

    const tableRows = [
        createData('項目', '料金'),
        createData('入会金', '¥0'),
        createData('月額サポート費用', '¥30,000+税/月'),
    ]

    const membershipFeeAmount = 33000

    useEffect(() => {
        const title = 'アカウント登録'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <img src={Swacrab} alt='Swacrab' />
                <div className='module-spacer--small' />

                <InputText
                    id={'username'}
                    type={'text'}
                    label={'ユーザー名'}
                    value={username}
                    onChange={inputUsername}
                    required={true}
                />
                <InputText
                    id={'email'}
                    type={'email'}
                    label={'メールアドレス'}
                    value={email}
                    onChange={inputEmail}
                    required={true}
                />
                <InputText
                    id={'password'}
                    type={'password'}
                    label={'パスワード（半角英数字6文字以上）'}
                    value={password}
                    onChange={inputPassword}
                    required={true}
                />
                <InputText
                    id={'confirm-password'}
                    type={'password'}
                    label={'パスワードの再確認'}
                    value={confirmPassword}
                    onChange={inputConfirmPassword}
                    required={true}
                />
                <InputText
                    id={'invitation-code'}
                    type={'text'}
                    label={'招待コード（半角英数字28桁）'}
                    value={invitationCode}
                    onChange={inputInvitationCode}
                    required={false}
                />
                <p className='u-text-bolder'>コース内容</p>
                <p>
                    upstairs内のカリキュラムを閲覧できます。
                    <br />
                    Slackでのチャットサポートを受けられます。
                    <br />
                    週に1度メンタリングを受けられます。
                </p>
                <div className='module-spacer--extra-extra-small' />
                <SimpleTable rows={tableRows} />
                <div className='module-spacer--small' />

                <p className='u-text-bolder'>決済情報の入力</p>
                <p>以下のクレジットカードとデビットカードに対応しています。</p>
                <p className='u-text-small'>VISA / MasterCard / AMEX / Discover / Diners Club / JCB</p>
                <div className='module-spacer--extra-extra-small' />

                <CheckoutForm />
                <div className='module-spacer--small' />

                <PrivacyAgreement
                    isPrivacyAgreed={isPrivacyAgreed}
                    handleIsPrivacyAgreed={(e) => handleIsPrivacyAgreed(e)}
                    isTermsAgreed={isTermsAgreed}
                    handleIsTermsAgreed={(e) => handleIsTermsAgreed(e)}
                />
                <div className='module-spacer--small' />

                <p className='u-text-small u-text-primary'>
                    決済処理は通信環境の良いところで行ってください。また、決済処理中にブラウザのタブを閉じないようご注意ください。
                </p>
                <div className='module-spacer--small' />

                <div className='center'>
                    <PrimaryButton
                        disabled={
                            username === '' ||
                            email === '' ||
                            password === '' ||
                            confirmPassword === '' ||
                            password !== confirmPassword ||
                            !isPrivacyAgreed ||
                            !isTermsAgreed
                        }
                        label={'アカウントを登録する'}
                        onClick={() =>
                            dispatch(
                                registerMembership({
                                    confirmPassword: confirmPassword,
                                    email: email,
                                    elements: elements,
                                    invitationCode: invitationCode,
                                    isAgreed: isPrivacyAgreed && isTermsAgreed,
                                    membershipFeeAmount: membershipFeeAmount,
                                    password: password,
                                    stripe: stripe,
                                    username: username,
                                })
                            )
                        }
                    />
                </div>
            </div>
        </section>
    )
}

export default Registration
