import React, { useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { SubscribeButtons } from 'components/Supports'
import { Classes } from 'reducks/supports/types'
import { fetchCalendars } from 'reducks/supports/operations'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getCalendars } from 'reducks/supports/selectors'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        column: {
            height: 'auto',
            padding: '0 8px',
            marginBottom: 48,
            [theme.breakpoints.down('sm')]: {
                borderLeft: '1px solid #bdbdbd',
                borderRight: '1px solid #bdbdbd',
                minWidth: '50%',
            },
            [theme.breakpoints.up('sm')]: {
                borderLeft: '1px solid #bdbdbd',
                minWidth: 142,
                '&:last-child': {
                    borderRight: '1px solid #bdbdbd',
                },
            },
        },
    })
)

interface CalendarProps {
    mentor: string
}

const Calendar = (props: CalendarProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)

    const calendars = getCalendars(selector)

    const params = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        spaceBetween: 0,
        slidesPerView: 1,
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 7,
            },
        },
    }

    useEffect(() => {
        if (props.mentor !== '') {
            dispatch(fetchCalendars(props.mentor))
        }
    }, [props.mentor])

    return (
        <Grid container justify='center' spacing={0}>
            {props.mentor !== '' && (
                <Swiper {...params}>
                    {calendars.map((value: { dayOfWeek: string; classes: Classes }) => (
                        <Grid
                            className={classes.column}
                            key={value.dayOfWeek}
                            alignItems='center'
                            direction='column'
                            container
                        >
                            <Grid className='u-text-bolder' item>
                                {value.dayOfWeek}
                            </Grid>
                            <div className='module-spacer--small' />
                            <SubscribeButtons
                                classes={value.classes}
                                dayOfWeek={value.dayOfWeek}
                                mentor={props.mentor}
                            />
                        </Grid>
                    ))}
                </Swiper>
            )}
        </Grid>
    )
}

export default Calendar
