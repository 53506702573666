export const firebaseConfig = ((nodeEnv: string, projectId: string | undefined) => {
    if (nodeEnv === 'production' && projectId === 'lf-school') {
        return {
            apiKey: 'AIzaSyDk6WD_ofZpkk1gzxpAeUwuDq-ECjy-LBs',
            authDomain: 'lf-school.firebaseapp.com',
            projectId: 'lf-school',
            storageBucket: 'lf-school.appspot.com',
            messagingSenderId: '418436710319',
            appId: '1:418436710319:web:4e94e2aaea9fb5d826f95a',
            measurementId: 'G-MNW2GL4NZX',
        }
    } else {
        return {
            apiKey: 'AIzaSyDk6WD_ofZpkk1gzxpAeUwuDq-ECjy-LBs',
            authDomain: 'lf-school.firebaseapp.com',
            projectId: 'lf-school',
            storageBucket: 'lf-school.appspot.com',
            messagingSenderId: '418436710319',
            appId: '1:418436710319:web:4e94e2aaea9fb5d826f95a',
            measurementId: 'G-MNW2GL4NZX',
        }
    }
})(process.env.NODE_ENV, process.env.REACT_APP_PROJECT_ID)
