import React, { useCallback, useEffect, useState } from 'react'
import { SupportEditTable } from 'components/Supports'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getUserId, getUsername } from 'reducks/users/selectors'
import { db, FirebaseTimestamp } from 'firebase/index'
import { SupportsType, SupportType } from 'reducks/supports/types'
import { CheckboxWithLabel, SelectBox, TimePicker } from 'components/UIkit'
import { makeStyles } from '@material-ui/styles'
import { IconButton } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import RefreshIcon from '@material-ui/icons/Refresh'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const useStyles = makeStyles({
    editRow: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 auto',
        maxWidth: 768,
        width: '100%',
    },
    icon: {
        paddding: 0,
        height: 48,
        width: 48,
    },
})

const SupportEdit = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const selector = useSelector((state: State) => state)
    const uid = getUserId(selector)
    const username = getUsername(selector)

    const [supports, setSupports] = useState<SupportsType>([]),
        [editedId, setEditedId] = useState<string>(''),
        [dayOfWeek, setDayOfWeek] = useState<string>(''),
        [isSubscribed, setIsSubscribed] = useState<boolean>(false),
        [startTime, setStartTime] = useState<string>('00:00'),
        [endTime, setEndTime] = useState<string>('00:00')

    const dayOfWeeks = [
        { id: '月', name: '月曜日' },
        { id: '火', name: '火曜日' },
        { id: '水', name: '水曜日' },
        { id: '木', name: '木曜日' },
        { id: '金', name: '金曜日' },
        { id: '土', name: '土曜日' },
        { id: '日', name: '日曜日' },
    ]

    useEffect(() => {
        const unsubscribe = db
            .collection('supports')
            .orderBy('created_at', 'asc')
            .where('uid', '==', uid)
            .onSnapshot((snapshots) => {
                const list: SupportsType = []
                snapshots.forEach((snapshot) => {
                    const data = snapshot.data()
                    list.push({
                        created_at: data.created_at,
                        day_of_week: data.day_of_week,
                        label: data.label,
                        id: data.id,
                        is_subscribed: data.is_subscribed,
                        mentor_name: data.mentor_name,
                        subscriber_id: data.subscriber_id,
                        subscriber_name: data.subscriber_name,
                        term: data.term,
                        uid: data.uid,
                        updated_at: data.updated_at,
                    })
                })
                setSupports([...list])
            })

        return () => unsubscribe()
    }, [])

    const deleteSupportCalendar = useCallback((id: string, isSubscribed: boolean) => {
        const ret = window.confirm('この時間帯のサポート枠を削除しますか？')
        if (!ret) {
            return false
        } else if (isSubscribed) {
            alert('受講中のため削除できません。')
            return false
        } else {
            return db.collection('supports').doc(id).delete()
        }
    }, [])

    const editSupportCalendar = useCallback(
        (support: SupportType) => {
            setEditedId(support.id)
            setDayOfWeek(support.day_of_week)
            setIsSubscribed(support.is_subscribed)
            setStartTime(support.label.split('-')[0])
            setEndTime(support.label.split('-')[1])
        },
        [setEditedId]
    )

    const inputStartTime = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setStartTime(event.target.value)
        },
        [setStartTime]
    )

    const inputEndTime = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setEndTime(event.target.value)
        },
        [setEndTime]
    )

    const handleSubscribed = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsSubscribed(event.target.checked)
        },
        [setIsSubscribed]
    )

    const updateCalendar = () => {
        let id = editedId
        const timestamp = FirebaseTimestamp.now()

        if (id === '') {
            const ref = db.collection('supports').doc()
            id = ref.id
            db.collection('supports')
                .doc(id)
                .set({
                    created_at: timestamp,
                    day_of_week: dayOfWeek,
                    label: startTime + '-' + endTime,
                    id: id,
                    is_subscribed: isSubscribed,
                    mentor_name: username,
                    subscriber_id: '',
                    subscriber_name: '',
                    term: '',
                    uid: uid,
                    updated_at: timestamp,
                })
                .then(() => {
                    setDayOfWeek('')
                    setEditedId('')
                    setIsSubscribed(false)
                    setStartTime('00:00')
                    setEndTime('00:00')
                })
        } else {
            db.collection('supports')
                .doc(id)
                .update({
                    day_of_week: dayOfWeek,
                    is_subscribed: isSubscribed,
                    label: startTime + '-' + endTime,
                    updated_at: timestamp,
                })
                .then(() => {
                    setDayOfWeek('')
                    setEditedId('')
                    setIsSubscribed(false)
                    setStartTime('00:00')
                    setEndTime('00:00')
                })
        }
    }

    const resetState = () => {
        setDayOfWeek('')
        setEditedId('')
        setIsSubscribed(false)
        setStartTime('00:00')
        setEndTime('00:00')
    }

    const pageTitle = getPageTitle(selector)

    useEffect(() => {
        const title = 'サポート時間の管理'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section-wrapin'>
            <h2 className='u-text__headline u-display-none-over-md'>{pageTitle}</h2>
            <div className='module-spacer--medium' />
            <SupportEditTable delete={deleteSupportCalendar} edit={editSupportCalendar} supports={supports} />
            <div className='module-spacer--medium' />
            <div className={classes.editRow}>
                <SelectBox
                    isMinWidth={true}
                    label={'曜日'}
                    options={dayOfWeeks}
                    required={true}
                    select={setDayOfWeek}
                    value={dayOfWeek}
                />
                <TimePicker label={'Start'} onChange={inputStartTime} value={startTime} />
                <TimePicker label={'End'} onChange={inputEndTime} value={endTime} />
                <CheckboxWithLabel checked={isSubscribed} label={'受講状況'} handleChange={handleSubscribed} />
                <IconButton className={classes.icon} onClick={() => updateCalendar()}>
                    <CheckIcon />
                </IconButton>
                <IconButton className={classes.icon} onClick={() => resetState()}>
                    <RefreshIcon />
                </IconButton>
            </div>
        </section>
    )
}

export default SupportEdit
