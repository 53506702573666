import React from 'react'

interface Title {
    title: string
}

const PageTitle = (props: Title) => {
    return <h2 className='u-text__headline'>{props.title}</h2>
}

export default PageTitle
