import React from 'react'
import { Helmet } from 'react-helmet'
import { getUserId } from '../reducks/users/selectors'
import { useSelector } from 'react-redux'
import { State } from '../index'

const ThankYouSupport = () => {
    const selector = useSelector((state: State) => state)
    const uid = getUserId(selector)

    return (
        <>
            <Helmet>
                <script src={`https://r.moshimo.com/af/r/result.js?p_id=2786&pc_id=6333&m_v=${uid}`} id='msmaf' />
                <noscript>{`<img src="https://r.moshimo.com/af/r/result?p_id=2786&pc_id=6333&m_v=${uid}" width="1" height="1" alt="" />`}</noscript>
                <script
                    type='text/javascript'
                    src={`https://js.felmat.net/fmcv.js?adid=G4688N&uqid=${uid}&item=conversion_point_2.1.0`}
                />
            </Helmet>
            <section className='c-section'>
                <div className='c-section__container'>
                    <p>会員登録およびサポート付きプランへのお申し込みが完了しました！🎉</p>
                    <p>ご入力いただいたメールアドレス宛にお送りしたメールをご確認ください。</p>
                </div>
            </section>
        </>
    )
}

export default ThankYouSupport
