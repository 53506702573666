import React, { ChangeEvent } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'

interface PrivacyAgreementProps {
    isPrivacyAgreed: boolean
    handleIsPrivacyAgreed: (e: ChangeEvent<HTMLInputElement>) => void
    isTermsAgreed: boolean
    handleIsTermsAgreed: (e: ChangeEvent<HTMLInputElement>) => void
}

const PrivacyAgreement = (props: PrivacyAgreementProps) => {
    return (
        <>
            <p className='u-text-bolder'>会員登録における個人情報の取扱いに関する同意確認</p>
            <p className='u-text-small'>
                株式会社Playgroundは、弊社が定める以下の「会員登録における個人情報の取扱いに関して」に則り、個人情報を取り扱います。
            </p>
            <p className='u-text-small'>
                また、ご登録いただいたメールアドレス宛に、弊社から重要な情報や新機能のご案内をお送りすることがあります。
            </p>
            <p className='u-text-small'>
                <a href='https://playground.style/privacy_policy/#account' target='_blank' rel='noopener noreferrer'>
                    会員登録における個人情報の取扱いに関して
                </a>
            </p>
            <div className='module-spacer--extra-extra-small' />
            <p className='u-text-bolder'>利用規約への同意</p>
            <p className='u-text-small'>
                会員登録には利用規約への同意が必要です。
                <br />
                以下リンク先をご確認いただき、「同意する」にチェックを入れてください。
            </p>
            <p className='u-text-small'>
                <a href='https://ca-playground.sakura.ne.jp/corp/terms/' target='_blank' rel='noopener noreferrer'>
                    利用規約
                </a>
            </p>
            <div className='module-spacer--extra-extra-small' />
            <div className='center'>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.isPrivacyAgreed}
                            onChange={props.handleIsPrivacyAgreed}
                            value={props.isPrivacyAgreed}
                        />
                    }
                    label='会員登録における個人情報の取扱いに関して同意する'
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.isTermsAgreed}
                            onChange={props.handleIsTermsAgreed}
                            value={props.isTermsAgreed}
                        />
                    }
                    label='利用規約に同意する'
                />
            </div>
        </>
    )
}

export default PrivacyAgreement
