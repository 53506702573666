import React, { useEffect } from 'react'
import { attachFiles } from '../../functions/commonFunc'

interface EyecatchAreaProps {
    imagePath: string
}

const EyecatchArea = (props: EyecatchAreaProps) => {
    useEffect(() => {
        attachFiles('image', 'add')
        return () => {
            attachFiles('image', 'remove')
        }
    }, [])

    return (
        <div className='p-grid__curriculum__eyecatch'>
            <label className='u-text-left'>
                アイキャッチ登録<span className='p-label__optional'>任意</span>
            </label>
            <div className='input__area'>
                <label>
                    <span className='p-btn-rounded p-btn-secondary'>
                        <i className='fas fa-plus mr-1' />
                        ファイルを選択
                        <input className='u-display-none' type='file' id='image' />
                    </span>
                </label>
                <div className='p-media__thumb'>
                    <img id='image-thumb' alt='アイキャッチ画像' src={props.imagePath} />
                </div>
            </div>
        </div>
    )
}

export default EyecatchArea
