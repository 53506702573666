import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import {
    AdminMenu,
    Bank,
    BankEdit,
    ChapterCreate,
    ChapterEdit,
    Chapters,
    CheckoutWrapper,
    Curriculum,
    CurriculumDetail,
    CurriculumEdit,
    Login,
    Notification,
    NotificationEdit,
    Reset,
    SupportEdit,
    Supports,
    SupportSorry,
    Tests,
    TestDetail,
    TestEdit,
    ThankYouRegistration,
    ThankYouSupport,
    UserCreate,
    UserProfile,
    Users,
    NotificationsList,
    Courses,
    SupportManagement,
    // SupportsFromLp,
    Faq,
} from './templates'
import UserEdit from 'templates/UserEdit'
import Auth from './Auth'
import { initializeGA, useTracking } from 'functions/gtag'

const Router = () => {
    useEffect(() => {
        initializeGA()
    }, [])

    useTracking('G-6L83GW515F')
    useTracking('UA-159636317-8')

    return (
        <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/login/reset' component={Reset} />
            <Route path='/registration(/:invitationCode)?' component={CheckoutWrapper} />
            {/*<Route path='/support/registration(/:invitationCode)?' component={CheckoutWrapper} />*/}
            <Route path='/support/registration(/:invitationCode)?' component={SupportSorry} />
            <Route exact path='/thankyou' component={ThankYouRegistration} />
            <Route exact path='/supportThankyou' component={ThankYouSupport} />
            {/*<Route exact path='/supports/lp' component={SupportsFromLp} />*/}
            {/* Redirect to google form for unsubscribed user */}
            <Route
                exact
                path='/unsubscribe'
                component={() => {
                    window.location.replace('https://forms.gle/5ZCMbEsPj5fJNcTG6')
                    return null
                }}
            />

            <Auth>
                <Switch>
                    <Route exact path='/courses' component={Courses} />

                    {/* Curriculum Routes */}
                    <Route exact path='/swift' component={Curriculum} />
                    <Route exact path='/website' component={Curriculum} />
                    <Route exact path='/swift/curriculum/:id' component={CurriculumDetail} />
                    <Route exact path='/website/curriculum/:id' component={CurriculumDetail} />
                    <Route exact path='/pg-admin/curriculum/create' component={CurriculumEdit} />
                    <Route exact path='/pg-admin/curriculum/edit/:id' component={CurriculumEdit} />

                    {/* Chapters Routes */}
                    <Route exact path='/pg-admin/chapters' component={Chapters} />
                    <Route exact path='/pg-admin/chapter/create' component={ChapterCreate} />
                    <Route exact path='/pg-admin/chapter/edit/:id' component={ChapterEdit} />

                    {/* Notifications Routes */}
                    <Route exact path='/notifications' component={NotificationsList} />
                    <Route exact path='/notification/:id' component={Notification} />
                    <Route path='/pg-admin/notification/edit(/:id)?' component={NotificationEdit} />

                    {/* Supports Routes */}
                    <Route exact path='/supports' component={Supports} />
                    <Route exact path='/pg-admin/supports' component={SupportManagement} />
                    <Route exact path='/pg-admin/supports/edit' component={SupportEdit} />
                    <Route exact path='/support/checkout' component={CheckoutWrapper} />

                    {/* Test Routes */}
                    <Route exact path='/tests'>
                        <Redirect to='/courses' />
                    </Route>
                    <Route exact path='/swift/tests' component={Tests} />
                    <Route exact path='/website/tests' component={Tests} />
                    <Route exact path='/swift/test/:id' component={TestDetail} />
                    <Route exact path='/website/test/:id' component={TestDetail} />
                    <Route path='/pg-admin/test/edit(/:id)?' component={TestEdit} />

                    {/* Users Routes */}
                    <Route exact path='/user/edit' component={UserEdit} />
                    <Route exact path='/user/profile/:id' component={UserProfile} />
                    <Route exact path='/pg-admin/users' component={Users} />
                    <Route exact path='/pg-admin/users/create' component={UserCreate} />
                    <Route exact path='/bank' component={Bank} />
                    <Route exact path='/bank/edit' component={BankEdit} />

                    {/* Root Routes */}
                    <Route exact path='/pg-admin' component={AdminMenu} />
                    <Route exact path='(/)?'>
                        <Redirect to='/courses' />
                    </Route>
                    <Route exact path='/1'>
                        <Redirect to='/courses' />
                    </Route>

                    <Route exact path='/faq' component={Faq} />
                </Switch>
            </Auth>
        </Switch>
    )
}

export default Router
