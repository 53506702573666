import React, { useEffect, useState } from 'react'
import { InputText } from '../components/UIkit'
import { useDispatch } from 'react-redux'
import { signIn } from 'reducks/users/operations'
import { setTitleAction } from 'reducks/page/action'

const Login = () => {
    const dispatch = useDispatch()
    const [email, inputEmail] = useState('')
    const [password, inputPassword] = useState('')

    useEffect(() => {
        const query: string | undefined = window.location.search.split('?email=')[1]
        if (query) {
            inputEmail(query)
        }
    }, [])

    useEffect(() => {
        const title = 'ログイン'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section__container'>
                <p className='u-text-primary' id='log' />
                <InputText
                    id={'email'}
                    type={'email'}
                    label={'Email'}
                    value={email}
                    onChange={inputEmail}
                    required={true}
                />
                <InputText
                    id={'password'}
                    type={'password'}
                    label={'Password'}
                    value={password}
                    onChange={inputPassword}
                    required={true}
                />
                <div className='module-spacer--extra-extra-small' />
                <button className='p-btn-rounded' onClick={() => dispatch(signIn(email, password))}>
                    ログイン
                </button>
                <div className='module-spacer--small' />
                <a className='u-text__link center' href='/login/reset'>
                    パスワードを忘れた方はこちら
                </a>
                <div className='module-spacer--extra-extra-small' />
                <a className='u-text__link center' href='/registration'>
                    アカウント登録がまだですか？
                </a>
            </div>
        </section>
    )
}

export default Login
