import React, { useEffect } from 'react'
import { CommentList, CommentPost } from 'components/Curriculum'
import { useDispatch, useSelector } from 'react-redux'
import { fetchComments } from 'reducks/curriculum/operations'
import { State } from 'index'
import { getComments } from 'reducks/curriculum/selectors'

interface CommentProps {
    id: string
}

const CommentArea = (props: CommentProps) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const comments = getComments(selector)

    useEffect(() => {
        if (props.id) {
            dispatch(fetchComments(props.id))
        }
    }, [props.id])

    return (
        <div className='p-grid__comment'>
            <h2>コメント（質問はこちらへ）</h2>
            <div className='module-spacer--medium' />
            {comments.length > 0 && <CommentList comments={comments} curriculumId={props.id} />}
            <CommentPost curriculumId={props.id} label={'コメントを書き込む'} rows={5} />
        </div>
    )
}

export default CommentArea
