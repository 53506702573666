import React, { useEffect, useState } from 'react'
import { InputText, SelectBox } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { fetchChapters, saveChapter } from 'reducks/chapters/operations'
import { State } from '../index'
import { ChapterItem } from 'reducks/chapters/types'
import { getChapterItems } from 'reducks/chapters/selectors'
import { goBackTransition } from 'reducks/router/operation'
import { getCourses } from 'reducks/courses/selectors'
import { getCourseId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'
import { fetchCourses } from 'reducks/courses/operations'

type Options = { id: string; name: string }[]

const ChapterEdit = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const chapters = getChapterItems(selector)
    const course = getCourseId(selector)
    const pageTitle = getPageTitle(selector)
    const courseOptions: Options = getCourses(selector)

    const [chapterName, inputChapterName] = useState(''),
        [courseId, selectCourseId] = useState<string>(''),
        [order, inputOrder] = useState('')

    const location = useSelector((state: State) => state.router.location)
    const chapterId = location.pathname.split('/pg-admin/chapter/edit/')[1]

    useEffect(() => {
        if (chapters.length > 0) {
            const chapter = chapters.filter((item: ChapterItem) => item.chapter_id === chapterId)[0]
            inputOrder(chapter.order)
            inputChapterName(chapter.chapter_name)
            selectCourseId(chapter.course_id ? chapter.course_id : '')
        } else {
            dispatch(fetchChapters(course))
        }
    }, [chapters.length])

    useEffect(() => {
        const title = 'チャプターの編集'
        dispatch(setTitleAction(title))

        if (courseOptions.length === 0) {
            dispatch(fetchCourses())
        }
    }, [])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <div className='module-spacer--small' />
                <InputText
                    id={'chapter_name'}
                    label={'【チャプター名】'}
                    onChange={inputChapterName}
                    required={true}
                    type={'text'}
                    value={chapterName}
                />
                <InputText
                    id={'order'}
                    label={'4or6桁の数字'}
                    onChange={inputOrder}
                    required={true}
                    type={'text'}
                    value={order}
                />
                <SelectBox
                    label={'コース'}
                    required={true}
                    options={courseOptions}
                    select={selectCourseId}
                    value={courseId}
                />
                <div className='module-spacer--small' />
                <button
                    className='p-btn-rounded'
                    onClick={() => dispatch(saveChapter(chapterId, chapterName, courseId, order))}
                >
                    保存する
                </button>
                <button
                    className='p-btn-rounded p-btn-gray'
                    onClick={() => {
                        dispatch(goBackTransition())
                    }}
                >
                    前のページに戻る
                </button>
            </div>
        </section>
    )
}

export default ChapterEdit
