import React, { useState, useEffect } from 'react'
import { InputText } from '../components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from 'reducks/users/operations'
import { State } from 'index'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const Reset = () => {
    const dispatch = useDispatch()
    const [email, inputEmail] = useState('')
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)

    useEffect(() => {
        const title = 'パスワードリセット'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <p className='u-text-primary' id='log' />
                <InputText
                    id={'email'}
                    type={'email'}
                    label={'Email'}
                    value={email}
                    onChange={inputEmail}
                    required={true}
                />
                <div className='module-spacer--small' />
                <button className='p-btn-rounded' onClick={() => dispatch(resetPassword(email))}>
                    リセットする
                </button>
                <div className='module-spacer--small' />
                <a className='u-text__link center' href='/login'>
                    ログイン画面に戻る
                </a>
            </div>
        </section>
    )
}

export default Reset
