import React, { useCallback, useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import TableContainer from '@material-ui/core/TableContainer'
import EditIcon from '@material-ui/icons/Edit'
import { makeStyles } from '@material-ui/styles'
import { TableHead } from '@material-ui/core'
import { db } from 'firebase/index'
import { SupportsType, SupportType } from 'reducks/supports/types'
import { dateToString } from 'functions/commonFunc'
import { DatePicker } from '../UIkit'
import DoneIcon from '@material-ui/icons/Done'

const useStyles = makeStyles({
    root: {
        margin: '0 auto',
        maxWidth: 1440,
        width: '100%',
        '& th': {
            fontSize: 16,
            fontWeight: 600,
        },
    },
    iconCell: {
        padding: 0,
        height: 48,
        width: 48,
    },
})

interface SupportedUser {
    email: string
    username: string
    mentorName: string
    term: string
    firstSupport: SupportType
    secondSupport: SupportType | null
    applyDate: string
}
interface SupportedUsers extends Array<SupportedUser> {}

const SupportManagementTable = () => {
    const classes = useStyles()

    const [supportedUsers, setSupportedUsers] = useState<SupportedUsers>([]),
        [supports, setSupports] = useState<SupportsType>([]),
        [editIndex, setEditIndex] = useState<number>(-1),
        [startDate, setStartDate] = useState<string>(''),
        [endDate, setEndDate] = useState<string>('')

    const handleStartDate = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setStartDate(event.target.value)
        },
        [setStartDate]
    )

    const handleEndDate = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setEndDate(event.target.value)
        },
        [setEndDate]
    )

    const handleEdit = (index: number) => {
        const supportedUser = supportedUsers[index]
        const firstSupport = supportedUser.firstSupport
        setStartDate(firstSupport.start_date ? firstSupport.start_date : '')
        setEndDate(firstSupport.end_date ? firstSupport.end_date : '')
        setEditIndex(index)
    }

    const editDone = (index: number) => {
        const supportedUser = supportedUsers[index]

        const firstSupport = supportedUser.firstSupport
        db.collection('supports').doc(firstSupport.id).set(
            {
                start_date: startDate,
                end_date: endDate,
            },
            { merge: true }
        )

        const secondSupport = supportedUser.secondSupport
        if (secondSupport) {
            db.collection('supports').doc(secondSupport.id).set(
                {
                    start_date: startDate,
                    end_date: endDate,
                },
                { merge: true }
            )
        }

        setStartDate('')
        setEndDate('')
        setEditIndex(-1)
    }

    // Set the term options and the table of terms and price
    const terms = [
        { id: 'curriculumPlan', name: 'カリキュラム達成プラン(3ヶ月)' },
        { id: 'coDevelopmentPlan', name: '共同開発プラン(4ヶ月)' },
        { id: 'appReleasePlan', name: 'アプリリリースプラン(4ヶ月)' },
        { id: 'fullPlan', name: 'アプリリリース×共同開発プラン(5ヶ月)' },
    ]

    useEffect(() => {
        const unsubscribe = db
            .collection('supports')
            .where('is_subscribed', '==', true)
            .onSnapshot((snapshots) => {
                let list: SupportsType = []
                snapshots.docChanges().forEach((change) => {
                    const data = change.doc.data()
                    const updateData = {
                        created_at: data.created_at,
                        day_of_week: data.day_of_week,
                        end_date: data.end_date ? data.end_date : '',
                        label: data.label,
                        id: data.id,
                        mentor_name: data.mentor_name,
                        is_subscribed: data.is_subscribed,
                        start_date: data.start_date ? data.start_date : '',
                        subscriber_id: data.subscriber_id,
                        subscriber_name: data.subscriber_name,
                        term: data.term,
                        uid: data.uid,
                        updated_at: data.updated_at,
                    }

                    if (change.type === 'added') {
                        list.push(updateData)
                    } else if (change.type === 'modified') {
                        const listIndex = list.findIndex((support) => support.id === change.doc.id)
                        list[listIndex] = updateData
                    } else {
                        list = list.filter((support) => support.id !== change.doc.id)
                    }
                })
                setSupports([...list])
            })
        return () => unsubscribe()
    }, [])

    useEffect(() => {
        if (supports.length > 0) {
            const usersValue: SupportedUsers = []
            db.collection('users')
                .where('role', '==', 'supported')
                .get()
                .then((snapshots) => {
                    snapshots.forEach((snapshot) => {
                        const data = snapshot.data()
                        const subscribedSupports = supports.filter((support) => support.subscriber_id === snapshot.id)
                        const firstSupport = subscribedSupports[0]
                        const secondSupport = subscribedSupports.length === 2 ? subscribedSupports[1] : null
                        const termId: string = firstSupport.term
                        const term = terms.filter((term) => term.id === termId)[0]

                        usersValue.push({
                            email: data.email,
                            username: data.username,
                            mentorName: firstSupport.mentor_name,
                            term: term.name,
                            firstSupport: firstSupport,
                            secondSupport: secondSupport,
                            applyDate: dateToString(firstSupport.updated_at.toDate()),
                        })
                    })

                    setSupportedUsers([...usersValue])
                })
        }
    }, [supports])

    return (
        <TableContainer>
            <Table aria-label='simple table' className={classes.root}>
                <TableHead>
                    <TableRow>
                        <TableCell>ユーザー名</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>メンター名</TableCell>
                        <TableCell>プラン名</TableCell>
                        <TableCell>サポート①</TableCell>
                        <TableCell>サポート②</TableCell>
                        <TableCell>申込日</TableCell>
                        <TableCell>開始日</TableCell>
                        <TableCell>終了日</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {supportedUsers.length > 0 &&
                        supportedUsers.map((supportedUser: SupportedUser, index: number) => (
                            <TableRow key={supportedUser.email}>
                                <TableCell>{supportedUser.email}</TableCell>
                                <TableCell>{supportedUser.username}</TableCell>
                                <TableCell>{supportedUser.mentorName}</TableCell>
                                <TableCell>{supportedUser.term}</TableCell>
                                <TableCell>
                                    {supportedUser.firstSupport.day_of_week +
                                        '曜日 ' +
                                        supportedUser.firstSupport.label}
                                </TableCell>
                                <TableCell>
                                    {supportedUser.secondSupport
                                        ? supportedUser.secondSupport.day_of_week +
                                          '曜日 ' +
                                          supportedUser.secondSupport.label
                                        : ''}
                                </TableCell>
                                <TableCell>{supportedUser.applyDate}</TableCell>
                                <TableCell>
                                    {editIndex === index ? (
                                        <DatePicker date={startDate} handleDateChange={handleStartDate} />
                                    ) : (
                                        supportedUser.firstSupport.start_date
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editIndex === index ? (
                                        <DatePicker date={endDate} handleDateChange={handleEndDate} />
                                    ) : (
                                        supportedUser.firstSupport.end_date
                                    )}
                                </TableCell>
                                <TableCell className={classes.iconCell}>
                                    {editIndex === index ? (
                                        <IconButton className={classes.iconCell} onClick={() => editDone(index)}>
                                            <DoneIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton className={classes.iconCell} onClick={() => handleEdit(index)}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SupportManagementTable
