import React, { useEffect, useCallback, useState } from 'react'
import { db } from 'firebase/index'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../index'
import { GreyButton, SecondaryButton, TextDetail } from 'components/UIkit'
import { getSignedIn, getUserId } from 'reducks/users/selectors'
import { pushTransition } from 'reducks/router/operation'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const Bank = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const isSignedIn = getSignedIn(selector)
    const uid: string = getUserId(selector)
    const pageTitle = getPageTitle(selector)

    const [firstName, setFirstName] = useState(''),
        [lastName, setLastName] = useState(''),
        [firstKanaName, setFirstKanaName] = useState(''),
        [lastKanaName, setLastKanaName] = useState(''),
        [zipCode, setZipCode] = useState(''),
        [address, setAddress] = useState(''),
        [building, setBuilding] = useState(''),
        [bankCode, setBankCode] = useState(''),
        [bankName, setBankName] = useState(''),
        [branchCode, setBranchCode] = useState(''),
        [branchName, setBranchName] = useState(''),
        [accountNumber, setAccountNumber] = useState(''),
        [accountType, setAccountType] = useState('')

    const pushTransitionEditBankPage = useCallback(() => {
        dispatch(pushTransition('/bank/edit'))
    }, [])

    const pushTransitionProfilePage = useCallback(() => {
        dispatch(pushTransition('/user/profile/' + uid))
    }, [])

    useEffect(() => {
        if (isSignedIn) {
            db.collection('users')
                .doc(uid)
                .collection('banks')
                .doc(uid)
                .get()
                .then((snapshot) => {
                    const bankData = snapshot.data()
                    if (bankData) {
                        setFirstName(bankData.first_name)
                        setLastName(bankData.last_name)
                        setFirstKanaName(bankData.first_kana_name)
                        setLastKanaName(bankData.last_kana_name)
                        setZipCode(bankData.zip_code)
                        setAddress(bankData.address)
                        setBuilding(bankData.building)
                        setBankCode(bankData.bank_code)
                        setBankName(bankData.bank_name)
                        setBranchCode(bankData.branch_code)
                        setBranchName(bankData.branch_name)
                        setAccountNumber(bankData.account_number)
                        setAccountType(bankData.account_type)
                    }
                })
        }
    }, [isSignedIn])

    useEffect(() => {
        const title = 'お振込先情報の確認'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <p>紹介制度のキャッシュバックを受け取っていただくために、ご登録いただく情報です。</p>
                <TextDetail label={'姓名'} value={lastName + ' ' + firstName} />
                <TextDetail label={'フリガナ'} value={lastKanaName + ' ' + firstKanaName} />
                <TextDetail label={'郵便番号'} value={zipCode} />
                <TextDetail label={'住所・番地'} value={address} />
                <TextDetail label={'建物名・部屋番号'} value={building} />
                <TextDetail label={'銀行名'} value={bankName} />
                <TextDetail label={'銀行コード'} value={bankCode} />
                <TextDetail label={'支店名'} value={branchName} />
                <TextDetail label={'支店コード'} value={branchCode} />
                <TextDetail label={'口座種類'} value={accountType} />
                <TextDetail label={'口座番号'} value={accountNumber} />

                <section className='center'>
                    <SecondaryButton label={'お振込先情報の編集'} onClick={pushTransitionEditBankPage} />
                    <GreyButton label={'プロフィール画面に戻る'} onClick={pushTransitionProfilePage} />
                </section>
            </div>
        </section>
    )
}

export default Bank
