import React from 'react'
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    root: {
        position: 'relative',
        '& > ul': {
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },
})

interface PaginatorProps {
    currentPage: number
    pageLength: number
    selectPage: (event: object, page: number) => void
}

const Paginator = (props: PaginatorProps) => {
    const classes = useStyles()

    return (
        <div className='center'>
            <Pagination
                className={classes.root}
                color={'primary'}
                count={props.pageLength}
                page={props.currentPage}
                onChange={props.selectPage}
            />
        </div>
    )
}

export default Paginator
