import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import Router from 'Router'
import { HeadTags, Loading, ScrollToTop } from 'components/UIkit'
import { Header } from 'components/Header'
import { Footer } from 'components/Footer'
import { Modal } from './templates'

const App = () => {
    // Google Tag Manager
    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-5GKXQPK' })
    }, [])

    return (
        <Loading>
            <Modal>
                <ScrollToTop />
                <HeadTags />
                <Header />
                <main>
                    <section>
                        <div className='module-spacer--medium' />
                        <div className='module-spacer--medium' />
                    </section>

                    <Router />

                    <section>
                        <div className='module-spacer--medium' />
                        <div className='module-spacer--medium' />
                    </section>
                </main>
                <Footer />
            </Modal>
        </Loading>
    )
}

export default App
