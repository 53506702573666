import { auth, db, functions, FirebaseTimestamp } from 'firebase/index'
import { hideLoadingAction, showLoadingAction } from 'reducks/loading/action'
import { CardElement } from '@stripe/react-stripe-js'
import { signInAction } from 'reducks/users/action'
import { push } from 'connected-react-router'
import { isValidEmailFormat, isValidRequiredInput } from 'functions/commonFunc'
import * as stripeJs from '@stripe/stripe-js'
import { pushTransition } from 'reducks/router/operation'
import { openTextModalAction } from '../modal/action'

// Declare constraints
const usersRef = db.collection('users')
const invitationCampaignRef = db.collection('campaigns').doc('invitation')
const headers = new Headers()
headers.set('Content-type', 'application/json')
const BASE_URL =
    process.env.NODE_ENV === 'production' ? 'https://pg-learning.net' : 'https://pg-school-dev-4ce92.firebaseapp.com'

const alertCardError = (response: { paymentIntent?: stripeJs.PaymentIntent; error?: stripeJs.StripeError }): string => {
    if (response.error) {
        // Error code reference: https://stripe.com/docs/error-codes
        switch (response.error.code) {
            case 'card_declined':
                return 'カードによる支払いが拒否されました。ご利用のカード会社にお問い合わせください。'
            case 'parameter_missing':
                return '決済処理中に問題が発生しました。ご利用のカード会社にお問い合わせください。'
            default:
                if (response.error.message) {
                    return '決済処理中に問題が発生しました。' + response.error.message
                } else {
                    return '決済処理中に問題が発生しました。時間を置いてもう1度お試しいただくか、カード会社にお問い合わせください。'
                }
        }
    } else {
        return '決済処理中に問題が発生しました。'
    }
}

const disableUser = async (userId: string): Promise<boolean> => {
    const batch = db.batch()
    const timestamp = FirebaseTimestamp.now()
    const userRef = db.collection('users').doc(userId)
    batch.update(userRef, {
        is_subscriber: false,
        role: 'student',
        updated_at: timestamp,
    })

    const paymentRef = userRef.collection('payments')
    await paymentRef.get().then((snapshots) => {
        snapshots.forEach((doc) => {
            batch.delete(paymentRef.doc(doc.id))
        })
    })

    return batch
        .commit()
        .then(() => {
            return true
        })
        .catch((error) => {
            console.error(error)
            return false
        })
}

const getStripeId = (userId: string, feeType: string): Promise<string | null> => {
    return db
        .collection('users')
        .doc(userId)
        .collection('payments')
        .doc(feeType)
        .get()
        .then((doc) => {
            const data = doc.data()
            // Check the document data exists
            if (!data) {
                return null
            }

            // Verify the user's payment has already been captured
            if (feeType !== 'membership_fee' && data.is_valid) {
                return null
            }

            return data.stripe_id
        })
}

const getUserIds = async () => {
    return usersRef.get().then((snapshots) => {
        const userIds: string[] = []
        snapshots.forEach((doc) => {
            userIds.push(doc.id)
        })
        return userIds
    })
}

interface RegisterNewUserType {
    confirmPassword: string
    email: string
    invitationCode: string
    isAgreed: boolean
    password: string
    username: string
}

const registerNewUser = async (values: RegisterNewUserType) => {
    // Validations
    if (!isValidRequiredInput(values.email, values.password, values.confirmPassword)) {
        alert('必須項目が未入力です。')
        return null
    }
    if (!isValidEmailFormat(values.email)) {
        alert('メールアドレスの形式が不正です。もう1度お試しください。')
        return null
    }
    if (values.password !== values.confirmPassword) {
        alert('パスワードが一致しません。もう1度お試しください。')
        return null
    }
    if (values.password.length < 6) {
        alert('パスワードは6文字以上で入力してください。')
        return null
    }
    if (!values.isAgreed) {
        alert('個人情報の取扱および利用規約に「同意する」をチェックしてください。')
        return null
    }

    // Validate invitation code if a user input it
    if (values.invitationCode !== '') {
        if (values.invitationCode.length !== 28) {
            alert(
                '招待コードは28桁です。手入力した場合はもう1度ご確認ください。招待リンクからの登録時は28桁の招待コードが自動入力されます。'
            )
            return null
        }

        const userIds: string[] = await getUserIds()
        if (!userIds.includes(values.invitationCode)) {
            alert(
                '不正な招待コードです。手入力した場合はもう1度ご確認ください。招待リンクからの登録時は28桁の招待コードが自動入力されます。'
            )
            return null
        }
    }

    return auth
        .createUserWithEmailAndPassword(values.email, values.password)
        .then((result) => {
            const user = result.user
            if (user) {
                const userId = user.uid
                const batch = db.batch()
                const timestamp = FirebaseTimestamp.now()

                const userInitialData = {
                    created_at: timestamp,
                    email: values.email,
                    icon_path: '',
                    introduction: '',
                    is_first_login: true,
                    is_subscriber: false,
                    role: 'student',
                    username: values.username,
                    updated_at: timestamp,
                }

                batch.set(usersRef.doc(userId), userInitialData)
                batch.set(invitationCampaignRef.collection('users').doc(userId), {
                    created_at: timestamp,
                    is_paid: false, // This cache back is paid or not
                    inviter_id: values.invitationCode, // The user ID who invites this new user
                    updated_at: timestamp,
                })

                if (values.invitationCode !== '') {
                    batch.set(
                        invitationCampaignRef
                            .collection('users')
                            .doc(values.invitationCode)
                            .collection('invitations')
                            .doc(userId),
                        {
                            created_at: timestamp,
                            invitee_id: userId,
                            is_paid: false, // This cache back is paid or not
                            is_valid: false, // The user has paid or not after registration
                            updated_at: timestamp,
                        },
                        { merge: true }
                    )
                }

                return batch.commit().then(async () => {
                    return userId
                })
            } else {
                return null
            }
        })
        .catch((error) => {
            if (error.code === 'auth/email-already-in-use') {
                alert('すでに登録済みのメールアドレスです。')
            }
            console.error(error.message)
            return null
        })
}

interface TransactPaymentType {
    customerId: string
    membershipFeeAmount: number
    paymentMethodId: string
    subscriptionId: string
    userId: string
}

const transactRegistrationPayment = (values: TransactPaymentType) => {
    return db
        .runTransaction((transaction) => {
            const userId = values.userId

            // Confirm this user had used invitation code or not when he/she registered
            return transaction.get(invitationCampaignRef.collection('users').doc(userId)).then((campaignDoc) => {
                try {
                    if (!campaignDoc.exists) {
                        throw new Error('Invalid campaign!!')
                    }

                    const timestamp = FirebaseTimestamp.now()
                    const data = campaignDoc.data()
                    const inviterId = data ? data.inviter_id : ''

                    // Record payment data about the monthly membership fee
                    const membershipFeeValue = {
                        amount: values.membershipFeeAmount,
                        created_at: timestamp,
                        is_valid: true,
                        payer_id: userId,
                        stripe_id: values.subscriptionId,
                        updated_at: timestamp,
                    }

                    transaction.set(
                        usersRef.doc(userId).collection('payments').doc('membership_fee'),
                        membershipFeeValue
                    )

                    transaction.set(
                        usersRef.doc(userId),
                        {
                            customer_id: values.customerId,
                            payment_method_id: values.paymentMethodId,
                            is_subscriber: true,
                        },
                        { merge: true }
                    )

                    // Update campaign data if this user had used invitation code
                    if (inviterId !== '') {
                        transaction.update(
                            invitationCampaignRef
                                .collection('users')
                                .doc(inviterId)
                                .collection('invitations')
                                .doc(userId),
                            {
                                is_valid: true,
                                updated_at: timestamp,
                            }
                        )
                    }
                } catch (e) {
                    console.error(e)
                }
            })
        })
        .then(() => {
            return true
        })
        .catch((error) => {
            throw new Error(`Transaction Failed... ${error}`)
        })
}

interface RegisterMembershipType {
    confirmPassword: string
    email: string
    elements: stripeJs.StripeElements | null
    invitationCode: string
    isAgreed: boolean
    membershipFeeAmount: number
    password: string
    stripe: stripeJs.Stripe | null
    username: string
}

export const registerMembership = (values: RegisterMembershipType) => {
    return async (dispatch: any) => {
        const stripe = values.stripe
        const elements = values.elements

        dispatch(showLoadingAction('決済処理中...'))

        //*********************** START VALIDATION **************************//
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            console.error('Does not exist stripe or elements')
            dispatch(hideLoadingAction())
            return
        }

        // Get a reference to a mounted CardInputForm.
        const cardElement = elements.getElement(CardElement)
        if (!cardElement) {
            console.error('Does not exist cardElement')
            dispatch(hideLoadingAction())
            return
        }

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        })

        if (error) {
            console.error(error.message)
            dispatch(hideLoadingAction())
            return
        }

        //*********************** END VALIDATION **************************//

        // Create an account by Firebase Authentication
        const uid: string | null = await registerNewUser({
            confirmPassword: values.confirmPassword,
            email: values.email,
            invitationCode: values.invitationCode,
            isAgreed: values.isAgreed,
            password: values.password,
            username: values.username,
        })

        if (!uid) {
            dispatch(hideLoadingAction())
            return
        }

        if (!paymentMethod) {
            dispatch(hideLoadingAction())
            return
        }
        const paymentMethodId = paymentMethod.id

        // Create customer on Stripe and register the email.
        const createCustomer = await fetch(BASE_URL + '/v1/customer', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                email: values.email,
                paymentMethod: paymentMethodId,
                userId: uid,
            }),
        })

        const customerResponse = await createCustomer.json()
        const customerData = JSON.parse(customerResponse.body)
        const customerId = customerData.id

        // Create subscription plan
        const createSubscription = await fetch(BASE_URL + '/v1/subscription', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                customerId: customerId,
                email: values.email,
                paymentMethodId: paymentMethodId,
            }),
        })

        const subscriptionResponse = await createSubscription.json()
        const subscriptionData = JSON.parse(subscriptionResponse.body)

        const hasSucceededToSubscribe = subscriptionData && subscriptionData.status === 'active'
        if (!hasSucceededToSubscribe) {
            await usersRef.doc(uid).delete()
            dispatch(hideLoadingAction())
            const errorMessage = alertCardError(subscriptionData.error)
            alert(errorMessage)
            return false
        }

        const transactionResult = await transactRegistrationPayment({
            customerId: customerId,
            membershipFeeAmount: values.membershipFeeAmount,
            paymentMethodId: paymentMethodId,
            subscriptionId: subscriptionData.id,
            userId: uid,
        })

        if (transactionResult) {
            const sendThankYouMail = functions.httpsCallable('sendThankYouMail')
            await sendThankYouMail({
                email: values.email,
                userId: uid,
                username: values.username,
            })

            if (process.env.NODE_ENV === 'production') {
                await sendSlackNotification(values.email, '新規の有料会員登録がありました！🎉🎉🎉', values.username)
            }
            dispatch(
                signInAction({
                    course: '',
                    customer_id: customerId,
                    done_tests: [],
                    doneTestsAmount: {},
                    doneTestsRate: {},
                    email: values.email,
                    icon_path: '',
                    introduction: '',
                    isSignedIn: true,
                    isFirstLogin: true,
                    isSubscriber: true,
                    loyaltyPoints: 0,
                    read_curriculum: [],
                    readCurriculumAmount: {},
                    readCurriculumRate: {},
                    read_notifications: [],
                    role: 'student',
                    payment_method_id: paymentMethodId,
                    sharedIds: [],
                    uid: uid,
                    username: values.username,
                })
            )

            dispatch(hideLoadingAction())
            dispatch(push('/thankyou'))
            return true
        } else {
            await usersRef.doc(uid).delete()
            await sendSlackNotification(
                values.email,
                'ユーザーの決済処理後に不具合が発生しました。Stripeのダッシュボードを確認してください。',
                values.username
            )
            dispatch(hideLoadingAction())
            alert('決済処理中に問題が発生しました。運営会社が調査をするため連絡をお待ちください。')
            return
        }
    }
}

const confirmInputPassword = (email: string, password: string): Promise<boolean> => {
    return auth
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
            return !!user
        })
        .catch(() => {
            return false
        })
}

interface TransactSupportPaymentType {
    paymentId: string
    supportFeeAmount: number
    uid: string
    username: string
}

const transactSupportPayment = (values: TransactSupportPaymentType): Promise<boolean> => {
    const uid = values.uid

    // Confirm this user had used invitation code or not when he/she registered
    const batch = db.batch()
    const timestamp = FirebaseTimestamp.now()

    const supportPaymentValue = {
        amount: values.supportFeeAmount,
        created_at: timestamp,
        is_valid: false,
        payer_id: uid,
        stripe_id: values.paymentId,
    }

    batch.set(usersRef.doc(uid).collection('payments').doc('support_fee'), supportPaymentValue)
    batch.update(usersRef.doc(uid), { role: 'supported' })

    return batch
        .commit()
        .then(() => {
            return true
        })
        .catch((e) => {
            console.error(e)
            return false
        })
}

interface SubscribeSupportType {
    customerId: string
    email: string
    paymentMethodId: string
    role: string
    supportFeeAmount: number
    uid: string
    username: string
}

export const subscribeSupport = (values: SubscribeSupportType) => {
    return async (dispatch: any) => {
        const supportFeeAmount = Math.floor(values.supportFeeAmount * 1.1)

        dispatch(showLoadingAction('決済処理中...'))

        if (values.role !== 'student') {
            dispatch(hideLoadingAction())
            alert('すでにサポート付きプランに申し込み済みです。')
            return
        }

        const createSubscription = await fetch(BASE_URL + '/v1/supportSubscription', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                customerId: values.customerId,
                paymentMethodId: values.paymentMethodId,
            }),
        })

        const subscriptionResponse = await createSubscription.json()
        const subscriptionData = JSON.parse(subscriptionResponse.body)

        const transactionResult = await transactSupportPayment({
            paymentId: subscriptionData.id,
            supportFeeAmount: supportFeeAmount,
            uid: values.uid,
            username: values.username,
        })

        if (transactionResult) {
            dispatch(hideLoadingAction())

            if (process.env.NODE_ENV === 'production') {
                await sendSlackNotification(values.email, '学習サポートに申込がありました！🎉🎉🎉', values.username)
            }

            const body = `<p>
                            サポート付きプランのお申し込みが完了しました！<br/>
                            運営事務局からメールでご連絡いたします。
                          </p>`
            dispatch(openTextModalAction(body, () => pushTransition('/'), 'Thank you for your application!'))

            return true
        } else {
            await sendSlackNotification(
                values.email,
                'ユーザーの決済処理後に不具合が発生しました。Stripeのダッシュボードを確認してください。',
                values.username
            )
            dispatch(hideLoadingAction())
            alert('決済処理中に問題が発生しました。運営会社が調査をするため連絡をお待ちください。')
            return
        }
    }
}

export const unsubscribeMembership = () => {
    return async (dispatch: any, getState: any) => {
        const ret = window.confirm(
            '退会する場合、このアカウントで再度ログインすることはできません。このまま退会手続きを進めてよろしいですか？'
        )

        if (!ret) {
            return false
        } else {
            dispatch(showLoadingAction('退会処理中...'))

            // Get user state
            const user = getState().users
            const email = user.email
            const role = user.role
            const username = user.username
            const uid = user.uid

            try {
                const subscriptionId = await getStripeId(uid, 'membership_fee')

                if (!subscriptionId) {
                    dispatch(hideLoadingAction())
                    alert(
                        '退会手続きに失敗しました。通信環境をお確かめの上、もう1度お試しいただくか、お手数ですがお問い合わせフォームよりご連絡ください。'
                    )
                    return false
                }

                const unsubscribePlan = await fetch(BASE_URL + '/v1/unsubscription', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        subscriptionId: subscriptionId,
                        userId: uid,
                    }),
                })

                const unsubscribeResponse = await unsubscribePlan.json()
                const unsubscriptionData = JSON.parse(unsubscribeResponse.body)
                const unsubscriptionHasSucceeded = unsubscriptionData && unsubscriptionData.status === 'canceled'

                // Cancel the authorized payment if the user unsubscribe within 7 days after registration.
                const supportSubscriptionId = await getStripeId(uid, 'support_fee')
                let unsubscriptionSupportHasSucceeded = true
                if (supportSubscriptionId) {
                    const unsubscribeSupport = await fetch(BASE_URL + '/v1/unsubscription', {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify({
                            subscriptionId: supportSubscriptionId,
                            userId: uid,
                        }),
                    })

                    const unsubscribeSupportResponse = await unsubscribeSupport.json()
                    const unsubscriptionSupportData = JSON.parse(unsubscribeSupportResponse.body)
                    unsubscriptionSupportHasSucceeded =
                        unsubscriptionSupportData && unsubscriptionSupportData.status === 'canceled'
                }

                if (unsubscriptionHasSucceeded && unsubscriptionSupportHasSucceeded) {
                    const sendUnsubscriptionMail = functions.httpsCallable('sendUnsubscriptionMail')
                    await sendUnsubscriptionMail({
                        email: email,
                        userId: uid,
                        username: username,
                    })

                    const isDisabled = await disableUser(uid)
                    if (!isDisabled) {
                        dispatch(hideLoadingAction())
                        return false
                    }

                    if (process.env.NODE_ENV === 'production') {
                        await sendSlackNotification(email, 'ユーザーが退会しました😭', username)
                    }

                    await auth.signOut()
                    dispatch(hideLoadingAction())
                    return dispatch(pushTransition('/unsubscribe'))
                } else {
                    dispatch(hideLoadingAction())
                    alert(
                        '退会手続きに失敗しました。通信環境をお確かめの上、もう1度お試しいただくか、お手数ですがお問い合わせフォームよりご連絡ください。'
                    )
                    return false
                }
            } catch (e) {
                console.error(e)
                dispatch(hideLoadingAction())
                alert(
                    '退会手続きに失敗しました。通信環境をお確かめの上、もう1度お試しいただくか、お手数ですがお問い合わせフォームよりご連絡ください。'
                )
                return false
            }
        }
    }
}

export const sendSlackNotification = (email: string, title: string, username: string) => {
    const WEBHOOK_URL = 'https://hooks.slack.com/services/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'
    const payload = {
        text: title + '\nユーザー名: ' + username + '\nEmail: ' + email,
    }

    return fetch(WEBHOOK_URL, {
        method: 'POST',
        body: JSON.stringify(payload),
    })
}

/**
 * Unsubscribe membership plan on Stripe to query the API
 * @param subscriptionId
 * @param uid
 */
export const unsubscribeStripeMembership = async (subscriptionId: string, uid: string): Promise<boolean> => {
    const unsubscribePlan = await fetch(BASE_URL + '/v1/unsubscription', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            subscriptionId: subscriptionId,
            userId: uid,
        }),
    })

    const unsubscribeResponse = await unsubscribePlan.json()
    const unsubscriptionData = JSON.parse(unsubscribeResponse.body)
    return unsubscriptionData && unsubscriptionData.status === 'canceled'
}
