import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { TableHead } from '@material-ui/core'

const useStyles = makeStyles({
    header: {
        fontWeight: 600,
    },
    table: {
        minWidth: 225,
    },
})

interface SimpleTableProps {
    rows: { label: string; value: string }[]
}

const SimpleTable = (props: SimpleTableProps) => {
    const classes = useStyles()
    const rows = props.rows

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                    <TableRow key={rows[0].label}>
                        <TableCell className={classes.header} component='th' scope='row'>
                            {rows[0].label}
                        </TableCell>
                        <TableCell className={classes.header} component='th'>
                            {rows[0].value}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(
                        (row, index) =>
                            index > 0 && (
                                <TableRow key={row.label}>
                                    <TableCell component='th' scope='row'>
                                        {row.label}
                                    </TableCell>
                                    <TableCell align='right'>{row.value}</TableCell>
                                </TableRow>
                            )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SimpleTable
