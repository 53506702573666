import { ChapterItems } from './types'

export const FETCH_CHAPTERS = 'FETCH_CHAPTERS'
export const fetchChaptersAction = (chapters: ChapterItems) => {
    return {
        type: 'FETCH_CHAPTERS',
        payload: chapters,
    }
}

export const INIT_CHAPTERS = 'INIT_CHAPTERS'
export const initChaptersAction = () => {
    return {
        type: 'INIT_CHAPTERS',
        payload: null,
    }
}
