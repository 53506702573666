import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { createStyles, Checkbox } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getDoneTests, getReadCurriculum, getUserId } from 'reducks/users/selectors'
import { State } from 'index'
import { db } from 'firebase/index'

const useStyles = makeStyles(() =>
    createStyles({
        init: {
            background: 'none',
            border: 'none',
            outline: 'none',
        },
    })
)

const hasDone = (id: string, doneList: { [s: string]: boolean }[]) => {
    let flag: boolean = false

    if (!doneList) {
        return flag
    } else {
        Object.keys(doneList).map((listItemId: string) => {
            // @ts-ignore
            if (listItemId === id && doneList[id]) {
                flag = true
            }
        })
        return flag
    }
}

const toggleHasDone = (id: string, userId: string, isChecked: boolean, type: string) => {
    const updateData =
        type === 'curriculum' ? { read_curriculum: { [id]: !isChecked } } : { done_tests: { [id]: !isChecked } }

    return db.collection('users').doc(userId).set(updateData, { merge: true })
}

interface ButtonProps {
    id: string
    type: string
}

const DoneCheckButton = (props: ButtonProps) => {
    const classes = useStyles()
    const selector = useSelector((state: State) => state)
    const userId = getUserId(selector)
    const doneList = props.type === 'curriculum' ? getReadCurriculum(selector) : getDoneTests(selector)
    let doneState = hasDone(props.id, doneList)
    const button = classes.init

    return (
        <button className={button} onClick={() => toggleHasDone(props.id, userId, doneState, props.type)}>
            <Checkbox checked={doneState} color='primary' />
        </button>
    )
}

export default DoneCheckButton
