import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { createStyles, Theme } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getDoneTests, getReadCurriculum, getUserId, getCourseId } from 'reducks/users/selectors'
import { State } from 'index'
import { db } from 'firebase/index'
import { openTextModalAction } from 'reducks/modal/action'
import {
    getDoneTestsAmount,
    getDoneTestsRate,
    getReadCurriculumAmount,
    getReadCurriculumRate,
} from 'reducks/users/selectors'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primary: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            fontSize: 16,
            height: 48,
            width: 256,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        grey: {
            backgroundColor: theme.palette.grey['700'],
            color: '#fff',
            fontSize: 16,
            height: 48,
            width: 256,
            '&:hover': {
                backgroundColor: theme.palette.grey['500'],
            },
        },
    })
)

const hasDone = (id: string, doneList: { [s: string]: boolean }[]) => {
    let flag: boolean = false

    if (!doneList) {
        return flag
    } else {
        Object.keys(doneList).map((listItemId: string) => {
            // @ts-ignore
            if (listItemId === id && doneList[id]) {
                flag = true
            }
        })
        return flag
    }
}

const toggleHasDone = (id: string, userId: string, isChecked: boolean, type: string) => {
    const updateData =
        type === 'curriculum' ? { read_curriculum: { [id]: !isChecked } } : { done_tests: { [id]: !isChecked } }

    return db.collection('users').doc(userId).set(updateData, { merge: true })
}

interface ButtonProps {
    id: string
    type: string
}

const DoneButton = (props: ButtonProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const userId = getUserId(selector)
    const courseId = getCourseId(selector)
    const doneList = props.type === 'curriculum' ? getReadCurriculum(selector) : getDoneTests(selector)
    let doneState = hasDone(props.id, doneList)

    const button = doneState ? classes.grey : classes.primary
    const label = doneState ? '受講済' : '受講中'

    const doneTestsAmount = getDoneTestsAmount(selector)
    const doneTestsRate = getDoneTestsRate(selector)
    const readCurriculumAmount = getReadCurriculumAmount(selector)
    const readCurriculumRate = getReadCurriculumRate(selector)

    const [doneStateBoolean, setDoneStateBoolean] = useState<boolean>(false)

    // モーダル中身
    const body =
        courseId === 'swift'
            ? `Swiftカリキュラム達成数 ${readCurriculumAmount.swift}(${readCurriculumRate.swift}%)<br />Swift課題達成数 ${doneTestsAmount.swift}(${doneTestsRate.swift}%)`
            : `LP制作カリキュラム達成数 ${readCurriculumAmount.website}(${readCurriculumRate.website}%)<br />LP制作課題達成数 ${doneTestsAmount.website}(${doneTestsRate.website}%)`

    const title = courseId === 'swift' ? 'Swiftコース達成数・達成率' : 'LP制作コース達成数・達成率'
    let func = () => {
        console.log('モーダル')
    }

    useEffect(() => {
        // 以前の達成数がfalse
        setDoneStateBoolean(false)
        if (!doneState) {
            // 受講済になり値が更新されたらtrue
            setDoneStateBoolean(true)
        }
        if (doneStateBoolean) {
            dispatch(openTextModalAction(body, func, title))
        }
    }, [doneState])

    return (
        <Button
            className={button}
            variant='contained'
            onClick={() => {
                toggleHasDone(props.id, userId, doneState, props.type)
            }}
        >
            {label}
        </Button>
    )
}

export default DoneButton
