import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import HTMLReactParser from 'html-react-parser'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getModalBody, getModalOpen, getModalTitle } from 'reducks/modal/selectors'
import { closeModalAction } from 'reducks/modal/action'
import ShareButton from './ShareButton'

interface ModalShareProps {
    text: string
}

const useStyles = makeStyles({
    root: {
        margin: '0 auto',
    },
    buttons: {
        margin: '0 auto',
        textAlign: 'center',
        width: 300,
    },
})

const ModalShare = (props: ModalShareProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const selector = useSelector((state: State) => state)
    const rawBody = getModalBody(selector)
    const open = getModalOpen(selector)
    const title = getModalTitle(selector)

    const body = HTMLReactParser(rawBody)

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={() => dispatch(closeModalAction())}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{body}</DialogContentText>
            </DialogContent>
            <div className={classes.buttons}>
                <ShareButton text={props.text} type={'twitter'} />
                <ShareButton text={props.text} type={'facebook'} />
                <ShareButton text={props.text} type={'line'} />
            </div>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(closeModalAction())
                    }}
                    color='primary'
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalShare
