import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { db, FirebaseTimestamp, functions, storage } from 'firebase/index'
import { getUserId, getUsername } from 'reducks/users/selectors'
import { getCommenterIds, getCurriculumTitle } from 'reducks/curriculum/selectors'
import { ImagePreview } from '../UIkit'
import { IconButton } from '@material-ui/core'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import { makeStyles } from '@material-ui/styles'
import { hideLoadingAction, showLoadingAction } from 'reducks/loading/action'
import { CommentInput } from './index'
import { returnCodeToBr } from '../../functions/commonFunc'
import { getCourseId } from 'reducks/users/selectors'

const useStyles = makeStyles({
    icon: {
        marginRight: 8,
        height: 48,
        width: 48,
    },
})

interface InputProps {
    curriculumId: string
    label: string
    rows: number
}

const CommentPost = (props: InputProps) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const selector = useSelector((state: State) => state)
    const uid = getUserId(selector)
    const username = getUsername(selector)
    const title = getCurriculumTitle(selector)
    const commenterIds = getCommenterIds(selector)
    const courseId = getCourseId(selector)

    const [comment, setComment] = useState('')
    const [images, setImages] = useState<Array<{ id: string; path: string }>>([])

    const curriculumRef = db.collection('curriculum')

    const inputComment = useCallback(
        (value: string) => {
            setComment(value)
        },
        [setComment]
    )

    // Function to add comment into the database
    const addComment = async (
        commenterId: string,
        curriculumId: string,
        value: string,
        attachedImages: { id: string; path: string }[]
    ) => {
        // Get mentioned users' ID
        const mentionedIds = value.match(/]\(.{28}\)/g)

        const formatMentionText = (text: string): string => {
            if (text === '') {
                return text
            } else {
                const mentionedNames: string[] | null = text.match(/@\[.+]\(.+\)/g)

                if (!mentionedNames) {
                    return text
                }

                const mentionedName = mentionedNames[0]
                const name = mentionedName.replace(/@\[/g, '@').replace(/]\(.{28}\)/g, '')
                text = text.replace(mentionedName, name)

                return text
            }
        }

        if (mentionedIds !== null) {
            const notifyByMail = functions.httpsCallable('sendMailToNotifyMention')
            for (let mentionedId of mentionedIds) {
                mentionedId = mentionedId.slice(2, 30)
                commenterIds.add(mentionedId)
                const formattedValue = formatMentionText(value)
                const comment = returnCodeToBr(formattedValue)
                await notifyByMail({
                    id: mentionedId,
                    comment: comment,
                    curriculumId: curriculumId,
                })
            }
        }

        const commentRef = curriculumRef.doc(curriculumId).collection('comments').doc()
        const batch = db.batch()
        const timestamp = FirebaseTimestamp.now()

        try {
            batch.set(commentRef, {
                created_at: timestamp,
                comment: value,
                commenter_id: commenterId,
                images: attachedImages,
                updated_at: timestamp,
            })
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(e.message)
            } else {
                // eがErrorオブジェクトでない場合は、Stringを使って文字列化
                throw new Error(String(e))
            }
        }

        const notificationsRef = db.collection('notifications').doc()
        const content = username + 'さんが「' + title + '」のカリキュラムにコメントしました。'

        try {
            batch.set(notificationsRef, {
                content: content,
                created_at: timestamp,
                link_id: curriculumId,
                link_path: courseId + '/curriculum',
                notification_id: notificationsRef.id,
                receiver_ids: Array.from(commenterIds),
                sender_id: commenterId,
                type: 'comment',
                title: '',
                updated_at: timestamp,
            })
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(e.message)
            } else {
                // eがErrorオブジェクトでない場合は、Stringを使って文字列化
                throw new Error(String(e))
            }
        }

        batch
            .commit()
            .then(() => {
                // Initialize local state
                inputComment('')
                setImages([])

                if (process.env.NODE_ENV === 'production') {
                    // Send notification to slack channel (#pg-learning-notifications)
                    const webhookUrl = 'https://hooks.slack.com/services/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'
                    const payload = {
                        text:
                            '以下のカリキュラムにコメントがあります。\n' +
                            'https://pg-learning.net/' +
                            courseId +
                            '/curriculum/' +
                            curriculumId +
                            '\n' +
                            'ユーザー名: ' +
                            username +
                            '\n' +
                            'コメント内容:\n ' +
                            value,
                    }

                    return fetch(webhookUrl, {
                        method: 'POST',
                        body: JSON.stringify(payload),
                    })
                } else {
                    return null
                }
            })
            .catch((error) => {
                throw new Error(error)
            })
    }

    const deleteImage = useCallback(
        async (id: string) => {
            const ret = window.confirm('この画像を削除しますか？')
            if (!ret) {
                return false
            } else {
                const newImages = images.filter((image) => image.id !== id)
                setImages(newImages)
                return storage.ref('images').child(id).delete()
            }
        },
        [images]
    )

    const uploadImage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(showLoadingAction('uploading...'))
            const file = event.target.files
            // @ts-ignore
            let blob = new Blob(file, { type: 'image/jpeg' })

            // Generate random 16 digits strings
            const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            const N = 16
            const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N)))
                .map((n) => S[n % S.length])
                .join('')

            const uploadRef = storage.ref('images').child(fileName)
            const uploadTask = uploadRef.put(blob)

            uploadTask
                .then(() => {
                    // Handle successful uploads on complete
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        const newImage = { id: fileName, path: downloadURL }
                        setImages((prevState) => [...prevState, newImage])
                        dispatch(hideLoadingAction())
                    })
                })
                .catch(() => {
                    dispatch(hideLoadingAction())
                })
        },
        [setImages]
    )

    return (
        <div className='p-input__text'>
            <label className='u-text-left'>{props.label}</label>
            <CommentInput comment={comment} inputComment={inputComment} />
            <div className='p-grid__list-images'>
                {images.length > 0 &&
                    images.map((image) => (
                        <ImagePreview delete={deleteImage} id={image.id} path={image.path} key={image.id} />
                    ))}
            </div>
            <div className='u-text-right'>
                <IconButton className={classes.icon}>
                    <label>
                        <AddPhotoAlternateIcon />
                        <input className='u-display-none' type='file' id='image' onChange={(e) => uploadImage(e)} />
                    </label>
                </IconButton>
                <button
                    className='p-btn-small p-btn-primary-light'
                    onClick={() => {
                        addComment(uid, props.curriculumId, comment, images)
                    }}
                >
                    コメントする
                </button>
            </div>
        </div>
    )
}

export default CommentPost
