import { createSelector } from 'reselect'
import State from 'reducks/store/types'

const supportsSelector = (state: State) => state.supports

export const getCalendars = createSelector([supportsSelector], (state) => state.calendars)

export const getDayOfWeek = createSelector([supportsSelector], (state) => state.dayOfWeek)

export const getMentor = createSelector([supportsSelector], (state) => state.mentor)

export const getMentors = createSelector([supportsSelector], (state) => state.mentors)

export const getSupportId = createSelector([supportsSelector], (state) => state.id)
