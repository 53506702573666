import { db } from '../../firebase'
import { CalendarsType, CalendarType } from './types'
import { fetchCalendarsAction, fetchMentorsAction, selectSupportCalendarAction } from './action'
import { pushTransition } from '../router/operation'

export const fetchCalendars = (mentorId: string) => {
    return async (dispatch: any) => {
        db.collection('supports')
            .orderBy('created_at', 'asc')
            .where('uid', '==', mentorId)
            .get()
            .then((snapshots) => {
                let calendars: CalendarsType = [
                    { dayOfWeek: '月', classes: [] },
                    { dayOfWeek: '火', classes: [] },
                    { dayOfWeek: '水', classes: [] },
                    { dayOfWeek: '木', classes: [] },
                    { dayOfWeek: '金', classes: [] },
                    { dayOfWeek: '土', classes: [] },
                    { dayOfWeek: '日', classes: [] },
                ]

                snapshots.forEach((snapshot) => {
                    const data = snapshot.data()
                    const index = calendars.findIndex(
                        (calendar: CalendarType) => calendar.dayOfWeek === data.day_of_week
                    )
                    calendars[index].classes.push({
                        label: data.label,
                        id: data.id,
                        isSubscribed: data.is_subscribed,
                    })
                })
                dispatch(fetchCalendarsAction(calendars))
            })
    }
}

export const fetchMentors = () => {
    return async (dispatch: any) => {
        const mentors: { id: string; name: string }[] = []
        await db
            .collection('users')
            .where('is_mentor', '==', true)
            .get()
            .then((snapshots) => {
                snapshots.forEach((snapshot) => {
                    const data = snapshot.data()
                    mentors.push({ id: snapshot.id, name: data.username })
                })
            })

        dispatch(fetchMentorsAction(mentors))
    }
}

export const selectSupportCalendar = (dayOfWeek: string, id: string, mentor: string) => {
    return async (dispatch: any) => {
        dispatch(pushTransition('/support/checkout'))
        dispatch(selectSupportCalendarAction(dayOfWeek, id, mentor))
    }
}
