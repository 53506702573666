import React from 'react'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import IconButton from '@material-ui/core/IconButton'
import TableContainer from '@material-ui/core/TableContainer'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/styles'
import { SupportsType, SupportType } from 'reducks/supports/types'
import { TableHead } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { pushTransition } from 'reducks/router/operation'

const useStyles = makeStyles({
    root: {
        margin: '0 auto',
        maxWidth: 768,
        width: '100%',
        '& th': {
            fontSize: 16,
            fontWeight: 600,
        },
    },
    iconCell: {
        padding: 0,
        height: 48,
        width: 48,
    },
})

interface SupportTableProps {
    delete: (id: string, isSubscribed: boolean) => void
    edit: (support: SupportType) => void
    supports: SupportsType
}

const SupportEditTable = (props: SupportTableProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    return (
        <TableContainer>
            <Table aria-label='simple table' className={classes.root}>
                <TableHead>
                    <TableRow>
                        <TableCell>曜日</TableCell>
                        <TableCell>時間帯</TableCell>
                        <TableCell>受講状況</TableCell>
                        <TableCell>受講者名</TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.supports.length > 0 &&
                        props.supports.map((support: SupportType) => (
                            <TableRow key={support.id}>
                                <TableCell component='th' scope='row'>
                                    {support.day_of_week}
                                </TableCell>
                                <TableCell>{support.label}</TableCell>
                                <TableCell>{support.is_subscribed ? '予約済' : '未予約'}</TableCell>
                                <TableCell>
                                    {support.is_subscribed && (
                                        <button
                                            className='p-btn-small p-btn-secondary'
                                            onClick={() =>
                                                dispatch(pushTransition('/user/profile/' + support.subscriber_id))
                                            }
                                        >
                                            {support.subscriber_name}
                                        </button>
                                    )}
                                </TableCell>
                                <TableCell className={classes.iconCell}>
                                    <IconButton className={classes.iconCell} onClick={() => props.edit(support)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell className={classes.iconCell}>
                                    <IconButton
                                        className={classes.iconCell}
                                        onClick={() => props.delete(support.id, support.is_subscribed)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SupportEditTable
