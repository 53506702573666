import React, { useCallback, useEffect, useState } from 'react'
import { GreyButton } from '../components/UIkit'
import { pushTransition } from '../reducks/router/operation'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../index'
import { db } from '../firebase'
import { datetimeToString, returnCodeToBr } from '../functions/commonFunc'
import ReactHtmlParser from 'html-react-parser'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const Notification = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const id = selector.router.location.pathname.split('/notification/')[1]
    const pageTitle = getPageTitle(selector)

    const [content, setContent] = useState<string>(''),
        [eyecatch, setEyecatch] = useState<string>(''),
        [datetime, setDatetime] = useState<string>(''),
        [title, setTitle] = useState<string>('')

    const pushTransitionTopPage = useCallback(() => {
        dispatch(pushTransition('/'))
    }, [])

    useEffect(() => {
        if (id) {
            db.collection('notifications')
                .doc(id)
                .get()
                .then((doc) => {
                    const data = doc.data()
                    if (data) {
                        // @ts-ignore
                        setContent(returnCodeToBr(data.content))
                        setDatetime(datetimeToString(data.updated_at.toDate()))
                        setEyecatch(data.eyecatch === '' || !data.eyecatch ? '' : data.eyecatch)
                        setTitle(data.title)
                    }
                })
        }
    }, [id])

    useEffect(() => {
        const title = '運営からのお知らせ'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <div className='module-spacer--extra-small' />
                <p className='u-text-right'>{datetime}</p>
                <div className='module-spacer--extra-small' />
                <h3 className='u-text-bolder u-text-larger'>{title}</h3>
                {eyecatch !== '' && (
                    <>
                        <div className='module-spacer--extra-small' />
                        <img src={eyecatch} alt='アイキャッチ' />
                    </>
                )}
                <div className='module-spacer--extra-small' />
                <p>{ReactHtmlParser(content)}</p>
                <div className='module-spacer--small' />
                <section className='center'>
                    <GreyButton label={'トップ画面に戻る'} onClick={pushTransitionTopPage} />
                </section>
            </div>
        </section>
    )
}

export default Notification
