import React, { useEffect, useState } from 'react'
import { PageTitle } from 'components/UIkit'
import { useSelector, useDispatch } from 'react-redux'
import { State } from 'index'
import { getUserRole } from 'reducks/users/selectors'
import { pushTransition } from 'reducks/router/operation'
import { Notification } from 'reducks/notifications/types'
import { db } from 'firebase/index'
import NotificationListItem from '../components/Notifications/NotificationListItem'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const NotificationsList = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)

    const userRole = getUserRole(selector)
    const isManager = userRole === 'teacher' || userRole === 'administrator'

    const [notifications, setNotifications] = useState<Array<any>>([])

    useEffect(() => {
        let list: Array<any> = []
        db.collection('notifications')
            .where('type', '==', 'official')
            .orderBy('updated_at', 'desc')
            .limit(20)
            .get()
            .then((snapshots) => {
                snapshots.forEach((doc) => {
                    const notification = doc.data()
                    list.push(notification)
                })

                setNotifications(list)
            })
    }, [])

    useEffect(() => {
        const title = 'お知らせ管理'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section-wrapin'>
                <div className='u-display-none-over-md'>
                    <PageTitle title={pageTitle} />
                </div>
                <div className='module-spacer--small' />
                {isManager && (
                    <div className='u-text-right' id='create-button'>
                        <a
                            className='p-btn-small p-btn-primary'
                            role='button'
                            onClick={() => dispatch(pushTransition('/pg-admin/notification/edit'))}
                        >
                            新規作成
                        </a>
                    </div>
                )}
                <div className='module-spacer--medium' />
                <div>
                    {notifications.length > 0 &&
                        notifications.map((notification: Notification) => {
                            return (
                                <NotificationListItem notification={notification} key={notification.notification_id} />
                            )
                        })}
                </div>
                <div className='module-spacer--medium' />
            </div>
        </section>
    )
}

export default NotificationsList
