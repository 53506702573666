import React, { useState, useEffect, useCallback } from 'react'
import { PageTitle, SelectBox, TextInput } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { fetchChapters } from 'reducks/chapters/operations'
import { ChapterItem } from 'reducks/chapters/types'
import { getChapterItems } from 'reducks/chapters/selectors'
import { EyecatchArea } from 'components/Curriculum'
import { db, FirebaseTimestamp } from '../firebase'
import { isValidRequiredInput } from 'functions/commonFunc'
import NoImage from 'assets/img/src/no_image.png'
import { State } from 'index'
import { pushTransition } from 'reducks/router/operation'
import { getUsername } from 'reducks/users/selectors'
import { saveContent, saveTest } from 'reducks/tests/operations'
import { AnswerEditor, Hint2Editor, HintEditor, QuestionEditor } from 'components/Tests'
import { getAnswer, getHint, getHint2, getQuestion } from 'reducks/tests/selectors'
import { getCourses } from 'reducks/courses/selectors'
import { getCourseId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'
import { fetchCourses } from '../reducks/courses/operations'

type Options = { id: string; name: string }[]

const TestEdit = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const chapters = getChapterItems(selector)
    const course = getCourseId(selector)
    const pageTitle = getPageTitle(selector)
    const courseOptions: Options = getCourses(selector)

    // Get test ID from this URL (If it is to create a new test, ID is blank string.)
    let id = selector.router.location.pathname.split('/pg-admin/test/edit')[1]
    id = id === '' ? '' : id.split('/')[1]

    // Get chapters' name
    const chaptersForTests = chapters.filter((chapter: ChapterItem) => chapter.order.length === 6)
    const chaptersValue: Options = chaptersForTests.map((chapter: ChapterItem) => {
        return { id: chapter.chapter_id, name: chapter.chapter_name }
    })

    const publishStateOptions: Options = [
        { id: 'drafted', name: '下書き' },
        { id: 'published', name: '公開' },
        { id: 'freePublished', name: '無料公開' },
    ]

    const editorTypes: Options = [
        { id: 'question', name: '問題文' },
        { id: 'hint', name: 'ヒント1' },
        { id: 'hint2', name: 'ヒント2' },
        { id: 'answer', name: '解答文' },
    ]

    // Declare local states
    const [chapterId, selectChapter] = useState<string>(''),
        [courseId, selectCourseId] = useState<string>(''),
        [editorType, setEditorType] = useState<string>(''),
        [eyecatchPath, setEyecatchPath] = useState<string>(NoImage),
        [publishedState, selectPublishedState] = useState<string>('drafted'),
        [order, setOrder] = useState<string>(''),
        [title, setTitle] = useState<string>('')

    const inputOrder = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setOrder(event.target.value)
        },
        [setOrder]
    )

    const inputTitle = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value)
        },
        [setTitle]
    )

    useEffect(() => {
        if (id !== '') {
            dispatch(setTitleAction('【テスト】課題の編集'))
            db.collection('tests')
                .doc(id)
                .get()
                .then((snapshot) => {
                    const value = snapshot.data()
                    if (value) {
                        dispatch(saveContent(value.answer, 'answer'))
                        dispatch(saveContent(value.hint, 'hint'))
                        dispatch(saveContent(value.hint2, 'hint2'))
                        dispatch(saveContent(value.question, 'question'))
                        selectChapter(value.chapter_id)
                        selectCourseId(value.course_id ? value.course_id : '')
                        setEyecatchPath(value.eyecatch_path !== '' ? value.eyecatch_path : NoImage)
                        selectPublishedState(
                            value.isPublished ? (value.is_free ? 'freePublished' : 'published') : 'drafted'
                        )
                        setOrder(value.order)
                        setTitle(value.title)
                    }
                })
        } else {
            dispatch(setTitleAction('【テスト】課題の編集'))
        }

        if (courseOptions.length === 0) {
            dispatch(fetchCourses())
        }
    }, [])

    useEffect(() => {
        if (chapters.length === 0) {
            dispatch(fetchChapters(course))
        }
    }, [chapters.length])

    const getInputData = () => {
        const imageThumbDom = document.getElementById('image-thumb')
        const imageThumb = imageThumbDom ? imageThumbDom.getAttribute('src') : ''
        const data = {
            answer: getAnswer(selector),
            author_id: getUsername(selector),
            chapter_id: chapterId,
            course_id: courseId,
            eyecatch_path: imageThumb !== NoImage ? imageThumb : '',
            hint: getHint(selector),
            hint2: getHint2(selector),
            is_free: publishedState === 'freePublished',
            isPublished: publishedState !== 'drafted',
            order: order,
            question: getQuestion(selector),
            title: title,
            updated_at: FirebaseTimestamp.now(),
        }

        // Prevent from updating unexpectedly if this function is called on loading the existing content
        return isValidRequiredInput(data.title) ? data : false
    }

    const Editor = () => {
        if (editorType === 'answer') {
            return <AnswerEditor />
        } else if (editorType === 'question') {
            return <QuestionEditor />
        } else if (editorType === 'hint') {
            return <HintEditor />
        } else if (editorType === 'hint2') {
            return <Hint2Editor />
        } else {
            return <></>
        }
    }

    useEffect(() => {}, [])

    return (
        <section>
            <div className='c-section-wrapin-wide'>
                <div className='u-display-none-over-md'>
                    <PageTitle title={pageTitle} />
                </div>
                {/* {(id === "") ? (
                    <PageTitle title={"課題の編集"} />
                ) : (
                    <PageTitle title={"課題の編集"} />
                )} */}
                <div className='module-spacer--medium' />
                <div className='p-grid__curriculum__editor'>
                    <article>
                        <TextInput
                            fullWidth={true}
                            label={'タイトル'}
                            multiline={false}
                            onChange={inputTitle}
                            required={true}
                            rows={1}
                            type={'text'}
                            value={title}
                        />
                        <SelectBox
                            label={'編集項目'}
                            required={true}
                            options={editorTypes}
                            select={setEditorType}
                            value={editorType}
                        />
                        <Editor />
                    </article>

                    <aside>
                        <div className='content__box'>
                            <TextInput
                                fullWidth={true}
                                label={'課題No.(6桁の整数)'}
                                multiline={false}
                                maxLength={6}
                                onChange={inputOrder}
                                required={false}
                                rows={1}
                                type={'tel'}
                                value={order}
                            />
                            <SelectBox
                                label={'公開状況'}
                                required={true}
                                options={publishStateOptions}
                                select={selectPublishedState}
                                value={publishedState}
                            />
                            <SelectBox
                                label={'チャプター'}
                                required={false}
                                options={chaptersValue}
                                select={selectChapter}
                                value={chapterId}
                            />
                            <SelectBox
                                label={'コース'}
                                required={false}
                                options={courseOptions}
                                select={selectCourseId}
                                value={courseId}
                            />
                            <div>
                                <button
                                    className='p-btn-small p-btn-secondary'
                                    onClick={() => {
                                        const data = getInputData()
                                        if (!data) {
                                            alert('必須入力欄が空白です。')
                                            return false
                                        } else {
                                            return dispatch(saveTest(id, data))
                                        }
                                    }}
                                >
                                    保存
                                </button>
                                <button
                                    className='p-btn-small p-btn-gray'
                                    onClick={() => dispatch(pushTransition(`/${course}/tests`))}
                                >
                                    戻る
                                </button>
                            </div>
                        </div>
                        <div className='module-spacer--medium' />
                        <div className='content__box'>
                            <EyecatchArea imagePath={eyecatchPath} />
                        </div>
                    </aside>
                </div>
            </div>
        </section>
    )
}

export default TestEdit
