import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { createStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
    createStyles({
        box: {
            margin: 8,
        },
        row: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
            margin: '16px 0',
        },
    })
)

const AdArea = () => {
    const classes = useStyles()

    return (
        <div className='center'>
            <p className='u-text-bolder u-text-small'>スポンサーリンク</p>
            <div className={classes.row}>
                <div className={classes.box}>
                    <a href='https://px.a8.net/svt/ejp?a8mat=3BICLD+F3KS36+3GWO+6C9LD' rel='nofollow' target='_blank'>
                        <img
                            width='300'
                            height='250'
                            alt=''
                            src='https://www24.a8.net/svt/bgt?aid=200730433913&wid=001&eno=01&mid=s00000016188001065000&mc=1'
                        />
                    </a>
                    <img width='1' height='1' src='https://www18.a8.net/0.gif?a8mat=3BICLD+F3KS36+3GWO+6C9LD' alt='' />
                </div>
                <div className={classes.box}>
                    <a href='https://px.a8.net/svt/ejp?a8mat=3BICLD+F467OY+3UES+BXYE9' rel='nofollow' target='_blank'>
                        <img
                            width='300'
                            height='250'
                            alt=''
                            src='https://www26.a8.net/svt/bgt?aid=200730433914&wid=001&eno=01&mid=s00000017938002006000&mc=1'
                        />
                    </a>
                    <img width='1' height='1' src='https://www10.a8.net/0.gif?a8mat=3BICLD+F467OY+3UES+BXYE9' alt='' />
                </div>
                <div className={classes.box}>
                    <a href='https://px.a8.net/svt/ejp?a8mat=3BICLD+F2ZCHE+3GOM+6NETT' rel='nofollow' target='_blank'>
                        <img
                            width='300'
                            height='250'
                            alt=''
                            src='https://www24.a8.net/svt/bgt?aid=200730433912&wid=001&eno=01&mid=s00000016159001117000&mc=1'
                        />
                    </a>
                    <img width='1' height='1' src='https://www13.a8.net/0.gif?a8mat=3BICLD+F2ZCHE+3GOM+6NETT' alt='' />
                </div>
            </div>
        </div>
    )
}

export default AdArea
