export const CLOSE_MODAL = 'CLOSE_MODAL'
export const closeModalAction = () => {
    return {
        type: 'CLOSE_MODAL',
        payload: {
            body: '',
            func: () => null,
            open: false,
            title: '',
            type: '',
        },
    }
}

export const OPEN_IMAGE_MODAL = 'OPEN_IMAGE_MODAL'
export const openImageModalAction = (path: string) => {
    return {
        type: 'OPEN_IMAGE_MODAL',
        payload: {
            body: path,
            open: true,
            type: 'image',
        },
    }
}

export const OPEN_TEXT_MODAL = 'OPEN_TEXT_MODAL'
export const openTextModalAction = (body: string, func: () => void, title: string) => {
    return {
        type: 'OPEN_TEXT_MODAL',
        payload: {
            body: body,
            func: func,
            open: true,
            title: title,
            type: 'text',
        },
    }
}
