import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { createStyles, Theme } from '@material-ui/core'

interface ButtonProps {
    label: string
    onClick: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            backgroundColor: theme.palette.grey['300'],
            fontSize: 16,
            height: 48,
            marginBottom: 16,
            maxWidth: 256,
            width: '100%',
        },
    })
)

const GreyButton = (props: ButtonProps) => {
    const classes = useStyles()

    return (
        <Button className={classes.button} variant='contained' onClick={() => props.onClick()}>
            {props.label}
        </Button>
    )
}

export default GreyButton
