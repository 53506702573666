import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from './index'
import { getSignedIn, getUserRole } from 'reducks/users/selectors'
import { listenAuthState } from 'reducks/users/operations'
import { Redirect } from 'react-router'
import { getCourseId } from 'reducks/users/selectors'

const Auth: React.StatelessComponent<React.Props<{}>> = ({ children }: { children?: any }) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pathname = selector.router.location.pathname

    const role = getUserRole(selector)
    const isAdmin = role === 'teacher' || role === 'administrator'
    const isSignedIn = getSignedIn(selector)
    const course = getCourseId(selector)

    useEffect(() => {
        // Confirm login session
        if (!isSignedIn) {
            dispatch(listenAuthState())
        }
    }, [])

    const isPageForAdmin = /\/pg-admin*/.test(pathname)

    if (!isSignedIn) {
        return <></>
    } else if (isPageForAdmin && !isAdmin) {
        return <Redirect to={'/' + course} />
    } else {
        return children
    }
}

export default Auth
