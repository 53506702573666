import * as Actions from 'reducks/chapters/action'
import { initialState } from 'reducks/store/initialState'

export const ChaptersReducer = (state = initialState.chapters, action: any) => {
    switch (action.type) {
        case Actions.FETCH_CHAPTERS:
            return [...action.payload]
        case Actions.INIT_CHAPTERS:
            return []
        default:
            return state
    }
}
