import React from 'react'
import { makeStyles } from '@material-ui/styles'

interface ImageContainProps {
    path: string
    height: number
}

const ImageContain = (props: ImageContainProps) => {
    const useStyles = makeStyles({
        root: {
            textAlign: 'center',
        },
        image: {
            objectFit: 'contain',
            height: props.height,
            width: 'auto',
        },
    })

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <img className={classes.image} src={props.path} alt={''} />
        </div>
    )
}

export default ImageContain
