import React from 'react'
import { useDispatch } from 'react-redux'
import { openImageModalAction } from '../../reducks/modal/action'

interface ImagePreviewProps {
    delete?: (id: string) => void
    id: string
    path: string
}

const ImagePreview = (props: ImagePreviewProps) => {
    const dispatch = useDispatch()

    return (
        <>
            {props.delete ? (
                // @ts-ignore --> If this preview image is editable
                <div className='p-media__thumb' onClick={() => props.delete(props.id)}>
                    <img alt='アイキャッチ画像' src={props.path} />
                </div>
            ) : (
                // If this preview image is read only
                <div className='p-media__thumb' onClick={() => dispatch(openImageModalAction(props.path))}>
                    <img alt='アイキャッチ画像' src={props.path} />
                </div>
            )}
        </>
    )
}

export default ImagePreview
