import React, { useState, useEffect } from 'react'
import { InputText, SelectBox } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { createChapter } from 'reducks/chapters/operations'
import { pushTransition } from 'reducks/router/operation'
import { getCourses } from 'reducks/courses/selectors'
import { State } from 'index'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'
import { fetchCourses } from 'reducks/courses/operations'

type Options = { id: string; name: string }[]

const ChapterCreate = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)
    const courseOptions: Options = getCourses(selector)

    const [chapterName, inputChapterName] = useState(''),
        [courseId, selectCourseId] = useState<string>(''),
        [order, inputOrder] = useState('')

    useEffect(() => {
        const title = 'チャプターの新規作成'
        dispatch(setTitleAction(title))

        if (courseOptions.length === 0) {
            dispatch(fetchCourses())
        }
    }, [])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <div className='module-spacer--small' />
                <InputText
                    id={'chapter_name'}
                    label={'【チャプター名】'}
                    onChange={inputChapterName}
                    required={true}
                    type={'text'}
                    value={chapterName}
                />
                <InputText
                    id={'order'}
                    label={'4or6桁の数字'}
                    onChange={inputOrder}
                    required={true}
                    type={'text'}
                    value={order}
                />
                <SelectBox
                    label={'コース'}
                    required={true}
                    options={courseOptions}
                    select={selectCourseId}
                    value={courseId}
                />
                <div className='module-spacer--small' />
                <button className='p-btn-rounded' onClick={() => dispatch(createChapter(chapterName, courseId, order))}>
                    作成する
                </button>
                <button
                    className='p-btn-rounded p-btn-gray'
                    onClick={() => dispatch(pushTransition('/pg-admin/chapters'))}
                >
                    前のページに戻る
                </button>
            </div>
        </section>
    )
}

export default ChapterCreate
