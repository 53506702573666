import React, { useEffect } from 'react'
import { PageTitle } from 'components/UIkit'
import { pushTransition } from '../reducks/router/operation'
import { useDispatch, useSelector } from 'react-redux'
import { SupportManagementTable } from '../components/Supports'
import { State } from 'index'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const SupportManagement = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)

    useEffect(() => {
        const title = 'サポートプランの受講者'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section-wrapin-wide'>
            <div className='u-display-none-over-md'>
                <PageTitle title={pageTitle} />
            </div>
            <div className='module-spacer--small' />
            <div className='u-text-right' id='create-button'>
                <a
                    className='p-btn-small p-btn-primary'
                    role='button'
                    onClick={() => dispatch(pushTransition('/pg-admin/supports/edit'))}
                >
                    サポート枠の編集
                </a>
            </div>
            <div className='module-spacer--medium' />
            <SupportManagementTable />
        </section>
    )
}

export default SupportManagement
