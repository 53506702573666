import React, { useState, useEffect, useCallback } from 'react'
import { PageTitle, SelectBox, TextInput } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { fetchChapters } from 'reducks/chapters/operations'
import { ChapterItem } from 'reducks/chapters/types'
import { getChapterItems } from 'reducks/chapters/selectors'
import * as curriculumOperations from 'reducks/curriculum/operations'
import { CurriculumEditor, EyecatchArea } from 'components/Curriculum'
import { db, FirebaseTimestamp } from '../firebase'
import { isValidRequiredInput } from 'functions/commonFunc'
import NoImage from 'assets/img/src/no_image.png'
import { State } from 'index'
import { pushTransition } from 'reducks/router/operation'
import { getCourses } from 'reducks/courses/selectors'
import { getCourseId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'
import { fetchCourses } from '../reducks/courses/operations'

type Options = { id: string; name: string }[]

const CurriculumEdit = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const chapters = getChapterItems(selector)
    const course = getCourseId(selector)
    const pageTitle = getPageTitle(selector)
    const courseOptions: Options = getCourses(selector)

    // Get URL values
    const location = selector.router.location
    const isBeingEdited = /\/curriculum\/edit*/.test(location.pathname)
    const id = isBeingEdited ? location.pathname.split('/pg-admin/curriculum/edit/')[1] : ''

    // Get chapters' name
    const chaptersForCurriculum = chapters.filter((chapter: ChapterItem) => chapter.order.length === 4)
    const chaptersValue: Options = chaptersForCurriculum.map((chapter: ChapterItem) => {
        return { id: chapter.chapter_id, name: chapter.chapter_name }
    })

    const publishStateOptions: Options = [
        { id: 'drafted', name: '下書き' },
        { id: 'published', name: '公開' },
        { id: 'freePublished', name: '無料公開' },
    ]

    // Declare local states
    const [chapterId, selectChapter] = useState<string>(''),
        [courseId, selectCourseId] = useState<string>(''),
        [content, setContent] = useState<string>('<p>ダミーテキスト</p>'),
        [eyecatchPath, setEyecatchPath] = useState<string>(NoImage),
        [publishedState, selectPublishedState] = useState<string>('drafted'),
        [order, setOrder] = useState<string>(''),
        [title, setTitle] = useState<string>('')

    const inputOrder = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setOrder(event.target.value)
    }, [])

    const inputTitle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value)
    }, [])

    useEffect(() => {
        if (isBeingEdited) {
            dispatch(setTitleAction('カリキュラムの編集'))
            db.collection('curriculum')
                .doc(id)
                .get()
                .then((snapshot) => {
                    const value = snapshot.data()
                    if (value) {
                        selectChapter(value.chapter_id)
                        setContent(value.content)
                        selectCourseId(value.course_id ? value.course_id : '')
                        setEyecatchPath(value.eyecatch_path !== '' ? value.eyecatch_path : NoImage)
                        selectPublishedState(
                            value.isPublished ? (value.is_free ? 'freePublished' : 'published') : 'drafted'
                        )
                        setOrder(value.order)
                        setTitle(value.title)
                    }
                })
        } else {
            dispatch(setTitleAction('カリキュラムの新規作成'))
        }

        if (courseOptions.length === 0) {
            dispatch(fetchCourses())
        }
    }, [])

    useEffect(() => {
        if (chapters.length === 0) {
            dispatch(fetchChapters(course))
        }
    }, [chapters.length])

    const getInputData = () => {
        const imageThumbDom = document.getElementById('image-thumb')
        const imageThumb = imageThumbDom ? imageThumbDom.getAttribute('src') : ''
        const data = {
            chapter_id: chapterId,
            content: content,
            course_id: courseId,
            eyecatch_path: imageThumb !== NoImage ? imageThumb : '',
            is_free: publishedState === 'freePublished',
            isPublished: publishedState !== 'drafted',
            order: order,
            title: title,
            updated_at: FirebaseTimestamp.now(),
        }
        // Prevent from updating unexpectedly if this function is called on loading the existing content
        return isValidRequiredInput(data.content, data.title) ? data : false
    }

    return (
        <section>
            <div className='c-section-wrapin-wide'>
                <div className='module-spacer--medium' />
                <div className='u-display-none-over-md'>
                    <PageTitle title={pageTitle} />
                </div>
                <div className='module-spacer--medium' />
                <div className='p-grid__curriculum__editor'>
                    <article>
                        <TextInput
                            fullWidth={true}
                            label={'タイトル'}
                            multiline={false}
                            onChange={inputTitle}
                            required={true}
                            rows={1}
                            type={'text'}
                            value={title}
                        />
                        <div className='p-grid__curriculum__content'>
                            <label className='u-text-left'>
                                本文
                                <span className='p-label__required'>必須</span>
                            </label>
                            <CurriculumEditor value={content} onChange={setContent} />
                        </div>
                    </article>

                    <aside>
                        <div className='content__box'>
                            <TextInput
                                fullWidth={true}
                                label={'カリキュラムNo.(4桁の整数)'}
                                multiline={false}
                                maxLength={4}
                                onChange={inputOrder}
                                required={false}
                                rows={1}
                                type={'tel'}
                                value={order}
                            />
                            <SelectBox
                                label={'公開状況'}
                                required={true}
                                options={publishStateOptions}
                                select={selectPublishedState}
                                value={publishedState}
                            />
                            <SelectBox
                                label={'チャプター'}
                                required={false}
                                options={chaptersValue}
                                select={selectChapter}
                                value={chapterId}
                            />
                            <SelectBox
                                label={'コース'}
                                required={false}
                                options={courseOptions}
                                select={selectCourseId}
                                value={courseId}
                            />
                            <div>
                                <button
                                    className='p-btn-small p-btn-secondary'
                                    onClick={() => {
                                        const data = getInputData()
                                        if (!data) {
                                            alert('必須入力欄が空白です。')
                                            return false
                                        } else {
                                            return dispatch(curriculumOperations.saveCurriculum(id, data))
                                        }
                                    }}
                                >
                                    保存
                                </button>
                                <button
                                    className='p-btn-small p-btn-gray'
                                    onClick={() => dispatch(pushTransition('/' + course))}
                                >
                                    戻る
                                </button>
                            </div>
                        </div>
                        <div className='module-spacer--medium' />
                        <div className='content__box'>
                            <EyecatchArea imagePath={eyecatchPath} />
                        </div>
                    </aside>
                </div>
            </div>
        </section>
    )
}

export default CurriculumEdit
