import { createSelector } from 'reselect'
import State from 'reducks/store/types'

const curriculumSelector = (state: State) => state.curriculum

export const getCurriculumItems = createSelector([curriculumSelector], (state) => state.list)

export const getCurriculumTitle = createSelector([curriculumSelector], (state) => state.title)

export const getComments = createSelector([curriculumSelector], (state) => state.comments)

export const getCommenterIds = createSelector([curriculumSelector], (state) => state.commenterIds)
