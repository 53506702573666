import { db } from 'firebase/index'
import { fetchCoursesAction } from './action'
import { Courses } from './types'

export const fetchCourses = () => {
    return async (dispatch: any) => {
        return db
            .collection('courses')
            .where('is_published', '==', true)
            .get()
            .then((snapshots) => {
                const courseList: Courses = []
                snapshots.forEach((doc) => {
                    const data = doc.data()
                    courseList.push({
                        description: data.description,
                        icon_path: data.icon_path,
                        id: data.id,
                        name: data.name,
                    })
                })
                dispatch(fetchCoursesAction(courseList))
            })
            .catch((error) => {
                throw new Error(error)
            })
    }
}
