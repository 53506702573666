import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PageTitle, Paginator } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { db } from 'firebase/index'
import { UsersTableRow } from 'components/Users'
import { pushTransition } from 'reducks/router/operation'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'

const useStyles = makeStyles({
    nowrap: {
        whiteSpace: 'nowrap',
    },
})

interface Invitees {
    [uid: string]: string
}

const Users = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)
    const ITEMS_PER_PAGE = 20

    const [usersSnapshots, setUsersSnapshots] = useState<any>(null),
        [currentPage, setCurrentPage] = useState<number>(1),
        [pageLength, setPageLength] = useState<number>(1),
        [invitees, setInvitees] = useState<Invitees>({})

    const fetchInvitees = (): Promise<Invitees> => {
        return db
            .collection('campaigns')
            .doc('invitation')
            .collection('users')
            .get()
            .then((snapshots) => {
                const list: Invitees = {}
                snapshots.forEach((snapshot) => {
                    const data = snapshot.data()
                    list[snapshot.id] = data.inviter_id
                })
                return list
            })
            .catch(() => {
                return {}
            })
    }

    // @ts-ignore TS6133: 'event' is declared but its value is never used.
    const selectPage = useCallback(
        (_event: object, page: number) => {
            setCurrentPage(page)
        },
        [dispatch]
    )

    useEffect(() => {
        ;(async () => {
            const title = 'ユーザー一覧'
            dispatch(setTitleAction(title))

            setInvitees(await fetchInvitees())

            db.collection('users')
                .orderBy('created_at', 'desc')
                .onSnapshot((snapshots) => {
                    const pages = Math.ceil(snapshots.docs.length / ITEMS_PER_PAGE)
                    setPageLength(pages)
                    setUsersSnapshots(snapshots)
                })
        })()
    }, [])

    const usersTableRows = useMemo(() => {
        if (usersSnapshots) {
            const list: Array<any> = []
            const docs = usersSnapshots.docs

            // Calculate index and item length for pagination
            const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
            const currentLength = currentPage * ITEMS_PER_PAGE
            const maxLength = currentLength > docs.length ? docs.length : currentLength

            for (let i = startIndex; i < maxLength; i = (i + 1) | 0) {
                const data = docs[i].data()
                const uid = docs[i].id
                list.push({
                    created_at: data.created_at,
                    email: data.email,
                    inviterId: invitees[uid] ? invitees[uid] : '×',
                    is_subscriber: data.is_subscriber,
                    role: data.role,
                    uid: uid,
                    username: data.username,
                })
            }
            return list
        } else {
            return []
        }
    }, [usersSnapshots, invitees, currentPage])

    return (
        <section className='c-section'>
            <div className='c-section-wrapin-wide'>
                <div className='u-display-none-over-md'>
                    <PageTitle title={pageTitle} />
                </div>
                <div className='module-spacer--medium' />
                <div className='u-text-right' id='create-button'>
                    <a
                        className='p-btn-small p-btn-primary-light'
                        role='button'
                        onClick={() => dispatch(pushTransition('/pg-admin/users/create'))}
                    >
                        新規作成
                    </a>
                </div>
                <div className='module-spacer--medium' />
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.nowrap}>登録日</TableCell>
                                <TableCell className={classes.nowrap}>UID</TableCell>
                                <TableCell className={classes.nowrap}>ユーザー名</TableCell>
                                <TableCell className={classes.nowrap}>メールアドレス</TableCell>
                                <TableCell className={classes.nowrap}>会員ステータス</TableCell>
                                <TableCell className={classes.nowrap}>役割</TableCell>
                                <TableCell className={classes.nowrap}>被招待</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersTableRows.map((value) => (
                                <UsersTableRow
                                    key={value.uid}
                                    created_at={value.created_at}
                                    email={value.email}
                                    inviterId={value.inviterId}
                                    is_subscriber={value.is_subscriber ? value.is_subscriber : false}
                                    role={value.role}
                                    uid={value.uid}
                                    username={value.username}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='module-spacer--medium' />
                <Paginator currentPage={currentPage} pageLength={pageLength} selectPage={selectPage} />
            </div>
        </section>
    )
}

export default Users
