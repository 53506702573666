import * as Actions from 'reducks/curriculum/action'
import { initialState } from 'reducks/store/initialState'

export const CurriculumReducer = (state = initialState.curriculum, action: any) => {
    switch (action.type) {
        case Actions.FETCH_COMMENTS:
            return {
                ...state,
                comments: action.payload.comments,
                commenterIds: action.payload.commenterIds,
            }
        case Actions.FETCH_CURRICULUM_ITEM:
            return {
                ...state,
                ...action.payload,
            }
        case Actions.FETCH_CURRICULUM_ITEMS:
            return {
                ...state,
                list: action.payload,
            }
        case Actions.INIT_CURRICULUM:
            return {
                ...initialState.curriculum,
            }
        case Actions.UPDATE_COMMENT:
            return {
                ...state,
                comments: action.payload,
            }
        default:
            return state
    }
}
