import React, { useState } from 'react'
import { CurriculumEditor } from 'components/Curriculum'
import { useDispatch, useSelector } from 'react-redux'
import { saveContent } from 'reducks/tests/operations'
import { State } from 'index'
import { getQuestion } from 'reducks/tests/selectors'

const QuestionEditor = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const [question, setQuestion] = useState<string>(getQuestion(selector))

    return (
        <div className='p-grid__curriculum__content'>
            <button className='p-btn-small p-btn-secondary' onClick={() => dispatch(saveContent(question, 'question'))}>
                問題文を保存する
            </button>
            <div className='module-spacer--extra-extra-small' />
            <CurriculumEditor value={question} onChange={setQuestion} />
        </div>
    )
}

export default QuestionEditor
