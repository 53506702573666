import React, { useState } from 'react'
import { CurriculumEditor } from 'components/Curriculum'
import { useDispatch, useSelector } from 'react-redux'
import { saveContent } from 'reducks/tests/operations'
import { State } from 'index'
import { getHint2 } from 'reducks/tests/selectors'

const Hint2Editor = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const [hint2, setHint2] = useState<string>(getHint2(selector))

    return (
        <div className='p-grid__curriculum__content'>
            <button className='p-btn-small p-btn-secondary' onClick={() => dispatch(saveContent(hint2, 'hint2'))}>
                ヒント2を保存する
            </button>
            <div className='module-spacer--extra-extra-small' />
            <CurriculumEditor value={hint2} onChange={setHint2} />
        </div>
    )
}

export default Hint2Editor
