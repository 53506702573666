import React, { useCallback, useEffect, useState } from 'react'
import { GreyButton, PrimaryButton, TextInput } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { db, FirebaseTimestamp } from 'firebase/index'
import { State } from '../index'
import { goBackTransition, pushTransition } from 'reducks/router/operation'
import { EyecatchArea } from 'components/Curriculum'
import NoImage from 'assets/img/src/no_image.png'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const NotificationEdit = () => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)

    const [content, setContent] = useState<string>(''),
        [eyecatch, setEyecatch] = useState<string>(NoImage),
        [id, setId] = useState<string>(''),
        [title, setTitle] = useState<string>('')

    const inputContent = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setContent(event.target.value)
        },
        [setContent]
    )

    const inputTitle = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value)
        },
        [setTitle]
    )

    const addOfficialNotification = useCallback(
        (content: string, title: string) => {
            const timestamp = FirebaseTimestamp.now()
            const imageThumbDom = document.getElementById('image-thumb')
            const imageThumb = imageThumbDom ? imageThumbDom.getAttribute('src') : ''

            // Add notification data to trigger of the function to send email
            db.collection('notifications')
                .doc(id)
                .set({
                    content: content,
                    created_at: timestamp,
                    eyecatch: imageThumb !== NoImage ? imageThumb : '',
                    link_id: id,
                    link_path: 'notification',
                    notification_id: id,
                    receiver_ids: ['all'],
                    sender_id: 'official',
                    title: title,
                    type: 'official',
                    updated_at: timestamp,
                })

            dispatch(pushTransition('/notifications'))
        },
        [id]
    )

    useEffect(() => {
        const path = selector.router.location.pathname.split('/notification/edit')[1]
        if (path !== '') {
            const notificationId = path.split('/')[1]
            setId(notificationId)

            // Set existing data
            db.collection('notifications')
                .doc(notificationId)
                .get()
                .then((doc) => {
                    const data = doc.data()
                    if (data) {
                        setContent(data.content)
                        setEyecatch(data.eyecatch)
                        setTitle(data.title)
                    }
                })
        } else {
            const doc = db.collection('notifications').doc()
            setId(doc.id)
        }
    }, [])

    useEffect(() => {
        const title = 'お知らせの作成・編集'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <div className='module-spacer--small' />
                <TextInput
                    fullWidth={true}
                    label={'お知らせのタイトル'}
                    multiline={false}
                    onChange={inputTitle}
                    required={true}
                    rows={1}
                    type={'text'}
                    value={title}
                />
                <TextInput
                    fullWidth={true}
                    label={'お知らせの内容'}
                    multiline={true}
                    onChange={inputContent}
                    required={true}
                    rows={10}
                    type={'text'}
                    value={content}
                />
                <div className='module-spacer--extra-small' />
                <EyecatchArea imagePath={eyecatch} />
                <div className='module-spacer--small' />
                <section className='center'>
                    <PrimaryButton
                        label={'お知らせを送信する'}
                        onClick={() => addOfficialNotification(content, title)}
                    />
                    <GreyButton label={'前の画面に戻る'} onClick={() => dispatch(goBackTransition())} />
                </section>
            </div>
        </section>
    )
}

export default NotificationEdit
