import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    textField: {
        marginBottom: 16,
    },
})

interface TextInputProps {
    fullWidth: boolean
    label: string
    maxLength?: number
    multiline: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    required: boolean
    rows: number
    type: string
    value: string
}

const TextInput = (props: TextInputProps) => {
    const classes = useStyles()

    return (
        <TextField
            className={classes.textField}
            fullWidth={props.fullWidth}
            inputProps={{
                // Set limit of input length if it is specified
                maxLength: props.maxLength ? props.maxLength : false,
            }}
            label={props.label}
            margin='dense'
            multiline={props.multiline}
            required={props.required}
            rows={props.rows}
            value={props.value}
            type={props.type}
            onChange={props.onChange}
        />
    )
}

export default TextInput
