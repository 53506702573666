import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { State } from 'index'
import { fetchTestItems } from 'reducks/tests/operations'
import { fetchChapters } from 'reducks/chapters/operations'
import { getChapterItems } from 'reducks/chapters/selectors'
import { getUserRole } from 'reducks/users/selectors'
import { ChapterItem } from 'reducks/chapters/types'
import { ExpansionList, CurriculumDone } from 'components/Curriculum'
import { getTestItems } from 'reducks/tests/selectors'
import { TestItem } from 'reducks/tests/types'
import { pushTransition } from 'reducks/router/operation'
import { getCourseId } from 'reducks/users/selectors'
import { setTitleAction } from 'reducks/page/action'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    align: {
        textAlign: 'right',
    },
})

const Tests = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const selector = useSelector((state: State) => state)
    const courseId = getCourseId(selector)

    const userRole = getUserRole(selector)
    const isManager = userRole === 'teacher' || userRole === 'administrator'

    const chaptersList = getChapterItems(selector)

    const testItems = getTestItems(selector)
    const uncategorizedTest = testItems.filter((item: TestItem) => item.chapter_id === '')

    useEffect(() => {
        if (chaptersList.length === 0) {
            dispatch(fetchChapters(courseId))
        }
    }, [chaptersList.length])

    useEffect(() => {
        if (testItems.length === 0) {
            dispatch(fetchTestItems(courseId))
        }
    }, [testItems.length])

    // Get uncategorized test
    const uncategorizedChapter: ChapterItem = {
        chapter_name: '未分類',
        chapter_id: '',
        course_id: courseId,
        order: '999999',
        tests: [],
    }

    useEffect(() => {
        const title = '【テスト】カリキュラム一覧'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section-wrapin'>
                <div className='module-spacer--small' />
                {isManager && (
                    <div className='u-text-right' id='create-button'>
                        <a
                            className='p-btn-small p-btn-primary'
                            role='button'
                            onClick={() => dispatch(pushTransition('/pg-admin/test/edit'))}
                        >
                            新規作成
                        </a>
                    </div>
                )}
                <div className='module-spacer--small' />
                <div className={classes.align}>
                    <CurriculumDone type={'tests'} />
                </div>
                <div className='module-spacer--small' />
                <div>
                    {chaptersList.length > 0 &&
                        chaptersList.map((chapter: ChapterItem) => {
                            if (chapter.order.length === 6) {
                                return <ExpansionList chapter={chapter} key={chapter.chapter_id} />
                            } else {
                                return <></>
                            }
                        })}

                    {uncategorizedTest.length > 0 && (
                        <ExpansionList chapter={uncategorizedChapter} key={'uncategorized'} />
                    )}
                </div>
                <div className='module-spacer--medium' />
                <div id='pagination' />
            </div>
        </section>
    )
}

export default Tests
