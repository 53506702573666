import React from 'react'
import { CurriculumItem } from 'reducks/curriculum/types'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getUserRole } from '../../reducks/users/selectors'
import { pushTransition } from '../../reducks/router/operation'
import { getCourseId } from 'reducks/users/selectors'

interface CurriculumNavProps {
    id: string
}

const CurriculumNav = (props: CurriculumNavProps) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const userRole = getUserRole(selector)
    const courseId = getCourseId(selector)

    const curriculum = selector.curriculum
    const list = curriculum.list
    let prevCurriculum,
        nextCurriculum,
        prevUrl = '',
        nextUrl = ''
    const publishedList = list.filter(
        (item: CurriculumItem) => item.isPublished || userRole === 'teacher' || userRole === 'administrator'
    )
    const itemIndex = publishedList.findIndex((item: CurriculumItem) => item.id === props.id)

    if (itemIndex !== -1) {
        prevCurriculum = publishedList[itemIndex - 1] ? publishedList[itemIndex - 1] : false
        prevUrl = `/${courseId}/curriculum/${prevCurriculum.id}`
        nextCurriculum = publishedList[itemIndex + 1] ? publishedList[itemIndex + 1] : false
        nextUrl = `/${courseId}/curriculum/${nextCurriculum.id}`
    }

    return (
        <div className='p-grid__navigation'>
            {prevCurriculum && (
                <a
                    className='p-btn__navigation p-btn-secondary p-icon__arrow-back u-text__ellipsis-one'
                    role='button'
                    onClick={() => dispatch(pushTransition(prevUrl))}
                >
                    {prevCurriculum.title}
                </a>
            )}
            {nextCurriculum && (
                <a
                    className='p-btn__navigation p-btn-secondary p-icon__arrow-next u-text__ellipsis-one'
                    role='button'
                    onClick={() => dispatch(pushTransition(nextUrl))}
                >
                    {nextCurriculum.title}
                </a>
            )}
        </div>
    )
}

export default CurriculumNav
