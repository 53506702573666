import * as Actions from 'reducks/loading/action'
import { initialState } from 'reducks/store/initialState'

export const LoadingReducer = (state = initialState.loading, action: any) => {
    switch (action.type) {
        case Actions.HIDE_LOADING:
            return {
                ...state,
                ...action.payload,
            }
        case Actions.SHOW_LOADING:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
