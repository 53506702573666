import { CalendarsType } from './types'

export const FETCH_CALENDARS = 'FETCH_CALENDARS'
export const fetchCalendarsAction = (calendars: CalendarsType) => {
    return {
        type: 'FETCH_CALENDARS',
        payload: calendars,
    }
}

export const FETCH_MENTORS = 'FETCH_MENTORS'
export const fetchMentorsAction = (mentors: { id: string; name: string }[]) => {
    return {
        type: 'FETCH_MENTORS',
        payload: mentors,
    }
}

export const INIT_SUPPORTS = 'INIT_SUPPORTS'
export const initSupportsAction = () => {
    return {
        type: 'INIT_SUPPORTS',
        payload: null,
    }
}

export const SELECT_SUPPORT_CALENDAR = 'SELECT_SUPPORT_CALENDAR'
export const selectSupportCalendarAction = (dayOfWeek: string, id: string, mentor: string) => {
    return {
        type: 'SELECT_SUPPORT_CALENDAR',
        payload: {
            dayOfWeek: dayOfWeek,
            id: id,
            mentor: mentor,
        },
    }
}
