import React, { useCallback } from 'react'
import Button from '@material-ui/core/Button'
import { useDispatch } from 'react-redux'
import { goBackTransition } from 'reducks/router/operation'
import { makeStyles } from '@material-ui/styles'
import { createStyles, Theme } from '@material-ui/core'

interface ButtonProps {
    label: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            backgroundColor: theme.palette.grey['300'],
            fontSize: 16,
            height: 48,
            marginBottom: 16,
            width: 256,
        },
    })
)

const GoBackButton = (props: ButtonProps) => {
    const dispatch = useDispatch()
    const goBack = useCallback(() => {
        dispatch(goBackTransition())
    }, [])

    const classes = useStyles()

    return (
        <Button className={classes.button} variant='contained' onClick={goBack}>
            {props.label}
        </Button>
    )
}

export default GoBackButton
