import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useSelector } from 'react-redux'
import { State } from 'index'
import { getLoadingState, getLoadingText } from 'reducks/loading/selectors'

const Loading: React.StatelessComponent<React.Props<{}>> = ({ children }: { children?: any }) => {
    const selector = useSelector((state: State) => state)
    const isBeingLoaded = getLoadingState(selector)
    const loadingText = getLoadingText(selector)

    return (
        <>
            {isBeingLoaded && (
                <section className='c-section__loading'>
                    <CircularProgress />
                    <p>{loadingText}</p>
                </section>
            )}
            {children}
        </>
    )
}

export default Loading
