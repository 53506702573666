import React, { useCallback, useState } from 'react'
import ReactHtmlParser from 'html-react-parser'
import NoProfile from 'assets/img/src/no-profile.png'
import { CommentItem } from 'reducks/curriculum/types'
import { returnCodeToBr, datetimeToString } from 'functions/commonFunc'
import CommentEdit from 'components/Comments/CommentEdit'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { editComment } from 'reducks/curriculum/operations'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { db } from 'firebase/index'
import { getUserId } from 'reducks/users/selectors'
import { ImagePreview } from 'components/UIkit'
import { storage } from 'firebase/index'
import { hideLoadingAction, showLoadingAction } from 'reducks/loading/action'

interface CommentProps {
    comment: CommentItem
    curriculumId: string
}

const CommentListItem = (props: CommentProps) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const uid = getUserId(selector)

    const [isEditable, setEditable] = useState(false)
    const [comment, inputEditableComment] = useState(props.comment.comment)
    const [images, setImages] = useState<Array<{ id: string; path: string }>>(
        props.comment.images ? props.comment.images : []
    )

    const colorMentionedName = (text: string) => {
        if (text === '') {
            return text
        } else {
            const mentionedNames: string[] | null = text.match(/@\[.+]\(.+\)/g)

            if (!mentionedNames) {
                return text
            }

            const mentionedName = mentionedNames[0]
            const name = mentionedName
                .replace(/@\[/g, `<span class="u-text-mentioned" >@`)
                .replace(/]\(.{28}\)/g, '</span>')
            text = text.replace(mentionedName, name)

            return text
        }
    }

    const text = returnCodeToBr(props.comment.comment) as string
    const coloredText = colorMentionedName(text)
    let commentText = []
    commentText.push(typeof text === 'string' ? ReactHtmlParser(coloredText) : <></>)

    const datetime = datetimeToString(props.comment.updated_at.toDate())
    const iconPath = props.comment.commenter_icon !== '' ? props.comment.commenter_icon : NoProfile

    const deleteComment = (commentId: string, curriculumId: string) => {
        for (const image of images) {
            storage.ref('images').child(image.id).delete().then()
        }

        return db.collection('curriculum').doc(curriculumId).collection('comments').doc(commentId).delete()
    }

    const deleteImage = useCallback(
        async (id: string) => {
            const ret = window.confirm('この画像を削除しますか？')
            if (!ret) {
                return false
            } else {
                const newImages = images.filter((image) => image.id !== id)
                setImages(newImages)
                return storage.ref('images').child(id).delete()
            }
        },
        [images]
    )

    const uploadImage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(showLoadingAction('uploading...'))
            const file = event.target.files
            // @ts-ignore
            let blob = new Blob(file, { type: 'image/jpeg' })

            // Generate random 16 digits strings
            const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            const N = 16
            const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N)))
                .map((n) => S[n % S.length])
                .join('')

            const uploadRef = storage.ref('images').child(fileName)
            const uploadTask = uploadRef.put(blob)

            uploadTask
                .then(() => {
                    // Handle successful uploads on complete
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        const newImage = { id: fileName, path: downloadURL }
                        setImages((prevState) => [...prevState, newImage])
                        dispatch(hideLoadingAction())
                    })
                })
                .catch(() => {
                    dispatch(hideLoadingAction())
                })
        },
        [setImages]
    )

    return (
        <div className='p-grid__comment__list'>
            {isEditable ? (
                <CommentEdit
                    close={setEditable}
                    commentId={props.comment.comment_id}
                    curriculumId={props.curriculumId}
                    deleteImage={deleteImage}
                    images={images}
                    label={''}
                    onChange={inputEditableComment}
                    onClick={editComment}
                    rows={3}
                    uploadImage={uploadImage}
                    value={comment}
                />
            ) : (
                <div className='p-grid__comment__list-parent'>
                    <img className='profile-icon' src={iconPath} alt='icon' />
                    <div className='bubble'>
                        <p className='header'>
                            <span className='username'>{props.comment.commenter_name}</span>
                            <span className='datetime'>{datetime}</span>
                        </p>
                        <p>{commentText}</p>
                        <div className='p-grid__list-images'>
                            {images.length > 0 &&
                                images.map((image) => <ImagePreview id={image.id} path={image.path} />)}
                        </div>
                    </div>

                    {props.comment.commenter_id === uid && (
                        <div className='buttons__operation'>
                            <IconButton onClick={() => setEditable(true)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    const ret = window.confirm('コメントを削除しますか？')
                                    if (!ret) {
                                        return false
                                    } else {
                                        return deleteComment(props.comment.comment_id, props.curriculumId)
                                    }
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default CommentListItem
