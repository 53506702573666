import { TestItem, TestItems } from './types'

export const FETCH_TEST_ITEM = 'FETCH_TEST_ITEM'
export const fetchTestItemAction = (testItem: TestItem) => {
    return {
        type: 'FETCH_TEST_ITEM',
        payload: testItem,
    }
}

export const FETCH_TEST_ITEMS = 'FETCH_TEST_ITEMS'
export const fetchTestItemsAction = (testItems: TestItems) => {
    return {
        type: 'FETCH_TEST_ITEMS',
        payload: testItems,
    }
}

export const INIT_TEST = 'INIT_TEST'
export const initTestAction = () => {
    return {
        type: 'INIT_TEST',
        payload: null,
    }
}

export const SAVE_ANSWER = 'SAVE_ANSWER'
export const saveAnswerAction = (answer: string) => {
    return {
        type: 'SAVE_ANSWER',
        payload: answer,
    }
}

export const SAVE_HINT = 'SAVE_HINT'
export const saveHintAction = (hint: string) => {
    return {
        type: 'SAVE_HINT',
        payload: hint,
    }
}

export const SAVE_HINT2 = 'SAVE_HINT2'
export const saveHint2Action = (hint: string) => {
    return {
        type: 'SAVE_HINT2',
        payload: hint,
    }
}
export const SAVE_QUESTION = 'SAVE_QUESTION'
export const saveQuestionAction = (question: string) => {
    return {
        type: 'SAVE_QUESTION',
        payload: question,
    }
}
