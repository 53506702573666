import * as Actions from 'reducks/modal/action'
import { initialState } from 'reducks/store/initialState'

export const ModalReducer = (state = initialState.modal, action: any) => {
    switch (action.type) {
        case Actions.CLOSE_MODAL:
            return {
                ...state,
                ...action.payload,
            }
        case Actions.OPEN_IMAGE_MODAL:
            return {
                ...state,
                ...action.payload,
            }
        case Actions.OPEN_TEXT_MODAL:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
