import * as Actions from 'reducks/supports/action'
import { initialState } from 'reducks/store/initialState'

export const SupportsReducer = (state = initialState.supports, action: any) => {
    switch (action.type) {
        case Actions.FETCH_CALENDARS:
            return {
                ...state,
                calendars: [...action.payload],
            }
        case Actions.FETCH_MENTORS:
            return {
                ...state,
                mentors: [...action.payload],
            }
        case Actions.INIT_SUPPORTS:
            return {
                ...initialState.supports,
            }
        case Actions.SELECT_SUPPORT_CALENDAR:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
