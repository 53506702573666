import React, { useMemo } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import { State } from '../index'
import { Registration, RegistrationWithSupport, SupportCheckoutForm } from './index'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const STRIPE_PUBLIC_KEY =
    process.env.NODE_ENV === 'production'
        ? 'pk_live_ZMNIu5RMEyEOH1fQCiAE9g2u001KOc7j5u'
        : 'pk_test_ZgurY8Iv282uziELtMmcQC3m0060mVHulH'
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const CheckoutWrapper = () => {
    const selector = useSelector((state: State) => state)
    const pathname = selector.router.location.pathname

    const CheckoutElement = useMemo(() => {
        // Determine what checkout form is be displayed
        switch (true) {
            case /^\/registration*/.test(pathname):
                return <Registration />
            case /^\/support\/registration*/.test(pathname):
                return <RegistrationWithSupport />
            case /^\/support\/checkout*/.test(pathname):
                return <SupportCheckoutForm />
            default:
                return <></>
        }
    }, [])

    return <Elements stripe={stripePromise}>{CheckoutElement}</Elements>
}

export default CheckoutWrapper
