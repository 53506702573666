import React, { useEffect } from 'react'
import ReactHtmlParser from 'html-react-parser'
import Prism from 'prismjs'

interface ContentProps {
    content: string
    eyecatch_path?: string
    title?: string
}

const Content = (props: ContentProps) => {
    useEffect(() => {
        // Push onto callback queue so it runs after the DOM is updated
        setTimeout(() => Prism.highlightAll(), 2000)
    }, [])

    return (
        <div className='p-grid__curriculum'>
            <article className='p-content__body'>
                <h1>{props.title}</h1>
                {props.eyecatch_path !== '' && <img className='p-media__thumb' src={props.eyecatch_path} />}
                <hr className='c-section__line' />
                {ReactHtmlParser(props.content)}
            </article>
        </div>
    )
}

export default Content
