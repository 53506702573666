import { db, FirebaseFieldValue, FirebaseTimestamp } from 'firebase/index'
import {
    fetchTestItemAction,
    fetchTestItemsAction,
    saveAnswerAction,
    saveHint2Action,
    saveHintAction,
    saveQuestionAction,
} from './action'
import { TestItem, TestItems } from './types'
import { fetchChapters } from 'reducks/chapters/operations'
import { pushTransition } from 'reducks/router/operation'

const chaptersRef = db.collection('chapters')
const testsRef = db.collection('tests')

const hasDone = (id: string, hasDone: { [s: string]: boolean }) => {
    let flag: boolean = false

    if (!hasDone) {
        return flag
    } else {
        for (let test of Object.keys(hasDone)) {
            if (test === id && hasDone[test]) {
                flag = true
            }
        }
        return flag
    }
}

export const deleteTest = (id: string) => {
    return async (dispatch: any, getState: any) => {
        const batch = db.batch()

        await chaptersRef
            .where('curriculum', 'array-contains', id)
            .get()
            .then((snapshots) => {
                // Add queries removing Test ID from test in chapter
                snapshots.forEach((doc) => {
                    batch.set(
                        chaptersRef.doc(doc.id),
                        { curriculum: FirebaseFieldValue.arrayRemove(id) },
                        { merge: true }
                    )
                })
            })
            .then(() => {
                // Add a query to remove Test ID from test data
                batch.delete(testsRef.doc(id))
            })
            .catch((error) => {
                throw new Error(error)
            })

        await db
            .collection('users')
            .where(`done_tests.${id}`, '==', true)
            .get()
            .then((snapshots) => {
                snapshots.forEach((snapshot) => {
                    const userData = snapshot.data()
                    const doneTests = userData.done_tests
                    doneTests[id] = false

                    batch.update(db.collection('users').doc(snapshot.id), { done_tests: doneTests })
                })
            })

        batch.commit().then(() => {
            const courseId = getState().users.course
            dispatch(fetchChapters(courseId))
            dispatch(fetchTestItems(courseId))
        })
    }
}

export const fetchTest = (id: string) => {
    return async (dispatch: any, getState: any) => {
        testsRef
            .doc(id)
            .get()
            .then((snapshot) => {
                const data = snapshot.data()
                if (!data) {
                    throw new Error('課題データが存在しません。')
                }

                const updateValue: TestItem = {
                    answer: data.answer,
                    author_id: data.author_id,
                    chapter_id: data.chapter_id ? data.chapter_id : '',
                    question: data.question,
                    created_at: data.created_at,
                    course_id: data.course_id,
                    eyecatch_path: data.eyecatch_path,
                    hasDone: hasDone(id, getState().users.done_tests),
                    hint: data.hint,
                    hint2: data.hint2 ? data.hint2 : '',
                    id: id,
                    is_free: data.is_free,
                    isPublished: data.isPublished,
                    order: data.order,
                    references: data.references,
                    title: data.title,
                    updated_at: data.updated_at,
                }
                dispatch(fetchTestItemAction(updateValue))
            })
    }
}

export const fetchTestItems = (courseId: string) => {
    return async (dispatch: any, getState: any) => {
        return testsRef
            .where('course_id', '==', courseId)
            .orderBy('order', 'asc')
            .get()
            .then((snapshots) => {
                let list: TestItems = []
                snapshots.forEach((doc) => {
                    const data = doc.data()
                    list.push({
                        answer: data.answer,
                        author_id: data.author_id,
                        chapter_id: data.chapter_id ? data.chapter_id : '',
                        question: data.question,
                        created_at: data.created_at,
                        course_id: data.course_id,
                        eyecatch_path: data.eyecatch_path,
                        hasDone: hasDone(doc.id, getState().users.done_tests),
                        hint: data.hint,
                        hint2: data.hint2 ? data.hint2 : '',
                        id: doc.id,
                        is_free: data.is_free,
                        isPublished: data.isPublished,
                        order: data.order,
                        references: data.references,
                        title: data.title,
                        updated_at: data.updated_at,
                    })
                })
                dispatch(fetchTestItemsAction(list))
            })
    }
}

export const saveContent = (content: string, type: string) => {
    return async (dispatch: any) => {
        switch (type) {
            case 'answer':
                dispatch(saveAnswerAction(content))
                break
            case 'hint':
                dispatch(saveHintAction(content))
                break
            case 'hint2':
                dispatch(saveHint2Action(content))
                break
            case 'question':
                dispatch(saveQuestionAction(content))
                break
            default:
                break
        }
    }
}

export const saveTest = (testId: string, data: any) => {
    return async (dispatch: any, getState: any) => {
        const batch = db.batch()
        const testList = getState().tests.list

        if (testId === '') {
            const ref = testsRef.doc()
            data.created_at = FirebaseTimestamp.now()
            testId = ref.id
        }

        const index = testList.findIndex((test: TestItem) => test.id === testId)
        const currentChapterId = testList[index] ? testList[index].chapter_id : ''

        // Remove test id from the list in chapter
        if (currentChapterId !== '') {
            batch.set(
                chaptersRef.doc(currentChapterId),
                { curriculum: FirebaseFieldValue.arrayRemove(testId) },
                { merge: true }
            )
        }

        // Add test id to the list in chapter
        if (data.chapter_id !== '') {
            batch.update(chaptersRef.doc(data.chapter_id), {
                curriculum: FirebaseFieldValue.arrayUnion(testId),
            })
        }

        // Update test
        batch.set(testsRef.doc(testId), data, { merge: true })

        return batch
            .commit()
            .then(() => {
                alert('課題の変更を保存しました。')
                dispatch(pushTransition('/pg-admin/test/edit/' + testId))
            })
            .catch((error) => {
                console.error(error)
                alert('課題の変更に失敗しました。通信環境を確認してもう1度試してください。')
            })
    }
}
