import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 156,
        },
    })
)
interface DatePickerProps {
    date: string
    handleDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const DatePicker = (props: DatePickerProps) => {
    const classes = useStyles()

    return (
        <form className={classes.container} noValidate>
            <TextField
                type='date'
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                value={props.date}
                onChange={props.handleDateChange}
            />
        </form>
    )
}

export default DatePicker
