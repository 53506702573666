import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'

const CheckoutForm = () => {
    return (
        <form>
            <label className='u-text-small'>カード情報のご入力</label>
            <CardElement className='p-input__card' />
            <div className='module-spacer--small' />
        </form>
    )
}

export default CheckoutForm
