import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { getModalBody, getModalOpen } from 'reducks/modal/selectors'
import { closeModalAction } from 'reducks/modal/action'

const useStyles = makeStyles({
    root: {
        margin: '0 auto',
        padding: '0',
        maxWidth: 768,
    },
})

const ModalImage = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const selector = useSelector((state: State) => state)
    const imagePath = getModalBody(selector)
    const open = getModalOpen(selector)

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={() => dispatch(closeModalAction())}
            aria-labelledby='enlarged-image'
            aria-describedby='enlarged-image'
        >
            <img src={imagePath} alt='Enlarged Image' />
        </Dialog>
    )
}

export default ModalImage
