import { createSelector } from 'reselect'
import State from 'reducks/store/types'

const usersSelector = (state: State) => state.users

export const getCourseId = createSelector([usersSelector], (state) => state.course)

export const getDoneTests = createSelector([usersSelector], (state) => state.done_tests)

export const getDoneTestsAmount = createSelector([usersSelector], (state) => state.doneTestsAmount)

export const getDoneTestsRate = createSelector([usersSelector], (state) => state.doneTestsRate)

export const getIsFirstLogin = createSelector([usersSelector], (state) => state.isFirstLogin)

export const getIsSubscriber = createSelector([usersSelector], (state) => state.isSubscriber)

export const getReadCurriculum = createSelector([usersSelector], (state) => state.read_curriculum)

export const getReadCurriculumAmount = createSelector([usersSelector], (state) => state.readCurriculumAmount)

export const getReadCurriculumRate = createSelector([usersSelector], (state) => state.readCurriculumRate)

export const getReadNotifications = createSelector([usersSelector], (state) => state.read_notifications)

export const getUserEmail = createSelector([usersSelector], (state) => state.email)

export const getUserId = createSelector([usersSelector], (state) => state.uid)

export const getUserRole = createSelector([usersSelector], (state) => state.role)

export const getUsername = createSelector([usersSelector], (state) => state.username)

export const getSignedIn = createSelector([usersSelector], (state) => state.isSignedIn)

export const getLoyaltyPoints = createSelector([usersSelector], (state) => state.loyaltyPoints)

export const getSharedIds = createSelector([usersSelector], (state) => state.sharedIds)
