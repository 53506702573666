import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 128,
        },
    })
)

interface TimePickerProps {
    label: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    value: string
}

const TimePicker = (props: TimePickerProps) => {
    const classes = useStyles()

    return (
        <form className={classes.container} noValidate>
            <TextField
                label={props.label}
                type='time'
                defaultValue='00:00'
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 1800, // 30 min
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event)}
                value={props.value}
            />
        </form>
    )
}

export default TimePicker
