import { db, FirebaseTimestamp } from 'firebase/index'
import { push } from 'connected-react-router'
import { fetchChaptersAction } from './action'
import { ChapterItems } from './types'

const curriculumRef = db.collection('curriculum')
const chaptersRef = db.collection('chapters')

export const createChapter = (chapterName: string, courseId: string, order: string) => {
    return async (dispatch: any) => {
        return chaptersRef
            .add({
                course_id: courseId,
                created_at: FirebaseTimestamp.now(),
                chapter_name: chapterName,
                order: order,
                curriculum: [],
                updated_at: FirebaseTimestamp.now(),
            })
            .then(() => {
                dispatch(push('/pg-admin/chapters'))
            })
            .catch((error) => {
                throw new Error(error)
            })
    }
}

export const deleteChapter = (chapterId: string) => {
    return async (dispatch: any, getState: any) => {
        const batch = db.batch()
        curriculumRef
            .where('chapter_id', '==', chapterId)
            .get()
            .then((snapshots) => {
                snapshots.forEach((doc) => {
                    batch.set(curriculumRef.doc(doc.id), { chapter_id: '' }, { merge: true })
                })
                batch.delete(chaptersRef.doc(chapterId))
                batch.commit().then(() => {
                    const courseId = getState().users.course
                    dispatch(fetchChapters(courseId))
                })
            })
            .catch((error) => {
                throw new Error(error)
            })
    }
}

export const fetchChapters = (courseId: string) => {
    return async (dispatch: any) => {
        return chaptersRef
            .where('course_id', '==', courseId)
            .orderBy('order', 'asc')
            .get()
            .then((snapshot) => {
                let chapters: ChapterItems = []
                snapshot.forEach(async (doc) => {
                    const data = doc.data()
                    chapters.push({
                        chapter_name: data.chapter_name,
                        chapter_id: doc.id,
                        course_id: data.course_id,
                        order: data.order,
                        curriculum: data.curriculum,
                    })
                })
                dispatch(fetchChaptersAction(chapters))
            })
            .catch((error) => {
                throw new Error(error)
            })
    }
}

export const saveChapter = (chapterId: string, chapterName: string, courseId: string, order: string) => {
    return async (dispatch: any) => {
        return chaptersRef
            .doc(chapterId)
            .set(
                {
                    chapter_name: chapterName,
                    course_id: courseId,
                    order: order,
                    updated_at: FirebaseTimestamp.now(),
                },
                { merge: true }
            )
            .then(() => {
                dispatch(push('/pg-admin/chapters'))
            })
            .catch((error) => {
                throw new Error(error)
            })
    }
}
