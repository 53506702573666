import React, { useEffect, useState } from 'react'
import { ImageContain, SelectBox } from 'components/UIkit'
import GoodSwacrab from 'assets/img/swacrab/12_good.png'
import { SubscribeButton } from 'components/Supports'
import { fetchCalendars, fetchMentors } from 'reducks/supports/operations'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../index'
import { getCalendars, getMentors } from 'reducks/supports/selectors'
import { makeStyles } from '@material-ui/styles'
import Swiper from 'react-id-swiper'
import { Classes, Cls } from '../reducks/supports/types'
import Grid from '@material-ui/core/Grid'
import { Theme } from '@material-ui/core/styles'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const useStyles = makeStyles((theme: Theme) => ({
    selectBox: {
        display: 'flex',
        margin: '0 auto',
        width: 320,
    },
    column: {
        height: 'auto',
        padding: '0 8px',
        marginBottom: 48,
        [theme.breakpoints.down('sm')]: {
            borderLeft: '1px solid #bdbdbd',
            borderRight: '1px solid #bdbdbd',
            minWidth: '50%',
        },
        [theme.breakpoints.up('sm')]: {
            borderLeft: '1px solid #bdbdbd',
            minWidth: 142,
            '&:last-child': {
                borderRight: '1px solid #bdbdbd',
            },
        },
    },
}))

const SupportsFromLp = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const mentors = getMentors(selector)
    const pageTitle = getPageTitle(selector)

    const [mentor, setMentor] = useState<string>('')

    const calendars = getCalendars(selector)

    const params = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        spaceBetween: 0,
        slidesPerView: 1,
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 7,
            },
        },
    }

    useEffect(() => {
        if (mentor !== '') {
            dispatch(fetchCalendars(mentor))
        }
    }, [mentor])

    useEffect(() => {
        if (mentors.length === 0) {
            dispatch(fetchMentors())
        }
    }, [])

    useEffect(() => {
        const title = 'メンターの空き状況'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section-wrapin'>
            <h2 className='u-text__headline u-display-none-over-md'>{pageTitle}</h2>
            <div className='module-spacer--medium' />
            <div className={classes.selectBox}>
                <SelectBox
                    label={'メンターを選択する'}
                    options={mentors}
                    required={true}
                    select={setMentor}
                    value={mentor}
                />
                <ImageContain path={GoodSwacrab} height={128} />
            </div>
            <div className='module-spacer--medium' />

            <Grid container justify='center' spacing={0}>
                {mentor !== '' && (
                    <Swiper {...params}>
                        {calendars.map((value: { dayOfWeek: string; classes: Classes }) => (
                            <Grid
                                className={classes.column}
                                key={value.dayOfWeek}
                                alignItems='center'
                                direction='column'
                                container
                            >
                                <Grid className='u-text-bolder' item>
                                    {value.dayOfWeek}
                                </Grid>
                                <div className='module-spacer--small' />
                                {value.classes.map((cls: Cls) => (
                                    <Grid item key={cls.id}>
                                        <SubscribeButton
                                            disabled={cls.isSubscribed}
                                            label={cls.label}
                                            onClick={() => true}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Swiper>
                )}
            </Grid>
            <div className='module-spacer--medium' />
        </section>
    )
}

export default SupportsFromLp
