import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { InputText, PrimaryButton, SelectBox, SimpleTable } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { ApplePayButton, CheckoutForm, PrivacyAgreement } from 'components/Checkout/index'
import Swacrab from 'assets/img/src/pg-swacrab.png'
import { registerMembership } from 'reducks/payments/operations'
import { CalendarType, Cls } from 'reducks/supports/types'
import { fetchCalendars, fetchMentors } from 'reducks/supports/operations'
import { getCalendars, getDayOfWeek, getMentor, getMentors, getSupportId } from 'reducks/supports/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const RegistrationWithSupport = () => {
    const elements = useElements()
    const dispatch = useDispatch()
    const stripe = useStripe()

    const selector = useSelector((state: State) => state)
    const calendars = getCalendars(selector)
    const mentors = getMentors(selector)

    // Declare ref not to update dayOfWeek and time after the component did update
    const mounted = useRef(false)

    const initialFeeAmount = 0
    const membershipFeeAmount = 550

    const pathname = selector.router.location.pathname
    const inviterId = pathname.split('/registration/')[1] ? pathname.split('/registration/')[1] : ''

    const [email, inputEmail] = useState(''),
        [password, inputPassword] = useState(''),
        [confirmPassword, inputConfirmPassword] = useState(''),
        [invitationCode, inputInvitationCode] = useState(inviterId),
        [username, inputUsername] = useState(''),
        [isPrivacyAgreed, setIsPrivacyAgreed] = useState<boolean>(false),
        [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false),
        [mentor, setMentor] = useState<string>(getMentor(selector)),
        [firstDayOfWeek, setFirstDayOfWeek] = useState<string>(getDayOfWeek(selector)),
        [firstSupportId, setFirstSupportId] = useState<string>(getSupportId(selector)),
        [secondDayOfWeek, setSecondDayOfWeek] = useState<string>(''),
        [secondSupportId, setSecondSupportId] = useState<string>(''),
        [term, setTerm] = useState<string>('curriculumPlan'),
        [supportFeeAmount, setSupportFeeAmount] = useState<number>(Math.floor(315000))

    const handleIsPrivacyAgreed = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPrivacyAgreed(event.target.checked)
    }, [])

    const handleIsTermsAgreed = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTermsAgreed(event.target.checked)
    }, [])

    const selectTerm = useCallback(
        (id: string) => {
            const selectedTerm = termOptions.filter((term) => term.id === id)[0]
            setSupportFeeAmount(selectedTerm.subtotal)
            setTerm(id)
        },
        [setTerm]
    )

    // Set the term options and the table of terms and price
    const termOptions = [
        {
            id: 'curriculumPlan',
            name: 'カリキュラム達成プラン(3ヶ月)',
            perWeek: 2,
            subtotal: 315000,
        },
        {
            id: 'coDevelopmentPlan',
            name: '共同開発プラン(4ヶ月)',
            perWeek: 2,
            subtotal: 420000,
        },
        {
            id: 'appReleasePlan',
            name: 'アプリリリースプラン(4ヶ月)',
            perWeek: 2,
            subtotal: 420000,
        },
        {
            id: 'fullPlan',
            name: 'アプリリリース×共同開発プラン(5ヶ月)',
            perWeek: 2,
            subtotal: 525000,
        },
    ]

    // Filtered valid day of weeks from all the one
    const dayOfWeeks = useMemo(() => {
        const validDays = calendars.filter((calendar: CalendarType) => calendar.classes.length > 0)
        return validDays.map((calendar: CalendarType) => {
            return { id: calendar.dayOfWeek, name: calendar.dayOfWeek + '曜日' }
        })
    }, [calendars])

    const selectableFirstSupportTimes = useMemo(() => {
        const selectedDay = calendars.filter((calendar: CalendarType) => calendar.dayOfWeek === firstDayOfWeek)[0]
        if (selectedDay) {
            const vacantClasses = selectedDay.classes.filter((cls: Cls) => !cls.isSubscribed)
            return vacantClasses.map((cls: Cls) => {
                return { id: cls.id, name: cls.label }
            })
        } else {
            return []
        }
    }, [calendars, firstDayOfWeek])

    const selectableSecondSupportTimes = useMemo(() => {
        const selectedDay = calendars.filter((calendar: CalendarType) => calendar.dayOfWeek === secondDayOfWeek)[0]
        if (selectedDay) {
            const vacantClasses = selectedDay.classes.filter((cls: Cls) => !cls.isSubscribed)
            return vacantClasses.map((cls: Cls) => {
                return { id: cls.id, name: cls.label }
            })
        } else {
            return []
        }
    }, [calendars, secondDayOfWeek])

    const paymentTable = useMemo(() => {
        const subtotal = initialFeeAmount + supportFeeAmount
        const tax = Math.floor(subtotal * 0.1)
        const total = Math.floor(subtotal * 1.1)
        const planName = termOptions.filter((option) => option.id === term)[0].name
        return [
            { label: '項目', value: '金額' },
            { label: '入会金', value: '¥' + initialFeeAmount.toLocaleString() },
            { label: planName, value: '¥' + supportFeeAmount.toLocaleString() },
            { label: '小計', value: '¥' + subtotal.toLocaleString() },
            { label: '消費税', value: '¥' + tax.toLocaleString() },
            { label: '合計', value: '¥' + total.toLocaleString() },
        ]
    }, [supportFeeAmount])

    const perWeek = useMemo(() => {
        return termOptions.filter((option) => option.id === term)[0].perWeek
    }, [term])

    const pageTitle = getPageTitle(selector)

    useEffect(() => {
        if (mounted.current) {
            setFirstDayOfWeek('')
            setFirstSupportId('')
            setSecondDayOfWeek('')
            setSecondSupportId('')
        } else {
            mounted.current = true
        }
        dispatch(fetchCalendars(mentor))
    }, [mentor])

    useEffect(() => {
        if (mentors.length === 0) {
            dispatch(fetchMentors())
        }
    }, [])

    useEffect(() => {
        const title = 'アカウント登録およびサポート付きプランのお申し込み'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <>
            <Helmet>
                <script type='text/javascript' src='https://js.felmat.net/fmlp.js' async />
            </Helmet>
            <section className='c-section'>
                <div className='c-section__container'>
                    <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                    <img src={Swacrab} alt='Swacrab' />
                    <div className='module-spacer--small' />

                    <InputText
                        id={'username'}
                        type={'text'}
                        label={'ユーザー名'}
                        value={username}
                        onChange={inputUsername}
                        required={true}
                    />
                    <InputText
                        id={'email'}
                        type={'email'}
                        label={'メールアドレス'}
                        value={email}
                        onChange={inputEmail}
                        required={true}
                    />
                    <InputText
                        id={'password'}
                        type={'password'}
                        label={'パスワード（半角英数字6文字以上）'}
                        value={password}
                        onChange={inputPassword}
                        required={true}
                    />
                    <InputText
                        id={'confirm-password'}
                        type={'password'}
                        label={'パスワードの再確認'}
                        value={confirmPassword}
                        onChange={inputConfirmPassword}
                        required={true}
                    />
                    <InputText
                        id={'invitation-code'}
                        type={'text'}
                        label={'招待コード（半角英数字28桁）'}
                        value={invitationCode}
                        onChange={inputInvitationCode}
                        required={false}
                    />

                    <SelectBox
                        label={'プランを選択する'}
                        options={termOptions}
                        required={true}
                        select={selectTerm}
                        value={term}
                    />
                    <SelectBox
                        label={'メンターを選択する'}
                        options={mentors}
                        required={true}
                        select={setMentor}
                        value={mentor}
                    />
                    <div className='module-spacer--small' />

                    <p>1つめのサポート日時を選ぶ</p>
                    <SelectBox
                        label={'曜日を選択する'}
                        options={dayOfWeeks}
                        required={true}
                        select={setFirstDayOfWeek}
                        value={firstDayOfWeek}
                    />
                    <SelectBox
                        label={'時間帯を選択する'}
                        options={selectableFirstSupportTimes}
                        required={true}
                        select={setFirstSupportId}
                        value={firstSupportId}
                    />
                    <div className='module-spacer--small' />

                    {perWeek === 2 && (
                        <>
                            <p>2つめのサポート日時を選ぶ</p>
                            <SelectBox
                                label={'曜日を選択する'}
                                options={dayOfWeeks}
                                required={true}
                                select={setSecondDayOfWeek}
                                value={secondDayOfWeek}
                            />
                            <SelectBox
                                label={'時間帯を選択する'}
                                options={selectableSecondSupportTimes}
                                required={true}
                                select={setSecondSupportId}
                                value={secondSupportId}
                            />
                            <div className='module-spacer--small' />
                        </>
                    )}

                    <p className='u-text-bolder'>決済情報の入力</p>
                    <p>選択したプランの合計金額は以下です。</p>
                    <p>また、サービス利用料として月額¥550(税込)を別途いただいております。</p>
                    <SimpleTable rows={paymentTable} />
                    <div className='module-spacer--small' />

                    <p>以下のクレジットカードとデビットカードに対応しています。</p>
                    <p className='u-text-small'>VISA / MasterCard / AMEX / Discover / Diners Club / JCB</p>
                    <div className='module-spacer--extra-extra-small' />

                    <CheckoutForm />
                    <div className='module-spacer--small' />

                    <PrivacyAgreement
                        isPrivacyAgreed={isPrivacyAgreed}
                        handleIsPrivacyAgreed={(e) => handleIsPrivacyAgreed(e)}
                        isTermsAgreed={isTermsAgreed}
                        handleIsTermsAgreed={(e) => handleIsTermsAgreed(e)}
                    />
                    <div className='module-spacer--small' />

                    <p className='u-text-small u-text-primary'>
                        決済処理は通信環境の良いところで行ってください。また、決済処理中にブラウザのタブを閉じないようご注意ください。
                    </p>
                    <div className='module-spacer--small' />

                    <div className='center'>
                        {/*<PrimaryButton*/}
                        {/*    label={'アカウントを登録する'}*/}
                        {/*    onClick={() =>*/}
                        {/*        dispatch(*/}
                        {/*            registerMembership({*/}
                        {/*                confirmPassword: confirmPassword,*/}
                        {/*                email: email,*/}
                        {/*                elements: elements,*/}
                        {/*                initialFeeAmount: initialFeeAmount,*/}
                        {/*                invitationCode: invitationCode,*/}
                        {/*                isAgreed: isPrivacyAgreed && isTermsAgreed,*/}
                        {/*                membershipFeeAmount: membershipFeeAmount,*/}
                        {/*                password: password,*/}
                        {/*                perWeek: perWeek,*/}
                        {/*                stripe: stripe,*/}
                        {/*                supportFeeAmount: supportFeeAmount,*/}
                        {/*                firstSupportId: firstSupportId,*/}
                        {/*                secondSupportId: secondSupportId,*/}
                        {/*                term: term,*/}
                        {/*                username: username,*/}
                        {/*            })*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*/>*/}
                    </div>
                </div>
            </section>
        </>
    )
}

export default RegistrationWithSupport
