import React, { useEffect } from 'react'
import { PageTitle } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { ChapterItem } from 'reducks/chapters/types'
import { fetchChapters } from 'reducks/chapters/operations'
import { fetchCurriculumItems } from 'reducks/curriculum/operations'
import { getChapterItems } from 'reducks/chapters/selectors'
import { ExpansionList } from 'components/Curriculum'
import { pushTransition } from '../reducks/router/operation'
import { getCurriculumItems } from '../reducks/curriculum/selectors'
import { getCourseId } from 'reducks/users/selectors'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'
import { getTestItems } from 'reducks/tests/selectors'
import { fetchTestItems } from '../reducks/tests/operations'

const Chapters = () => {
    const dispatch = useDispatch()

    const selector = useSelector((state: State) => state)
    const chaptersList = getChapterItems(selector)
    const curriculumItems = getCurriculumItems(selector)
    const testItems = getTestItems(selector)
    const courseId = getCourseId(selector)
    const pageTitle = getPageTitle(selector)

    useEffect(() => {
        if (chaptersList.length === 0) {
            dispatch(fetchChapters(courseId))
        }
    }, [chaptersList.length])

    useEffect(() => {
        if (curriculumItems.length === 0) {
            dispatch(fetchCurriculumItems(courseId))
        }
    }, [curriculumItems.length])

    useEffect(() => {
        if (testItems.length === 0) {
            dispatch(fetchTestItems(courseId))
        }
    }, [testItems.length])

    // Get uncategorized curriculum
    const uncategorizedChapter: ChapterItem = {
        chapter_name: '自動化ツール',
        chapter_id: '',
        course_id: courseId,
        order: '9999',
        curriculum: [],
    }

    useEffect(() => {
        const title = 'チャプター管理'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section-wrapin'>
                <div className='u-display-none-over-md'>
                    <PageTitle title={pageTitle} />
                </div>
                <div className='module-spacer--medium' />
                <div className='u-text-right' id='create-button'>
                    <a
                        className='p-btn-small p-btn-primary-light'
                        role='button'
                        onClick={() => dispatch(pushTransition('/pg-admin/chapter/create'))}
                    >
                        新規作成
                    </a>
                </div>
                <div className='module-spacer--medium' />
                <ExpansionList chapter={uncategorizedChapter} />
                {chaptersList.length > 0 &&
                    chaptersList.map((chapter: ChapterItem) => {
                        return <ExpansionList chapter={chapter} key={chapter.chapter_id} />
                    })}
            </div>
        </section>
    )
}

export default Chapters
