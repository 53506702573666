import React from 'react'
import { storage } from 'firebase/index'
import { Editor } from '@tinymce/tinymce-react'

interface EditorProps {
    onChange: (value: string) => void
    value: string
}

const CurriculumEditor = (props: EditorProps) => {
    return (
        <Editor
            apiKey='jlmwqcxi1rjyizrbfbkanw37x7mx1tgv4mzb3nnln53kif8z'
            initialValue={props.value}
            init={{
                height: 560,
                automatic_uploads: true,
                branding: false,
                codesample_languages: [
                    { text: 'Swift', value: 'swift' },
                    { text: 'Bash/Zsh(Terminal)', value: 'bash' },
                    { text: 'Git', value: 'git' },
                    { text: 'HTML/XML', value: 'markup' },
                    { text: 'JavaScript', value: 'javascript' },
                    { text: 'CSS', value: 'css' },
                    { text: 'PHP', value: 'php' },
                    { text: 'Ruby', value: 'ruby' },
                    { text: 'Python', value: 'python' },
                    { text: 'Java', value: 'java' },
                ],
                file_picker_types: 'image',
                language: 'ja', // 言語 = 日本語
                menu: {
                    format: { title: 'Format', items: 'forecolor backcolor swaCrabBalloon' },
                },
                setup: (editor: any) => {
                    const swaCrabThinking = () => {
                        const text =
                            '<div class="p-content__balloon"><div class="icon"><img alt="thinking" src="https://firebasestorage.googleapis.com/v0/b/pg-school-e2660.appspot.com/o/assets%2Fswacrab%2F03_thinking.png?alt=media&token=310da5ac-094f-4b0f-a87c-914560502156" height="160" width="160"></div><p class="p-content__comment">ここに文章</p></div><br/>'
                        return text
                    }
                    const swaCrabFlashed = () => {
                        const text =
                            '<div class="p-content__balloon"><div class="icon"><img alt="flashed" src="https://firebasestorage.googleapis.com/v0/b/pg-school-e2660.appspot.com/o/assets%2Fswacrab%2F04_flashed.png?alt=media&token=8bc7169c-0e9f-4c6f-8256-7a115766f421" height="160" width="160"></div><p class="p-content__comment">ここに文章</p></div><br/>'
                        return text
                    }
                    const swaCrabCongratulation = () => {
                        const text =
                            '<div class="p-content__balloon"><div class="icon"><img alt="congratulation" src="https://firebasestorage.googleapis.com/v0/b/pg-school-e2660.appspot.com/o/assets%2Fswacrab%2F07_congrats.png?alt=media&token=ad8ea65c-2fb6-4f94-aa7d-9122325ca982" height="160" width="160"></div><p class="p-content__comment">ここに文章</p></div><br/>'
                        return text
                    }
                    const swaCrabCrying = () => {
                        const text =
                            '<div class="p-content__balloon"><div class="icon"><img alt="crying" src="https://firebasestorage.googleapis.com/v0/b/pg-school-e2660.appspot.com/o/assets%2Fswacrab%2F10_crying.png?alt=media&token=2fdf3ded-7333-403c-90b5-842651ca8c0d" height="160" width="160"></div><p class="p-content__comment">ここに文章</p></div><br/>'
                        return text
                    }
                    const swaCrabBye = () => {
                        const text =
                            '<div class="p-content__balloon"><div class="icon"><img alt="bye" src="https://firebasestorage.googleapis.com/v0/b/pg-school-e2660.appspot.com/o/assets%2Fswacrab%2F11_bye.png?alt=media&token=e1906b33-265f-4983-8226-6504eb2aa0fa" height="160" width="160"></div><p class="p-content__comment">ここに文章</p></div><br/>'
                        return text
                    }
                    const swaCrabCorrect = () => {
                        const text =
                            '<div class="p-content__balloon"><div class="icon"><img alt="correct" src="https://firebasestorage.googleapis.com/v0/b/pg-school-e2660.appspot.com/o/assets%2Fswacrab%2F14_correct.png?alt=media&token=73fabc69-30e2-4c1a-9925-77bc0b62f313" height="160" width="160"></div><p class="p-content__comment">ここに文章</p></div><br/>'
                        return text
                    }
                    const swaCrabIncorrect = () => {
                        const text =
                            '<div class="p-content__balloon"><div class="icon"><img alt="incorrect" src="https://firebasestorage.googleapis.com/v0/b/pg-school-e2660.appspot.com/o/assets%2Fswacrab%2F15_incorrect.png?alt=media&token=297b2bd0-3468-46a8-b64a-f2f7ed5d1f1d" height="160" width="160"></div><p class="p-content__comment">ここに文章</p></div><br/>'
                        return text
                    }
                    const swaCrabCheckit = () => {
                        const text =
                            '<div class="p-content__balloon"><div class="icon"><img alt="checkit" src="https://firebasestorage.googleapis.com/v0/b/pg-school-e2660.appspot.com/o/assets%2Fswacrab%2F13_checkit.png?alt=media&token=3dd918bc-f1fc-4a82-88f4-9e8db4623542" height="160" width="160"></div><p class="p-content__comment">ここに文章</p></div><br/>'
                        return text
                    }
                    const movieWrapper = () => {
                        const text = '<div class="p-content__iframe-wrap">ここにメディアを挿入</div>'
                        return text
                    }
                    // addMenuButton = pullDown
                    // addButton = singleButton
                    editor.ui.registry.addMenuButton('swaCrabBalloon', {
                        // text: 'SCBalloon',
                        tooltip: 'Insert SwaCrab Balloon',
                        icon: 'comment-add',
                        fetch: (cb: any) => {
                            const items = [
                                {
                                    type: 'menuitem',
                                    text: 'thinking',
                                    onAction: () => {
                                        editor.insertContent(swaCrabThinking())
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    text: 'flashed',
                                    onAction: () => {
                                        editor.insertContent(swaCrabFlashed())
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    text: 'congratulation',
                                    onAction: () => {
                                        editor.insertContent(swaCrabCongratulation())
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    text: 'crying',
                                    onAction: () => {
                                        editor.insertContent(swaCrabCrying())
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    text: 'bye',
                                    onAction: () => {
                                        editor.insertContent(swaCrabBye())
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    text: 'correct',
                                    onAction: () => {
                                        editor.insertContent(swaCrabCorrect())
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    text: 'incorrect',
                                    onAction: () => {
                                        editor.insertContent(swaCrabIncorrect())
                                    },
                                },
                                {
                                    type: 'menuitem',
                                    text: 'checkit',
                                    onAction: () => {
                                        editor.insertContent(swaCrabCheckit())
                                    },
                                },
                            ]
                            cb(items)
                        },
                    })
                    editor.ui.registry.addButton('movieWrapper', {
                        // text: 'iframe wrap',
                        tooltip: 'Insert movie wrapper',
                        icon: 'browse',
                        onAction: () => {
                            editor.insertContent(movieWrapper())
                        },
                    })
                },
                menubar: true,
                paste_data_images: true,
                plugins: ['image link lists codesample, table media'],
                toolbar:
                    'swaCrabBalloon | movieWrapper media | insertfile undo redo | styleselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image table codesample',
                file_picker_callback: (cb: any) => {
                    let input = document.createElement('input')
                    input.setAttribute('type', 'file')
                    input.setAttribute('accept', 'image/*')

                    /*
                      Note: In modern browsers input[type="file"] is functional without
                      even adding it to the DOM, but that might not be the case in some older
                      or quirky browsers like IE, so you might want to add it to the DOM
                      just in case, and visually hide it. And do not forget do remove it
                      once you do not need it anymore.
                    */

                    input.onchange = (event: any) => {
                        const file = event.target.files[0]

                        // Generate random 16 digits strings
                        const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
                        const N = 16
                        const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N)))
                            .map((n) => S[n % S.length])
                            .join('')

                        let reader = new FileReader()
                        reader.onload = () => {
                            /*
                              Note: Now we need to register the blob in TinyMCEs image blob
                              registry. In the next release this part hopefully won't be
                              necessary, as we are looking to handle it internally.
                            */
                            const base64 = typeof reader.result === 'string' ? reader.result.split(',')[1] : ''
                            const imageRef = storage.ref('images')
                            const uploadRef = imageRef.child(fileName)
                            const uploadTask = uploadRef.putString(base64, 'base64', { contentType: 'image/png' })

                            return uploadTask.on(
                                'state_changed',
                                function (snapshot) {
                                    // Observe state change events such as progress, pause, and resume
                                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                    console.log('Upload is ' + progress + '% done')
                                },
                                (error) => {
                                    // Handle unsuccessful uploads
                                    console.error('Failed to upload file. ERROR: ', error)
                                },
                                () => {
                                    // Handle successful uploads on complete
                                    return uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                        console.log('File available at', downloadURL)
                                        /* call the callback and populate the Title field with the file name */
                                        cb(downloadURL, {
                                            title: file.name,
                                        })
                                    })
                                }
                            )
                        }
                        reader.readAsDataURL(file)
                    }
                    input.click()
                },
            }}
            onEditorChange={(content: string) => props.onChange(content)}
            value={props.value}
        />
    )
}

export default CurriculumEditor
