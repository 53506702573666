import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dateToString } from 'functions/commonFunc'
import { deleteCurriculum } from 'reducks/curriculum/operations'
import { State } from 'index'
import { push } from 'connected-react-router'
import noImage from 'assets/img/src/no_image.png'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { pushTransition } from 'reducks/router/operation'
import LockIcon from '@material-ui/icons/Lock'
import { IconButton } from '@material-ui/core'
import { theme } from 'assets/css/theme'
import { deleteTest } from 'reducks/tests/operations'
import { getUserRole } from '../../reducks/users/selectors'
import { getCourseId } from 'reducks/users/selectors'
import { DoneCheckButton } from 'components/UIkit'

interface ListItemProps {
    eyecatch_path: string
    hasDone: boolean
    id: string
    isFree: boolean
    isSubscriber: boolean
    title: string
    type: string
    updated_at: firebase.firestore.Timestamp
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: 0,
            position: 'relative',
        },
        locked: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 3,
        },
        lockIcon: {
            color: theme.palette.grey['100'],
            fontSize: 72,
        },
        doneCheckBtn: {
            marginRight: '1em',
        },
    })
)

const CurriculumListItem = (props: ListItemProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selector = useSelector((state: State) => state)
    const courseId = getCourseId(selector)

    const updatedAt: string = dateToString(props.updated_at.toDate())
    const editUrl = `/pg-admin/${props.type}/edit/${props.id}`
    const detailUrl = `/${courseId}/${props.type}/${props.id}`
    const eyecatch = props.eyecatch_path !== '' ? props.eyecatch_path : noImage
    const role: string = getUserRole(selector)

    return (
        <ExpansionPanelDetails className={classes.root}>
            <article className='p-grid__list-items'>
                <div className='p-media__thumb eyecatch'>
                    <img id='eyecatch' alt='アイキャッチ画像' src={eyecatch} />
                </div>
                <div className='body'>
                    <h3 className='u-text__ellipsis-two'>{props.title}</h3>
                    <p>{updatedAt}</p>
                </div>
                <div className='buttons'>
                    <div className={classes.doneCheckBtn}>
                        <DoneCheckButton id={props.id} type={props.type} />
                    </div>
                    {(role === 'teacher' || role === 'administrator') && (
                        <>
                            <button className='p-btn-small p-btn-secondary' onClick={() => dispatch(push(editUrl))}>
                                編集
                            </button>
                            <button
                                className='p-btn-small p-btn-gray'
                                onClick={() => {
                                    const ret = window.confirm('削除しますか？この操作はやり直しできません。')
                                    if (!ret) {
                                        return false
                                    } else {
                                        if (props.type === 'curriculum') {
                                            return dispatch(deleteCurriculum(props.id))
                                        } else if (props.type === 'test') {
                                            return dispatch(deleteTest(props.id))
                                        } else {
                                            return false
                                        }
                                    }
                                }}
                            >
                                削除
                            </button>
                        </>
                    )}
                </div>
                {props.isSubscriber || props.isFree ? (
                    <a className='link' onClick={() => dispatch(pushTransition(detailUrl))} role='button' />
                ) : (
                    <a
                        className='link locked'
                        onClick={() => {
                            const ret = window.confirm('こちらは有料コンテンツです。決済ページへ移動しますか？')
                            if (ret) {
                                return dispatch(pushTransition('/checkout'))
                            } else {
                                return false
                            }
                        }}
                        role='button'
                    >
                        <IconButton className={classes.locked}>
                            <LockIcon className={classes.lockIcon} />
                        </IconButton>
                    </a>
                )}
            </article>
        </ExpansionPanelDetails>
    )
}

export default CurriculumListItem
