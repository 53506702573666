import React, { useEffect, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import { db } from 'firebase/index'
import defaultStyle from './defaultStyle'

interface CommentInputProps {
    comment: string
    inputComment: (value: string) => void
}

const CommentInput = (props: CommentInputProps) => {
    type user = { id: string; display: string }
    const [users, setUsers] = useState<Array<user>>([])

    useEffect(() => {
        let list: Array<user> = []
        db.collection('users')
            .orderBy('created_at', 'asc')
            .get()
            .then((snapshots) => {
                snapshots.forEach((doc) => {
                    const data = doc.data()
                    if (data.role !== 'administrator') {
                        list.push({ id: doc.id, display: data.username })
                    }
                })
                setUsers(list)
            })
    }, [])

    const data = users

    return (
        <MentionsInput
            className='p-input__textarea'
            value={props.comment}
            onChange={(e) => props.inputComment(e.target.value)}
            style={defaultStyle}
        >
            <Mention trigger='@' data={data} markup='@[__display__](__id__)' />
        </MentionsInput>
    )
}

export default CommentInput
