import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ImageContain, InputText, PrimaryButton, SelectBox, SimpleTable } from 'components/UIkit'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'index'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { ApplePayButton, CheckoutForm } from 'components/Checkout/index'
import HiSwacrab from 'assets/img/swacrab/08_hi.png'
import { fetchCalendars, fetchMentors } from 'reducks/supports/operations'
import { getCalendars, getDayOfWeek, getMentor, getMentors, getSupportId } from '../reducks/supports/selectors'
import { CalendarType, Cls } from '../reducks/supports/types'
import { getUserEmail, getUserId, getUsername, getUserRole } from 'reducks/users/selectors'
import { subscribeSupport } from '../reducks/payments/operations'
import { getPageTitle } from 'reducks/page/selectors'
import { setTitleAction } from 'reducks/page/action'

const SupportCheckoutForm = () => {
    const dispatch = useDispatch()
    const elements = useElements()
    const stripe = useStripe()
    const selector = useSelector((state: State) => state)
    const pageTitle = getPageTitle(selector)

    const calendars = getCalendars(selector)
    const uid = getUserId(selector)
    const email = getUserEmail(selector)
    const username = getUsername(selector)
    const role = getUserRole(selector)
    const mentors = getMentors(selector)
    const mounted = useRef(false)

    const [password, inputPassword] = useState(''),
        [mentor, setMentor] = useState<string>(getMentor(selector)),
        [firstDayOfWeek, setFirstDayOfWeek] = useState<string>(getDayOfWeek(selector)),
        [firstSupportId, setFirstSupportId] = useState<string>(getSupportId(selector)),
        [secondDayOfWeek, setSecondDayOfWeek] = useState<string>(''),
        [secondSupportId, setSecondSupportId] = useState<string>(''),
        [term, setTerm] = useState<string>('curriculumPlan'),
        [supportFeeAmount, setSupportFeeAmount] = useState<number>(315000)

    const selectTerm = useCallback(
        (id: string) => {
            const selectedTerm = termOptions.filter((term) => term.id === id)[0]
            setSupportFeeAmount(selectedTerm.subtotal)
            setTerm(id)
        },
        [setTerm]
    )

    // Set the term options and the table of terms and price
    const termOptions = [
        {
            id: 'curriculumPlan',
            name: 'カリキュラム達成プラン(3ヶ月)',
            perWeek: 2,
            subtotal: 315000,
        },
        {
            id: 'coDevelopmentPlan',
            name: '共同開発プラン(4ヶ月)',
            perWeek: 2,
            subtotal: 420000,
        },
        {
            id: 'appReleasePlan',
            name: 'アプリリリースプラン(4ヶ月)',
            perWeek: 2,
            subtotal: 420000,
        },
        {
            id: 'fullPlan',
            name: 'アプリリリース×共同開発プラン(5ヶ月)',
            perWeek: 2,
            subtotal: 525000,
        },
    ]

    const createData = (label: string, value: string) => {
        return { label, value }
    }

    const tableRows = [
        createData('プラン名', '料金'),
        createData('カリキュラム達成プラン(3ヶ月)', '¥315,000+税'),
        createData('共同開発プラン(4ヶ月)', '¥420,000+税'),
        createData('アプリリリースプラン(4ヶ月)', '¥420,000+税'),
        createData('アプリリリース×共同開発プラン(5ヶ月)', '¥525,000+税'),
    ]

    // Filtered valid day of weeks from all the one
    const dayOfWeeks = useMemo(() => {
        const validDays = calendars.filter((calendar: CalendarType) => calendar.classes.length > 0)
        return validDays.map((calendar: CalendarType) => {
            return { id: calendar.dayOfWeek, name: calendar.dayOfWeek + '曜日' }
        })
    }, [calendars])

    const selectableFirstSupportTimes = useMemo(() => {
        const selectedDay = calendars.filter((calendar: CalendarType) => calendar.dayOfWeek === firstDayOfWeek)[0]
        if (selectedDay) {
            const vacantClasses = selectedDay.classes.filter((cls: Cls) => !cls.isSubscribed)
            return vacantClasses.map((cls: Cls) => {
                return { id: cls.id, name: cls.label }
            })
        } else {
            return []
        }
    }, [calendars, firstDayOfWeek])

    const selectableSecondSupportTimes = useMemo(() => {
        const selectedDay = calendars.filter((calendar: CalendarType) => calendar.dayOfWeek === secondDayOfWeek)[0]
        if (selectedDay) {
            const vacantClasses = selectedDay.classes.filter((cls: Cls) => !cls.isSubscribed)
            return vacantClasses.map((cls: Cls) => {
                return { id: cls.id, name: cls.label }
            })
        } else {
            return []
        }
    }, [calendars, secondDayOfWeek])

    const perWeek = useMemo(() => {
        return termOptions.filter((option) => option.id === term)[0].perWeek
    }, [term])

    useEffect(() => {
        if (mounted.current) {
            setFirstDayOfWeek('')
            setFirstSupportId('')
            setSecondDayOfWeek('')
            setSecondSupportId('')
        } else {
            mounted.current = true
        }
        dispatch(fetchCalendars(mentor))
    }, [mentor])

    useEffect(() => {
        if (mentors.length === 0) {
            dispatch(fetchMentors())
        }
    }, [])

    useEffect(() => {
        const title = 'サポート付きプランの申し込み'
        dispatch(setTitleAction(title))
    }, [])

    return (
        <section className='c-section'>
            <div className='c-section__container'>
                <h2 className='u-display-none-over-md'>{pageTitle}</h2>
                <ImageContain path={HiSwacrab} height={128} />
                <div className='module-spacer--extra-small' />

                <p>サポート付きプランの料金表</p>
                <SimpleTable rows={tableRows} />
                <div className='module-spacer--small' />
                <SelectBox
                    label={'プランを選択する'}
                    options={termOptions}
                    required={true}
                    select={selectTerm}
                    value={term}
                />
                <div className='module-spacer--small' />

                <SelectBox
                    label={'メンターを選択する'}
                    options={mentors}
                    required={true}
                    select={setMentor}
                    value={mentor}
                />
                <div className='module-spacer--small' />

                <p>1つめのサポート日時を選ぶ</p>
                <SelectBox
                    label={'曜日を選択する'}
                    options={dayOfWeeks}
                    required={true}
                    select={setFirstDayOfWeek}
                    value={firstDayOfWeek}
                />
                <SelectBox
                    label={'時間帯を選択する'}
                    options={selectableFirstSupportTimes}
                    required={true}
                    select={setFirstSupportId}
                    value={firstSupportId}
                />
                <div className='module-spacer--small' />

                {perWeek === 2 && (
                    <>
                        <p>2つめのサポート日時を選ぶ</p>
                        <SelectBox
                            label={'曜日を選択する'}
                            options={dayOfWeeks}
                            required={true}
                            select={setSecondDayOfWeek}
                            value={secondDayOfWeek}
                        />
                        <SelectBox
                            label={'時間帯を選択する'}
                            options={selectableSecondSupportTimes}
                            required={true}
                            select={setSecondSupportId}
                            value={secondSupportId}
                        />
                        <div className='module-spacer--small' />
                    </>
                )}

                <InputText
                    id={'password'}
                    type={'password'}
                    label={'パスワード'}
                    value={password}
                    onChange={inputPassword}
                    required={true}
                />

                <p className='u-text-bolder'>決済情報の入力</p>
                <p>以下のクレジットカードとデビットカードに対応しています。</p>
                <p className='u-text-small'>VISA / MasterCard / AMEX / Discover / Diners Club</p>
                <div className='module-spacer--extra-extra-small' />

                <CheckoutForm />
                <ApplePayButton initialFeeAmount={supportFeeAmount} stripe={stripe} />
                <div className='module-spacer--small' />

                <p className='u-text-small u-text-primary'>
                    決済処理は通信環境の良いところで行ってください。また、決済処理中にブラウザのタブを閉じないようご注意ください。
                </p>
                <div className='module-spacer--small' />
            </div>
        </section>
    )
}

export default SupportCheckoutForm
